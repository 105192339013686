import {Component, Injector, AfterViewInit, ViewChild, OnInit} from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
// import { TaxTable } from './taxTable';
import {TaxTable} from 'src/app/admin/taxtable/taxtable';
import { NotificationService } from '../../services/notification.service';
import { ApiService } from 'src/app/admin/api.service';
import * as globals from 'src/app/globals';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {ConfirmationDialogService} from '../../services/confirmation-dialog/confirmation-dialog.service';
import {UtilitiesService} from '../../services/utilities.service';
import {CustomValidators} from '../../services/custom_validators';
import {FormService} from '../../services/form';
import {CurrencyService} from '../../services/currency.service';
import {GlobalBehavioursService} from '../../services/global-behaviours.service';

@Component({
  selector: 'app-taxtable',
  templateUrl: './taxtable.component.html'
})

export class TaxTableComponent implements OnInit, AfterViewInit  {
  title = 'payroll-system';
  displayedColumns: string[] = ['taxYear', 'minimum', 'maximum', 'baseAmount', 'rate', 'manage'];
  rawData: any;
  data: any;
  inPayslipCurrency = false;
  latestTaxYearId = 1;
  resultsLength = 0;
  isLoadingResults = true;
  isServiceAvailable = true;
  updateMode = false;
  rForm: FormGroup;
  requiredAlert = 'This field is required';
  taxYears = [];
  pageSize = 20;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public formErrors = {
    minimum: '',
    maximum: '',
    taxYear: '',
    baseAmount: '',
    rate: '',
  };

  constructor(public injector: Injector,
              public apiService: ApiService,
              public fb: FormBuilder,
              public utilitiesService: UtilitiesService,
              public confirmationDialogService: ConfirmationDialogService,
              public currencyService: CurrencyService,
              public globalBehavioursService: GlobalBehavioursService,
              public formService: FormService) {

    this.rForm = fb.group({
      id: [null],
      minimum: [null, [Validators.required, CustomValidators.positiveNumberOrDecimal]],
      maximum: [null, [Validators.required, CustomValidators.positiveNumberOrDecimal]],
      taxYear: [null, Validators.required],
      baseAmount: [null, [Validators.required, CustomValidators.positiveNumberOrDecimal]],
      rate: [null, [Validators.required, CustomValidators.validPercentageValue]],
    });

    this.rForm.valueChanges.subscribe((data) => {
      this.formErrors = this.formService.validateForm(this.rForm, this.formErrors, true);
    });
  }

  isServiceAvailable$ = this.globalBehavioursService.isServiceAvailable$;
  notifier = this.injector.get(NotificationService);

  createTaxTableEntry(f: any) {
    const taxTable: TaxTable = {
      'minimum': f.minimum,
      'maximum': f.maximum,
      'taxYear': (f.taxYear) ? this.utilitiesService.generateQuickIdObject(f.taxYear) : null, // f.taxYear,
      'baseAmount': f.baseAmount,
      'rate': f.rate,
      'id': f.id
    };

    this.apiService.saveOnly(globals.TAX_TABLE_ENDPOINT, taxTable).subscribe(_ => {
      this.resetForm();
      this.ngAfterViewInit();
      this.updateMode = false;
      this.notifier.showSaved();
    }, error => this.isServiceAvailable = false);
  }

  formEdit(id: number) {
    this.apiService.getById(globals.TAX_TABLE_ENDPOINT, id).subscribe(
      (taxTable: TaxTable) => {
        this.rForm.setValue({
          id: taxTable.id,
          minimum: taxTable.minimum,
          maximum: taxTable.maximum,
          taxYear: (taxTable.taxYear != null) ? taxTable.taxYear.id : null,
          baseAmount: taxTable.baseAmount,
          rate: taxTable.rate
        });
        this.updateMode = true;
        window.scroll(0, 0);
      });
  }

  updateTaxTableEntry(f: any) {
    const taxTable: TaxTable = {
      'id': f.id,
      'minimum': f.minimum,
      'maximum': f.maximum,
      'taxYear': (f.taxYear) ? this.utilitiesService.generateQuickIdObject(f.taxYear) : null,
      'baseAmount': f.baseAmount,
      'rate': f.rate
    };

    return this.apiService.updateOnly(globals.TAX_TABLE_ENDPOINT, taxTable).subscribe(_ => {
      this.setCreateMode();
      this.ngAfterViewInit();
      this.notifier.showSaved();
    }, error => this.isServiceAvailable = false);
  }

  /*ngOnInit() {
    this.getTaxYears();
  }*/

  ngOnInit() {
    this.currencyService.setDefaultCurrency().then(r => { // Wrapped inside promise to ensure currencies are set first.
      this.getTaxYears();
    }).catch(function(e) {
      console.log('Error setting currencies! Please contact system administrator');
    });
  }

  getTaxYears() {
    this.apiService.getAll(globals.TAX_YEAR_ENDPOINT).subscribe(
      (res: []) => {
        this.taxYears = res['content'];
      }
    );
  }

  ngAfterViewInit() {

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // @ts-ignore
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.apiService.getAllPaginated(globals.TAX_TABLE_ENDPOINT, {pageNo: this.paginator.pageIndex, pageSize: this.pageSize, sortBy: this.sort.active, sortOrder: this.sort.direction}
          ).pipe(catchError(error => {
              this.isServiceAvailable = false;
              return observableOf(null);
            })
          );
        }),
        map(data => {
          this.isLoadingResults = false;

          if (data === null) {
            return [];
          }

          // @ts-ignore
          this.resultsLength = data.totalElements;
          return data.content;
        })
      ).subscribe(data => {
        // this.data = data;
        this.rawData = data;
        this.setLatestTaxYear(this.rawData);
        this.data = data.filter(x => x.taxYear.id === this.latestTaxYearId);
        this.rForm.controls.taxYear.setValue(this.latestTaxYearId);
      });
  }

  setLatestTaxYear(data) {
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data.length; j++) {
        if (data[i].taxYear.endDate > data[j].taxYear.endDate) {
          this.latestTaxYearId = data[i].taxYear.id;
          this.inPayslipCurrency = data[i].taxYear.usePayslipCurrency;
        } else {
          this.latestTaxYearId = data[j].taxYear.id;
          this.inPayslipCurrency = data[j].taxYear.usePayslipCurrency;
        }
      }
    }
  }

  public taxYearChange(selectedTaxYearId: number) {
    this.data = this.rawData.filter(data => data.taxYear.id === +selectedTaxYearId);
    if (this.data.length > 0) {
      this.inPayslipCurrency = this.data[0].taxYear.usePayslipCurrency;
    }
    // console.log("**\n" + JSON.stringify(this.data));
  }

  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm...', 'This may cause problems if used elsewhere in the application.')
      .then((confirmed) => {
        if (confirmed) { this.deleteEntry(id); }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  private deleteEntry(id) {
    this.apiService.deleteOnly(globals.TAX_TABLE_ENDPOINT, id)
      .subscribe(
        (res: boolean) => {
          this.notifier.showDeleted();
          this.ngAfterViewInit(); // Amend to only do this if above returns 200
          this.setCreateMode();
        }/*,
          (err) => this.error = err*/
      );
  }

  resetForm() {
    this.rForm.reset();
  }

  setCreateMode() {
    this.rForm.reset();
    this.updateMode = false;
  }
}
