'use strict';

export const COMPANY_ENDPOINT: string = '/companies';
export const COMPANY_TYPE_ENDPOINT: string = '/company_types';
export const COUNTRY_ENDPOINT: string = '/countries';
export const COSTCENTRE_ENDPOINT: string = '/costcentres';
export const DEPARTMENT_ENDPOINT: string = '/departments';
export const EMPLOYEE_ENDPOINT: string = '/employees';
export const EMPLOYEE_TYPE_ENDPOINT: string = '/employee_types';
export const EMPLOYEE_USER_ENDPOINT: string = '/employees/user';
export const GENDER_ENDPOINT: string = '/genders';
export const POSITION_ENDPOINT: string = '/positions';
export const PAY_GRADE_ENDPOINT = '/pay-grade';
export const TITLES_ENDPOINT: string = '/titles';
export const USER_ENDPOINT = '/users';
export const DATA_LOADER_ENDPOINT = '/dataloader';
export const FORMULA_ENDPOINT = '/formulas';
export const FORMULA_TYPE_ENDPOINT = '/formula_types';
export const FORMULA_BY_EMPLOYEE_TYPE_ENDPOINT = FORMULA_ENDPOINT + '/employee-type';
export const PAYROLL_ENDPOINT = '/payroll';
export const PAYROLL_PERIOD_DETAILS = '/payroll/period';
export const PAYROLL_PERIOD_ENDPOINT = '/payroll_periods';
export const PAYROLL_VIEW_PAYSLIP_ENDPOINT = '/payroll/payslip';
export const RUN_PAYROLL_ENDPOINT = '/run-payroll';
export const PAYROLL_FREQ_ENDPOINT = '/frequencies';
export const CALENDAR_ENDPOINT = '/calendar';
export const ABSENCE_ENDPOINT = '/absences';
export const OVERTIME_ENDPOINT = '/overtimes';
export const ABSENCE_TYPE_ENDPOINT = '/absence_types';
export const TAX_YEAR_ENDPOINT = '/tax-year';
export const TAX_TABLE_ENDPOINT = '/tax-table';
export const TAX_REBATE_ENDPOINT = '/tax-rebate';
export const TAX_THRESHOLD_ENDPOINT = '/tax-threshold';
export const CURRENCY_ENDPOINT = '/currencies';
export const CURRENCY_RATE_ENDPOINT = '/rates';
export const SALARY_ENDPOINT = '/salary';
export const BONUS_ENDPOINT = '/bonus';
/*self service*/
export const PERIODS_BY_EMPLOYEE = EMPLOYEE_ENDPOINT + '/self/periods';
export const LOGO_ENDPOINT = '/logo';
/* reports */
export const REPORTS_ENDPOINT = '/reports';
export const REPORTS_GENDER = REPORTS_ENDPOINT + '/gender';
export const REPORTS_PERIODS_TAX = REPORTS_ENDPOINT + '/period-taxes';
export const REPORTS_RECENT_PAYROLLS = REPORTS_ENDPOINT + '/recent-payrolls';
export const REPORTS_ACTIVE_EMPLOYEE_COUNT = REPORTS_ENDPOINT + '/active-employee-count';
export const REPORTS_LAST_PERIOD_COST = REPORTS_ENDPOINT + '/last-payroll-cost';
export const REPORTS_OPEN_PERIODS = REPORTS_ENDPOINT + '/open-periods';
export const REPORTS_PROCESSED_PAYROLL_COUNT = REPORTS_ENDPOINT + '/processed-payroll-count';
export const REPORTS_PAST_COSTS = REPORTS_ENDPOINT + '/past-costs';
/* Restricted */
export const EXTRAS = '/extras';
export const EXTRAS_PARAM = EXTRAS + '/options';
export const EXTRA_FIELD_TYPES = '/extra-field-types';


