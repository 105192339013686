import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() {
    if (this.hasRole('ROLE_PAYROLL_ADMIN')) {
      this.router.navigate(['/dashboard']).then(null);
    } else if (this.hasRole('ROLE_EMPLOYEE_ADMIN')) {
      this.router.navigate(['/employee_list/employee']).then(null);
    } else if (this.hasRole('ROLE_ADMIN')) {
      this.router.navigate(['/user_list']).then(null);
    } else if (this.hasRole('ROLE_EMPLOYEE')) {
      this.router.navigate(['/employee/periods']).then(null);
    }
  }

  hasRole(_role) {
    return this.auth.hasRole(_role); // Checks roles of current user
  }

}
