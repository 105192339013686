import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Color, Label, SingleDataSet} from 'ng2-charts';
import {ApiService} from '../../admin/api.service';
import * as globals from '../../globals';

interface PeriodTax {
  periodDates: string;
  value: number;
}

@Component({
  selector: 'app-expenses-by-costcentre-chart',
  templateUrl: './expenses-by-costcentre-chart.component.html',
  /*styleUrls: ['../x-chart.component.css']*/
  styleUrls: ['./expenses-by-costcentre-chart.component.scss']
})
export class ExpensesByCostcentreChartComponent implements OnInit {

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales : {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };

  pastCosts = [];

  // Navigation
  nextDisabled = true;
  previousDisabled = true;
  chartIndex = 0;
  chartCount = 0;

  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartData: ChartDataSets[] = [
    { data: [], label: '' },
  ];
  public barChartColors: Color[] = [
    { backgroundColor: 'green' }
  ];

  constructor(public apiService: ApiService) { }

  ngOnInit() {
    this.apiService.get(globals.REPORTS_PAST_COSTS).subscribe(
      (pastCosts: []) => {
        this.chartCount = pastCosts.length;
        if (this.chartCount > 0 ) {
          this.pastCosts = pastCosts;
          this.draw(this.chartIndex);
          if (this.chartCount > 1) {
            this.previousDisabled = false;
          }
        }
      });
  }

  initChart() {
    this.barChartLabels = [];
    this.barChartData = [{ data: [], label: '' },];
  }

  draw(costIndex: number) {
    this.initChart();
    const costs = this.pastCosts[costIndex].entity;
    const costsPeriod = this.pastCosts[costIndex].labels;
    for (const c of costs) {
      this.barChartData[0].data.push(c.total);
      this.barChartData[0].label = costsPeriod[0].labelName;
      this.barChartLabels.push(c.costcentreName);
    }
  }

  prev() {
    this.chartIndex += 1;
    if (this.chartIndex < this.chartCount - 1) {
      this.nextDisabled = false;
      this.draw(this.chartIndex);
    }
    if (this.chartIndex === this.chartCount - 1) {
      this.previousDisabled = true;
      this.draw(this.chartIndex);
    }
  }

  next() {
    this.chartIndex -= 1;
    if (this.chartIndex === 0) {
      this.nextDisabled = true;
      this.draw(this.chartIndex);
    }

    if (this.chartIndex > 0) {
      this.nextDisabled = false;
      this.draw(this.chartIndex);
    }

    if (this.chartIndex < this.chartCount - 1) {
      this.previousDisabled = false;
    }
  }
}
