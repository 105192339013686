import {Component, Injector, AfterViewInit, ViewChild, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import { PayrollPeriod } from './payrollPeriod';
import { NotificationService } from '../../services/notification.service';
import { ApiService } from 'src/app/admin/api.service';
import * as globals from 'src/app/globals';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {ConfirmationDialogService} from '../../services/confirmation-dialog/confirmation-dialog.service';
import {UtilitiesService} from '../../services/utilities.service';
import {FormService} from '../../services/form';
import {CustomValidators} from '../../services/custom_validators';
import {GlobalBehavioursService} from '../../services/global-behaviours.service';
import {TAX_YEAR_ENDPOINT} from 'src/app/globals';

@Component({
  selector: 'app-payrollPeriod',
  templateUrl: './payrollPeriod.component.html',
  styleUrls: ['./payrollPeriod.component.css']
})

export class PayrollPeriodComponent implements OnInit, AfterViewInit  {
  title = 'payroll-system';
  displayedColumns: string[] = ['name', 'employeeType', 'startDate', 'endDate', 'payDate', 'businessDays', 'open', 'manage'];
  data: any;
  resultsLength = 0;
  isLoadingResults = true;
  isServiceAvailable = true;
  updateMode = false;
  rForm: FormGroup;
  requiredAlert = 'This field is required';
  employeeTypes = [];
  taxYears = [];
  pageSize = 20;

  public formErrors = {
    name: '',
    employeeType: '',
    taxYear: '',
    startDate: '',
    duration: '',
    endDate: '',
    businessDays: '',
    payDate: '',
    open: '',
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public injector: Injector,
              public apiService: ApiService,
              public fb: FormBuilder,
              public utilitiesService: UtilitiesService,
              public globalBehavioursService: GlobalBehavioursService,
              public confirmationDialogService: ConfirmationDialogService,
              public formService: FormService) {

    this.rForm = fb.group({
      id: [null],
      name: [{value: '', disabled: true}],
      employeeType:  [null, Validators.required],
      taxYear: [null, Validators.required],
      startDate:  [null, Validators.required],
      duration:  [null, [Validators.required, CustomValidators.positiveIntegerOnly]],
      endDate: [{value: '', disabled: true}],
      payDate: [null, Validators.required],
      businessDays: [{value: '', disabled: true}],
      open: [null],
    });

    this.rForm.valueChanges.subscribe((data) => {
      this.formErrors = this.formService.validateForm(this.rForm, this.formErrors, true);
    });
  }

  isServiceAvailable$ = this.globalBehavioursService.isServiceAvailable$;
  notifier = this.injector.get(NotificationService);

  createPayrollPeriod(f: any) {
    const payrollPeriod: PayrollPeriod = {
      'name': f.name,
      'employeeType': (f.employeeType) ? this.utilitiesService.generateQuickIdObject(f.employeeType) : null,
      'taxYear': (f.taxYear) ? this.utilitiesService.generateQuickIdObject(f.taxYear) : null,
      'startDate': f.startDate,
      'duration': f.duration,
      'payDate': f.payDate,
      /*'businessDays': f.businessDays,*/
      /*'open': f.open,*/
      'id': f.id
    };

    this.apiService.saveOnly(globals.PAYROLL_PERIOD_ENDPOINT, payrollPeriod).subscribe(_ => {
      this.notifier.showSaved();
      this.resetForm();
      this.ngAfterViewInit();
      this.updateMode = false;
    }, error => this.isServiceAvailable = false);
  }

  formEdit(id: number) {
    this.apiService.getById(globals.PAYROLL_PERIOD_ENDPOINT, id).subscribe(
      (payrollPeriod: PayrollPeriod) => {
        this.rForm.controls.id.setValue(payrollPeriod.id);
        this.rForm.controls.name.setValue(payrollPeriod.name);
        this.rForm.controls.employeeType.reset({value: (payrollPeriod.employeeType != null) ? payrollPeriod.employeeType.id : null, disabled: true});
        this.rForm.controls.taxYear.reset({value: (payrollPeriod.taxYear != null) ? payrollPeriod.taxYear.id : null, disabled: true});
        this.rForm.controls.startDate.setValue(payrollPeriod.startDate);
        this.rForm.controls.duration.setValue(payrollPeriod.duration);
        this.rForm.controls.endDate.setValue(payrollPeriod.endDate);
        this.rForm.controls.payDate.setValue(payrollPeriod.payDate);
        this.rForm.controls.businessDays.setValue(payrollPeriod.businessDays);
        this.rForm.controls.open.reset({value: payrollPeriod.open, disabled: true});
        this.updateMode = true;
        window.scroll(0, 0);
      });
  }

  updatePayrollPeriod(f: any) {
    const payrollPeriod: PayrollPeriod = {
      'id': f.id,
      'payDate': f.payDate,
      'startDate': f.startDate,
      'duration': f.duration
    };

    return this.apiService.updateOnly(globals.PAYROLL_PERIOD_ENDPOINT, payrollPeriod).subscribe(_ => {
      this.notifier.showSaved();
      this.setCreateMode();
      this.ngAfterViewInit();
    }, error => this.isServiceAvailable = false);
  }

  ngOnInit() {
    this.getPayrollPeriodFrequencies();
    this.getTaxYears();
  }

  getPayrollPeriodFrequencies() {
    this.apiService.getAll(globals.EMPLOYEE_TYPE_ENDPOINT).subscribe(
      (res: []) => {
        this.employeeTypes = res;
      }
    );
  }

  getTaxYears() {
    this.apiService.getAllPaginated(globals.TAX_YEAR_ENDPOINT,
    {pageNo: 0,
      pageSize: 0,
      sortBy: 'id',
      sortOrder: 'desc'}
    ).subscribe(
      (res: []) => {
        this.taxYears = res['content'];
      }
    );
  }

  ngAfterViewInit() {

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // @ts-ignore
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.apiService.getAllPaginated(globals.PAYROLL_PERIOD_ENDPOINT, {pageNo: this.paginator.pageIndex, pageSize: this.pageSize, sortBy: this.sort.active, sortOrder: this.sort.direction}
          ).pipe(catchError(error => {
              this.isServiceAvailable = false;
              return observableOf(null);
            })
          );
        }),
        map(data => {
          this.isLoadingResults = false;

          if (data === null) {
            return [];
          }

          // @ts-ignore
          this.resultsLength = data.totalElements;
          return data.content;
        })
      ).subscribe(data => this.data = data);
  }

  public confirmationDialogClosePP(id) {
    this.confirmationDialogService.confirm('Please confirm...', 'This action cannot be undone.\n Do you wish to proceed?')
      .then((confirmed) => {
        if (confirmed) { this.closePayrollPeriod(id); }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  private closePayrollPeriod(id) {
    this.apiService.updateWithIdOnly(globals.PAYROLL_PERIOD_ENDPOINT + '/close', id)
      .subscribe(
        (res: boolean) => {
          this.notifier.showSuccess('Period successfully closed');
          this.ngAfterViewInit(); // Amend to only do this if above returns 200
          this.setCreateMode();
        }/*,
          (err) => this.error = err*/
      );
  }

  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm...', 'A Pay period can only be deleted if payroll has not ran!\n Do you wish to proceed?')
      .then((confirmed) => {
        if (confirmed) { this.deletePayrollPeriod(id); }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  private deletePayrollPeriod(id) {
    this.apiService.deleteOnly(globals.PAYROLL_PERIOD_ENDPOINT, id)
      .subscribe(
        (res: boolean) => {
          this.notifier.showDeleted();
          this.ngAfterViewInit(); // Amend to only do this if above returns 200
          this.setCreateMode();
        }/*,
          (err) => this.error = err*/
      );
  }

  clearDurationAndEndDate() {
    this.rForm.get('duration').reset();
    this.rForm.get('endDate').reset();
  }

  addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  durationChange(durationValue: number) {
    // DO VALIDATIONS
    const endDate = this.addDays(this.rForm.get('startDate').value, +durationValue - 1).toISOString().slice(0, 10);
    this.rForm.get('endDate').setValue(endDate);
  }

  resetForm() {
    this.rForm.reset();
  }

  setCreateMode() {
    this.rForm.controls.employeeType.reset({value: '', disabled: false});
    this.rForm.controls.taxYear.reset({value: '', disabled: false});
    this.rForm.reset();
    this.updateMode = false;
  }
}
