import {EmployeeExtra} from "../../interfaces/employee-extra";

export class Employee {
  id?: any;
  title?: any;//
  gender?: any;//
  active?: any;//
  employeeType?: any;//
  empCode?: any;
  firstName?: any;
  middleName?: any;
  lastName?: any;
  dob?: any;
  maritalStatus?: any;//
  passportNumber?: any;
  nationality?: any;//
  idNumber?: any;
  payGrade?: any;
  basicPay?: any;
  hourlyRate?: any;//
  department?: any;
  position?: any;
  emailAddress?: any;
  taxNumber?: any;
  hireDate?: any;
  leaveDate?: any;//
  address?: any;
  phone?: any;//
  extension?: any;//
  personalEmail?: any;//
  manager?: any;//
  bankingDetails?: any;//
  formulas?: any;
  extras?: EmployeeExtra[];
    constructor(
        title?: number,///
        gender?: number,///
        active?: any,///
        employeeType?: number,///
        empCode?: string,
        firstName?: string,
        middleName?: string,
        lastName?: string,
        userid?: string,
        dob?: string,
        maritalStatus?: any,////
        idNumber?: string,
        passportNumber?: string,
        nationality?: number,///
        payGrade?: number,
        basicPay?: number,
        hourlyRate?: number,///
        department?: number,
        position?: number,
        emailAddress?: string,
        taxNumber?: string,
        hireDate?: string,
        leaveDate?: string,///
        address?: string,
        phone?: number,///
        extension?: string,///
        bankingDetails?: number,///
        personalEmail?: string,///
        manager?: number,///
        formulas?: any,
        extras?: EmployeeExtra[],
        id?: number) {}
  }
