export class PayGrade {
  gradeName: any;
  midpointSalary: any;
  minimumSalary: any;
  maximumSalary: any;
  id: any;

    constructor(
        gradeName?: string,
        minimumSalary?: number,
        maximumSalary?: number,
        midpointSalary?: number,
        id?: number) {}
  }
