import { Component, OnInit, Injector, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Department } from './department';
import { CustomValidators } from '../../services/custom_validators';
import { FormService } from '../../services/form';
import { DataService } from '../data.service';
import { ApiService } from 'src/app/admin/api.service';
import { NotificationService } from '../../services/notification.service';
import { Costcentre } from '../costcentre/costcentre'; // For dropdown
import { Observable, of } from 'rxjs';
import {filter, startWith, map, switchMap, shareReplay} from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';//.................................
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table'; //pagination
import { Employee } from '../employee/employee';
import { UtilitiesService } from '../../services/utilities.service';
import * as globals from 'src/app/globals';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog/confirmation-dialog.service';
import {Company} from "../company/company";
import {HttpClient} from "@angular/common/http";
import {GlobalBehavioursService} from "../../services/global-behaviours.service";

@Component({
  selector: 'app-department', // WHAT MUST THE SELECTOR BE???
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css']
})

export class DepartmentComponent implements OnInit {
  title = 'payroll-system';
  departments: MatTableDataSource<Department>;
  //costcentres: Costcentre[]; // For dropdown
  allCostcentres: Observable<Costcentre[]>;
  filteredCostcentres: Observable<Costcentre[]>;

  //hod
  allEmployees: Observable<Employee[]>;
  filteredEmployees: Observable<Employee[]>;

  //company
  allCompanies: Observable<Company[]>;
  filteredCompanies: Observable<Company[]>;

  displayedColumns: string[] = ['name', 'hod', 'costcentre', 'company', 'manageColumn'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  department = new Department('', 0, '');

  currentCostCentreId = 0;

  rForm: FormGroup;
  showList = false;
  updateMode = false;
  post: any;
  name = '';
  costcentre = 0;
  hod = 0;

  public formErrors = {
    name: '',
    costcentre: '',
    hod: '',
    company: ''
  };

  @ViewChildren(MatAutocompleteTrigger) triggerCollection: QueryList<MatAutocompleteTrigger>;

  subscription: Subscription;

  constructor(private injector: Injector,
              private dataService: DataService,
              private apiService: ApiService,
              private utilitiesService: UtilitiesService,
              private fb: FormBuilder,
              private confirmationDialogService: ConfirmationDialogService,
              public formService: FormService,
              public globalBehavioursService: GlobalBehavioursService,
              private http: HttpClient) {

    this.rForm = fb.group({
      name: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(50), CustomValidators.validateCharacters]],
      costcentre: [null, [Validators.required]],
      hod: [null],
      company: [null, [Validators.required]]
    });

    this.rForm.valueChanges.subscribe((data) => {
      this.formErrors = this.formService.validateForm(this.rForm, this.formErrors, true);
    });
  }

  isServiceAvailable$ = this.globalBehavioursService.isServiceAvailable$;
  notifier = this.injector.get(NotificationService);

  ngOnInit() {
    this.getCostcentres();
    this.filteredCostcentres = this.thecostcentre.valueChanges
    .pipe(
      startWith(''),
      switchMap(value => this.filterCostcentres(value))
    );
    if (this.showList) {
      this.getDepartments();
    }
  }

  showHideList($isChecked): void {
    if ($isChecked) {
      if (!this.departments) {
        // Above Condition added to make the list available on demand. can't retrieve list if not defined (or it throws an error)
        this.getDepartments();
      }
      this.showList = true;
    } else {
      this.showList = false;
    }
  }

  applyFilter(filterValue: string) {
    this.departments.filter = filterValue.trim().toLowerCase();

    if (this.departments.paginator) {
      this.departments.paginator.firstPage();
    }
  }

  getCostcentres(): void {
    this.allCostcentres = this.apiService.getAll(globals.COSTCENTRE_ENDPOINT);
  }

  getCompanies(): void {
    this.allCompanies = this.apiService.getAll(globals.COMPANY_ENDPOINT);
  }

  getRelatedCompanies(event): void {

    let selectCompanyId = event.option.value.company.id;
    this.rForm.controls.company.reset();
    this.allCompanies = this.apiService.getAll(globals.COMPANY_ENDPOINT+'/related/' + selectCompanyId);
    this.filteredCompanies = this.theCompany.valueChanges
      .pipe(
        startWith(''),
        switchMap(value => this.filterCompanies(value))
      );
  }

  getCompanyEmployees(event) {
    this.rForm.controls.hod.reset();
    let selectCompanyId = event.option.value.id;
    this.allEmployees = this.apiService.getAll(globals.EMPLOYEE_ENDPOINT+'/by-company/'+selectCompanyId);
    this.filteredEmployees = this.theHod.valueChanges
      .pipe(
        startWith(''),
        switchMap(value => this.filterEmployees(value))
      );
  }

  getDepartments(): void {
    this.apiService.getAll(globals.DEPARTMENT_ENDPOINT).subscribe(
      (res: Department[]) => {
        this.departments = new MatTableDataSource(res);
        this.departments.paginator = this.paginator;
        this.departments.sort = this.sort;
      }
    );
  }

  getDepartment(id): void {
    this.apiService.getById(globals.DEPARTMENT_ENDPOINT, id).subscribe(
      (res: Department) => {
        this.department = res;
      }
    );
  }

  addDepartment(f) {
    let department = new Department();
    department.name = f.name;
    if (f.costcentre) {
      department.costcentre = {}
      department.costcentre.id = f.costcentre.id;
      department.costcentre.name = f.costcentre.name;
    }
    if (f.hod) {
      department.hod = {};
      department.hod.id = f.hod.id;
      department.hod.firstName = f.hod.firstName;
      department.hod.lastName = f.hod.lastName;
    }
    if (f.company) {
      department.company = {};
      department.company.id = f.company.id;
      department.company.name = f.company.name;
    }
    this.apiService.save(globals.DEPARTMENT_ENDPOINT, department, (this.departments) ? this.departments.data : null)
      .subscribe(
        (res: Department[]) => {
          // Update the list of departments
          // Above Condition added to make the list available on demand. service will only populate list if requested.
          if (this.showList) {
            // Refresh the entire list because the update only returns the ID of the FK and not the entire FK object
            // for that reason we cannot replace the changed object with the FK object since we only have a reference ID.
            this.departments.data = res;
          }
          // Inform the user
          this.notifier.showSaved();

          // Reset the form
          this.rForm.reset();
        }
      );
  }

  departmentEdit(id) {
    this.apiService.getById(globals.DEPARTMENT_ENDPOINT, id).subscribe(
      (res: Department) => {
        this.department = res;
        this.rForm.setValue({
          name: this.department.name,
          costcentre: this.department.costcentre,
          hod: (this.department.hod != null) ? this.department.hod : null,//this.department.hod
          company: this.department.company
        });
        //Important to filter list on edit as well
        this.filteredCompanies = this.apiService.getAll(globals.COMPANY_ENDPOINT+'/related/' + this.department.costcentre.company.id);
      }
    );
    this.updateMode = true;
    window.scroll(0, 0);
  }

  updateDepartment(f) {
    let updatedDepartment = new Department();
    updatedDepartment.id = this.department.id;
    updatedDepartment.name = f.name;
    if (f.costcentre) {
      updatedDepartment.costcentre = {}
      updatedDepartment.costcentre.id = f.costcentre.id;
      updatedDepartment.costcentre.name = f.costcentre.name;
    }
    if (f.hod) {
      updatedDepartment.hod = {};
      updatedDepartment.hod.id = f.hod.id;
      updatedDepartment.hod.firstName = f.hod.firstName;
      updatedDepartment.hod.lastName = f.hod.lastName;
    }
    if (f.company) {
      updatedDepartment.company = {};
      updatedDepartment.company.id = f.company.id;
      updatedDepartment.company.name = f.company.name;
    }
    this.apiService.update(globals.DEPARTMENT_ENDPOINT, updatedDepartment, this.departments.data)
      .subscribe(
        (res) => {
          if (this.showList) {
            this.departments.data = res;
          }
          this.department = new Department();
          this.notifier.showSaved();
          this.updateMode = false;
          this.rForm.reset();
        }
      );
  }

  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm...', 'Are you sure you want to delete?')
    .then((confirmed) => {
      if (confirmed) this.deleteDepartment(id);
    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  deleteDepartment(id) {
    this.apiService.delete(globals.DEPARTMENT_ENDPOINT, id, this.departments.data)
      .subscribe(
        (res: Department[]) => {
          if (this.showList) {
            this.departments.data = res;
          }
          this.notifier.showDeleted();
          this.updateMode = false;
          this.rForm.reset();
        }
      );
  }

  ngAfterViewInit() {
    this._subscribeToClosingActions();
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  private _subscribeToClosingActions(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.triggerCollection.toArray()[0].panelClosingActions
      .subscribe(e => {
        if (!e || !e.source) {
          if (!this.utilitiesService.isObject(this.rForm.getRawValue().costcentre))
            this.rForm.controls.costcentre.setValue(null);
        }
      },
      err => this._subscribeToClosingActions(),
      () => this._subscribeToClosingActions());

    this.subscription = this.triggerCollection.toArray()[1].panelClosingActions
    .subscribe(e => {
      if (!e || !e.source) {
        if (!this.utilitiesService.isObject(this.rForm.getRawValue().hod))
          this.rForm.controls.hod.setValue(null);
      }
    },
    err => this._subscribeToClosingActions(),
    () => this._subscribeToClosingActions());

    this.subscription = this.triggerCollection.toArray()[2].panelClosingActions
      .subscribe(e => {
          if (!e || !e.source) {
            if (!this.utilitiesService.isObject(this.rForm.getRawValue().hod))
              this.rForm.controls.company.setValue(null);
          }
        },
        err => this._subscribeToClosingActions(),
        () => this._subscribeToClosingActions());
  }

  private filterCostcentres(value: string | Costcentre) {
    let filterValue = '';
    if (value) {
      filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
      return this.allCostcentres.pipe(
        map(costcentres => costcentres.filter(costcentre => costcentre.name.toLowerCase().includes(filterValue)))
      );
    } else {
      return this.allCostcentres;
    }
  }

  displayFn(costcentre?: Costcentre): string | undefined {
    return costcentre ? costcentre.name : undefined;
  }

  get thecostcentre() {
    return this.rForm.get('costcentre');
  }

  //hod
  private filterEmployees(value: string | Employee) {
    let filterValue = '';
    if (value) {
      filterValue = typeof value === 'string' ? value.toLowerCase() : value.firstName.toLowerCase();
      return this.allEmployees.pipe(
        map(employees => employees.filter(employee => employee.firstName.toLowerCase().includes(filterValue) || employee.lastName.toLowerCase().includes(filterValue)))
      );
    } else {
      return this.allEmployees;
    }
  }
  displayHodFn(employee?: Employee): string | undefined {
    return employee ? employee.firstName + ' ' + employee.lastName : undefined;
  }

  get theHod() {
    return this.rForm.get('hod');
  }

  private filterCompanies(value: string | Company) {
    let filterValue = '';
    if (value) {
      filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
      return this.allCompanies.pipe(
        map(companies => companies.filter(company => company.name.toLowerCase().includes(filterValue)))
      );
    } else {
      return this.allCompanies;
    }
  }

  get theCompany() {
    return this.rForm.get('company');
  }

  displayCoFn(company?: Company): string | undefined {
    return company ? company.name : undefined;
  }

  clearForm() {
    this.updateMode = false;
    this.rForm.reset();
  }
}
