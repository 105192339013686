export class Company {
  name?: any;
  registrationNumber?: any;
  companyType?: any;
  workingDaysPerWeek?: any;
  address?: any;
  id?: any;

    constructor(
      name?: string,
      registrationNumber?: string,
      companyType?: number,
      workingDaysPerWeek?: number,
      address?: {},
      id?: number) {}
  }
