import {AfterViewInit, Component, Injector, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {ApiService} from "../api.service";
import {UtilitiesService} from "../../services/utilities.service";
import {CurrencyService} from '../../services/currency.service';
import {ConfirmationDialogService} from "../../services/confirmation-dialog/confirmation-dialog.service";
import {Location} from "@angular/common";
import {NotificationService} from "../../services/notification.service";
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import * as globals from "../../globals";
import {merge, of as observableOf} from "rxjs";
import {catchError, map, startWith, switchMap} from "rxjs/operators";
import { Router } from '@angular/router';
import {GlobalBehavioursService} from '../../services/global-behaviours.service';
import {PAYROLL_PERIOD_ENDPOINT} from "../../globals";

interface ColumnObject {
  columnDef: string;
  header: string;
  cell?: any;
  notMonetary?: boolean
}

@Component({
  selector: 'app-payroll',
  templateUrl: './ppdetails.component.html',
  styleUrls: ['payrollPeriod.component.css']
})

export class PPDetailsComponent implements OnInit, AfterViewInit  {

  payrollPeriodId = '';
  title = '';
  //
  columns = [];
  displayedColumns: string[] = [];
  //displayedColumns: string[] = ['days'];


  allFormulas = [];
  data: any;
  resultsLength = 0;
  isLoadingResults = true;
  isServiceAvailable = true;
  // pageSize = 20; // No longer used

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private injector: Injector,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    public currencyService: CurrencyService,
    public utilitiesService: UtilitiesService,
    public globalBehavioursService: GlobalBehavioursService,
    private confirmationDialogService: ConfirmationDialogService,
    private location: Location
  ) {}

  isServiceAvailable$ = this.globalBehavioursService.isServiceAvailable$;
  notifier = this.injector.get(NotificationService);

  ngOnInit() {
    this.payrollPeriodId = this.route.snapshot.paramMap.get('id');
    this.currencyService.setDefaultCurrency().then();
  }

  getFormulas() {
    // this.apiService.getAll(globals.FORMULA_ENDPOINT).subscribe(
    // tslint:disable-next-line:max-line-length
    this.apiService.getAllPaginated(globals.FORMULA_ENDPOINT, {pageNo: this.paginator.pageIndex, pageSize: 100, sortBy: this.sort.active, sortOrder: this.sort.direction}).subscribe(
      (res: any) => {
        const col_: ColumnObject = {
          columnDef: 'empName',
          header: 'Employee Name',
          notMonetary: true,
          cell: (empPayslipDetail: any) => `${this.getEmployeeName(`${empPayslipDetail.employee.id}`)}`
        };
        if (!this.columns.find(x => x.columnDef === 'empName')) { // Pagination tends to duplicate columns. this condition prevents it
          this.columns.push(col_);
        }

        this.allFormulas = res['content'];
        this.allFormulas.forEach(formula => {
          const col: ColumnObject = {
            columnDef: formula.uniqueCode,
            header: formula.name,
            notMonetary: formula.notMonetary,
            cell: (empPayslipDetail: any) => `${this.getFormulaValue(`${empPayslipDetail.employee.id}`, formula.uniqueCode)}`
          };
          if (!this.columns.find(x => x.columnDef === formula.uniqueCode)) { // Pagination tends to duplicate columns. this condition prevents it
            this.columns.push(col);
          }
        });
        const manageColumn: ColumnObject = {
          columnDef: 'manage',
          header: 'More actions'
        };
        if (!this.columns.find(x => x.columnDef === 'manage')) { // Pagination tends to duplicate columns. this condition prevents it
          this.columns.push(manageColumn);
        }

        this.displayedColumns = this.columns.map(c => c.columnDef);
      }
    );
  }

  viewPayslip(employeeId) {
    // console.log(JSON.stringify(employeeId));
    this.router.navigate(['/view-payslip/' + this.payrollPeriodId + '/' + employeeId]); // Instead, navigate to view payslip
  }

  downloadPayslip(employeeId) {
    this.apiService.getFileWithParam(globals.PAYROLL_PERIOD_ENDPOINT + '/download-payslip', {payrollPeriodId: this.payrollPeriodId, employeeId: employeeId}).subscribe((data) => {
      // @ts-ignore
      const blob = new Blob([data], {type: 'application/pdf'});

      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = this.generateFilename();
      link.click();

    });
  }

  generateFilename(): string {
    const date = new Date();
    console.log('->' + date);
    const day = date.getDate();
    const monthIndex = date.getMonth() + 1;
    const year = date.getFullYear();
    const minutes = date.getMinutes();
    const hours = date.getHours();
    const seconds = date.getSeconds();

    return 'Payslip_' + year + '-' + monthIndex + '-' + day + '_' + hours + '_' + minutes + '_' + seconds + '.pdf';
  }

  getEmployeeName(employeeId) {
    let value = '';
    if (this.data !== undefined) {
      const employeeObj = this.data.filter(epD => epD.employee.id == employeeId); // === & = don't not work here. it breaks
      value = employeeObj[0].employee.firstName + ' ' + employeeObj[0].employee.lastName;
    }
    return value;
  }

  getFormulaValue(employeeId, uniqueCode) {
    // console.log('this.data:\n' + JSON.stringify(this.data));
    let value = '';
    if (this.data !== undefined) {
      const employeeObj = this.data.filter(epD => epD.employee.id == employeeId); // === & = don't not work here. it breaks
      employeeObj[0].payslip.forEach(payslipEntry => {
        if (payslipEntry.formula.uniqueCode === uniqueCode) {
          value = payslipEntry.formula_value;
          return value;
        }
      });
    }
    return value;
  }

  ngAfterViewInit() {

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // @ts-ignore
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.apiService.getAllByIdPaginated(globals.PAYROLL_PERIOD_DETAILS,
            this.payrollPeriodId,
            { pageNo: this.paginator.pageIndex, pageSize: /*this.pageSize*/this.paginator.pageSize, sortBy: /*this.sort.active*/'employee.firstName', sortOrder: this.sort.direction }
          ).pipe(catchError(error => {
              this.isServiceAvailable = false;
              return observableOf(null);
            })
          );
        }),
        map(data => {
          this.isLoadingResults = false;

          if (data === null) {
            return [];
          }

          // @ts-ignore
          this.resultsLength = data.totalElements;
          this.paginator.length = data.totalElements;
          this.title = data.payrollPeriod.name;
          this.getFormulas(); // Must not in Oninit - It's data is needed afterwards
          return data.employeesPayslipDetails;
        })
      ).subscribe(data => this.data = data);
  }

  isSticky (column: string): boolean {
    return column === 'empName' ? true : false;
  }
}
