import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import {Color, Label} from 'ng2-charts';
import * as globals from "../../globals";
import {ApiService} from "../../admin/api.service";

interface PeriodTax {
  entity: any;
  labels: any;
}

@Component({
  selector: 'app-taxes-by-period-chart',
  templateUrl: './taxes-by-period-chart.component.html',
  styleUrls: ['../x-chart.component.css']
  /*styleUrls: ['./taxes-by-period-chart.component.css']*/
})
export class TaxesByPeriodChartComponent implements OnInit {

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales : {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartData: ChartDataSets[] = [
    { data: [], label: '' },
  ];
  public barChartColors: Color[] = [
    { backgroundColor: 'blueviolet' }
  ];

  constructor(public apiService: ApiService) { }

  ngOnInit() {
    this.apiService.get(globals.REPORTS_PERIODS_TAX).subscribe(
      (periodsTaxes: PeriodTax) => {
        const label = periodsTaxes.labels[0]?.labelName;
        this.barChartData[0].label = label;
        for (const taxArray of periodsTaxes.entity) {
          this.barChartData[0].data.push(taxArray.value);
          this.barChartLabels.push(taxArray.periodDates);
        }
      }
    );
  }
}
