import { Component, OnInit, ViewChild } from '@angular/core';
import { Employee } from './employee';
import { ApiService } from 'src/app/admin/api.service';
import { UtilitiesService } from '../../services/utilities.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as globals from 'src/app/globals';
import {GlobalBehavioursService} from '../../services/global-behaviours.service';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'employee-list',
  templateUrl: './employee-list.component.html'
})

export class EmployeeListComponent implements OnInit {
  title = 'payroll-system';

  employees: MatTableDataSource<Employee>;
  displayedColumns: string[] = ['firstName', 'lastName', 'emailAddress', 'manageColumn'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  employee = new Employee();
  updateMode = false;
  listFor: string = undefined;

  constructor(private route: ActivatedRoute,
              private apiService: ApiService,
              public globalBehavioursService: GlobalBehavioursService,
              private utilitiesService: UtilitiesService) {

  }

  isServiceAvailable$ = this.globalBehavioursService.isServiceAvailable$;
  ngOnInit() {
    this.getEmployees();
    this.route.paramMap.subscribe(params => {
      this.listFor = params.get('for');
      console.log(JSON.stringify(params));
      console.log(this.listFor);
    });
  }

  applyFilter(filterValue: string) {
    this.employees.filter = filterValue.trim().toLowerCase();

    if (this.employees.paginator) {
      this.employees.paginator.firstPage();
    }
  }

  getEmployees(): void {
    //this.employeeService.getAll().subscribe(
    this.apiService.getAll(globals.EMPLOYEE_ENDPOINT).subscribe(
      (res: Employee[]) => {
        this.employees = new MatTableDataSource(res);
        this.employees.paginator = this.paginator;
        this.employees.sort = this.sort;
      }/*,
      (err) => {
        this.error = err;
      }*/
    );
  }

  getEmployee(id): void {
    //this.employeeService.getEmployee(id).subscribe(
    this.apiService.getById(globals.EMPLOYEE_ENDPOINT, id).subscribe(
      (res: Employee) => {
        this.employee = res;
      }/*,
      (err) => {
        alert('error: ' + JSON.stringify(err));
        this.error = err;
      }*/
    );
  }

  encryptParam(empId): string{
    if (empId) {
      return this.utilitiesService.Encrypt(empId);
    } else return null;
  }

  displayFn(employee?: Employee): string | undefined {
    return employee ? employee.firstName + ' ' + employee.lastName : undefined;
  }
}
