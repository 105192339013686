import {
  AfterViewInit,
  Component, Injector,
  OnInit, QueryList, ViewChild, ViewChildren
} from "@angular/core";
import {
  ApiService
} from 'src/app/admin/api.service';
import * as globals from 'src/app/globals';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CustomValidators} from "../../services/custom_validators";
import {DataService} from "../data.service";
import {UtilitiesService} from "../../services/utilities.service";
import {ConfirmationDialogService} from "../../services/confirmation-dialog/confirmation-dialog.service";
import {FormService} from "../../services/form";
import {Company} from "../company/company";
import {MatTableDataSource} from "@angular/material/table";
import {Country} from "../countries/country";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {Observable, Subscription, of, merge, of as observableOf} from "rxjs";
import {NotificationService} from "../../services/notification.service";
import {catchError, map, startWith, switchMap} from "rxjs/operators";
import {Costcentre} from "../costcentre/costcentre";
import {PayrollPeriod} from "../payrollPeriod/payrollPeriod";
import {Calendar} from "../calendar/calendar";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {GlobalBehavioursService} from '../../services/global-behaviours.service';

interface CurrenciesObject {
  system?: Country;
  payslip?: Country;
}

interface RateObject {
  id?: number;
  base: Country;
  quote: Country;
  rate: number;
}

@Component({
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css']
})

export class ConfigComponent implements OnInit, AfterViewInit {

  initialLoadEnabled = undefined;

  rForm: FormGroup;
  systemCurrencies: Observable<Country[]>;
  filteredSystemCurrencies:  Observable<Country[]>;
  payslipCurrencies: Observable<Country[]>;
  filteredPayslipCurrencies:  Observable<Country[]>;

  xForm: FormGroup;
  baseCurrencies: Observable<Country[]>;
  filteredBaseCurrencies:  Observable<Country[]>;
  quoteCurrencies: Observable<Country[]>;
  filteredQuoteCurrencies:  Observable<Country[]>;

  rateUpdateMode = false;

  displayedColumns: string[] = ['pair', 'rate', 'manage'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isLoadingResults = true;
  data: any;

  isServiceAvailable = true;

  public formErrors = {
    rate: ''
  };

  @ViewChildren(MatAutocompleteTrigger) triggerCollection: QueryList<MatAutocompleteTrigger>;
  subscription: Subscription;

  constructor(private injector: Injector,
              private dataService: DataService,
              private apiService: ApiService,
              private utilitiesService: UtilitiesService,
              private fb: FormBuilder,
              private confirmationDialogService: ConfirmationDialogService,
              public globalBehavioursService: GlobalBehavioursService,
              public formService: FormService) {
    this.rForm = fb.group({
      systemCurrency: [null],
      payslipCurrency: [null]
    });

    this.xForm = fb.group({
      id: [null],
      baseCurrency: [null, Validators.required],
      quoteCurrency: [null, Validators.required],
      rate: [null, Validators.required],
    });
  }

  //loadButtonclicked = false;
  isServiceAvailable$ = this.globalBehavioursService.isServiceAvailable$;
  notifier = this.injector.get(NotificationService);

  ngOnInit(): void {
    this.apiService.getAll(globals.DATA_LOADER_ENDPOINT).toPromise().then(response => {
      if (response) {
        if (response['message'] == 'Yes') {
          this.initialLoadEnabled = false;
        } else if (response['message'] == 'No') {
          this.initialLoadEnabled = true;
        }
      }
    });
    /**/
    this.getCountries();
    this.filteredSystemCurrencies = this.theSystemCurrency.valueChanges
      .pipe(
        startWith(''),
        switchMap(value => this.filterSystemCurrencies(value))
      );
    this.filteredPayslipCurrencies = this.thePayslipCurrency.valueChanges
      .pipe(
        startWith(''),
        switchMap(value => this.filterPayslipCurrencies(value))
      );
    this.populateCurrencies();

    this.filteredBaseCurrencies = this.theBaseCurrency.valueChanges
      .pipe(
        startWith(''),
        switchMap(value => this.filterBaseCurrencies(value))
      );
    this.filteredQuoteCurrencies = this.theQuoteCurrency.valueChanges
      .pipe(
        startWith(''),
        switchMap(value => this.filterQuoteCurrencies(value))
      );
  }

  triggerLoad(element) {
    this.apiService.saveOnly(globals.DATA_LOADER_ENDPOINT, null).subscribe();
    let self = this;
    setTimeout(function () {
      self.apiService.getAll(globals.DATA_LOADER_ENDPOINT).toPromise().then(response => {
        if (response) {
          if (response['message'] == 'Yes') {
            element.textContent = 'Import done!';
          } else if (response['message'] == 'No') {
            element.textContent = 'Import failed!!!';
          }
        }
      });
    }, 5000);
    element.textContent = 'Importing...';
    element.disabled = true;
  }

  updateCurrencies(f) {
    const currenciesObj = {};
    if (f.systemCurrency !== null) {
      currenciesObj['system'] = {};
      currenciesObj['system']['id'] = f.systemCurrency['id'];
    }
    if (f.payslipCurrency !== null) {
      currenciesObj['payslip'] = {};
      currenciesObj['payslip']['id'] = f.payslipCurrency['id'];
    }

    this.apiService.saveOnly(globals.CURRENCY_ENDPOINT, currenciesObj)
      .subscribe(
        (res) => {
          this.notifier.showSaved();
        }
      );
  }

  populateCurrencies() {

    this.apiService.getById(globals.CURRENCY_ENDPOINT, '').subscribe(
      (currenciesObject: CurrenciesObject) => {
        if (currenciesObject != null) {
          this.rForm.patchValue({
            systemCurrency: currenciesObject?.system,
            payslipCurrency: currenciesObject?.payslip // (currenciesObject?.payslip !== null) ? currenciesObject?.payslip : null
          });
        }
      });
  }

  ngAfterViewInit() {
    this._subscribeToClosingActions();
    /**/
    // alert('YES');
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // @ts-ignore
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.apiService.getAllPaginated(globals.CURRENCY_RATE_ENDPOINT,
            {pageNo: this.paginator.pageIndex, pageSize: this.paginator.pageSize, sortBy: this.sort.active, sortOrder: this.sort.direction}
          ).pipe(catchError(error => {
              this.isServiceAvailable = false;
              return observableOf(null);
            })
          );
        }),
        map(data => {
          this.isLoadingResults = false;

          if (data === null) {
            return [];
          }

          // @ts-ignore
          // this.resultsLength = data.totalElements;
          this.paginator.length = data.totalElements;
          return data.content;
        })
      ).subscribe(data => this.data = data);
  }

  private _subscribeToClosingActions(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.triggerCollection.toArray()[0].panelClosingActions
      .subscribe(e => {
          if (!e || !e.source) {
            if (!this.utilitiesService.isObject(this.rForm.getRawValue().country))
              this.rForm.controls.systemCurrency.setValue(null);
          }
        },
        err => this._subscribeToClosingActions(),
        () => this._subscribeToClosingActions());

    this.subscription = this.triggerCollection.toArray()[1].panelClosingActions
      .subscribe(e => {
          if (!e || !e.source) {
            if (!this.utilitiesService.isObject(this.rForm.getRawValue().country))
              this.rForm.controls.payslipCurrency.setValue(null);
          }
        },
        err => this._subscribeToClosingActions(),
        () => this._subscribeToClosingActions());

    this.subscription = this.triggerCollection.toArray()[2].panelClosingActions
      .subscribe(e => {
          if (!e || !e.source) {
            if (!this.utilitiesService.isObject(this.xForm.getRawValue().country))
              this.xForm.controls.baseCurrency.setValue(null);
          }
        },
        err => this._subscribeToClosingActions(),
        () => this._subscribeToClosingActions());

    this.subscription = this.triggerCollection.toArray()[3].panelClosingActions
      .subscribe(e => {
          if (!e || !e.source) {
            if (!this.utilitiesService.isObject(this.xForm.getRawValue().country))
              this.xForm.controls.quoteCurrency.setValue(null);
          }
        },
        err => this._subscribeToClosingActions(),
        () => this._subscribeToClosingActions());
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  getCountries(): void {
    const countriesList = this.apiService.getAll(globals.COUNTRY_ENDPOINT);
    this.systemCurrencies = countriesList;
    this.payslipCurrencies = countriesList;
    this.baseCurrencies = countriesList;
    this.quoteCurrencies = countriesList;
  }

  private filterSystemCurrencies(value: string | Country) {
    let filterValue = '';
    if (value) {
      filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
      return this.systemCurrencies.pipe(
        map(systemCurrencies => systemCurrencies.filter(systemCurrency => systemCurrency.name.toLowerCase().includes(filterValue)))
      );
    } else {
      return this.systemCurrencies;
    }
  }

  displaySystemCurrencyFn(country?: Country): string | undefined {
    return country ? country.name + " " + "(" + country.currencyCode +")" : undefined;
  }

  get theSystemCurrency() {
    return this.rForm.get('systemCurrency');
  }

  private filterPayslipCurrencies(value: string | Country) {
    let filterValue = '';
    if (value) {
      filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
      return this.payslipCurrencies.pipe(
        map(payslipCurrencies => payslipCurrencies.filter(payslipCurrencies => payslipCurrencies.name.toLowerCase().includes(filterValue)))
      );
    } else {
      return this.payslipCurrencies;
    }
  }

  displayPayslipCurrencyFn(country?: Country): string | undefined {
    return country ? country.name + " " + "(" + country.currencyCode +")" : undefined;
  }

  get thePayslipCurrency() {
    return this.rForm.get('payslipCurrency');
  }

  private filterBaseCurrencies(value: string | Country) {
    let filterValue = '';
    if (value) {
      filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
      return this.baseCurrencies.pipe(
        map(baseCurrencies => baseCurrencies.filter(baseCurrency => baseCurrency.name.toLowerCase().includes(filterValue)))
      );
    } else {
      return this.baseCurrencies;
    }
  }

  displayBaseCurrencyFn(country?: Country): string | undefined {
    return country ? country.name + " " + "(" + country.currencyCode +")" : undefined;
  }

  get theBaseCurrency() {
    return this.xForm.get('baseCurrency');
  }

  private filterQuoteCurrencies(value: string | Country) {
    let filterValue = '';
    if (value) {
      filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
      return this.quoteCurrencies.pipe(
        map(quoteCurrencies => quoteCurrencies.filter(quoteCurrencies => quoteCurrencies.name.toLowerCase().includes(filterValue)))
      );
    } else {
      return this.quoteCurrencies;
    }
  }

  displayQuoteCurrencyFn(country?: Country): string | undefined {
    return country ? country.name + " " + "(" + country.currencyCode +")" : undefined;
  }

  get theQuoteCurrency() {
    return this.xForm.get('quoteCurrency');
  }

  saveRate(f) {
    const rateObject: RateObject = {
      base: f.baseCurrency,
      quote: f.quoteCurrency,
      rate: f.rate
    };
    this.apiService.saveOnly(globals.CURRENCY_RATE_ENDPOINT, rateObject)
      .subscribe(
        (res) => {
          this.resetXForm();
          this.ngAfterViewInit();
          this.rateUpdateMode = false;
          this.notifier.showSaved();
        }, error => this.isServiceAvailable = false);
  }

  xFormEdit(id: number) {
    this.apiService.getById(globals.CURRENCY_RATE_ENDPOINT, id).subscribe(
      (rateObject: RateObject) => {
        if (rateObject != null) {
          /*this.xForm.setValue({
            id: rateObject?.id,
            baseCurrency: rateObject?.base,
            quoteCurrency: rateObject?.quote,
            rate:  rateObject?.rate
          });*/
          this.xForm.controls.id.setValue(rateObject?.id);
          this.xForm.controls.baseCurrency.reset({value: rateObject?.base, disabled: true});
          this.xForm.controls.quoteCurrency.reset({value: rateObject?.quote, disabled: true});
          this.xForm.controls.rate.setValue(rateObject?.rate);
          this.rateUpdateMode = true;
        }
      });
  }

  updateRate(f) {
    const rateObject: RateObject = {
      id: f.id, // needed
      base: f.baseCurrency, // will be ignored by backend for update
      quote: f.quoteCurrency, // will be ignored by backend for update
      rate: f.rate // needed
    };

    this.apiService.updateOnly(globals.CURRENCY_RATE_ENDPOINT, rateObject)
      .subscribe(
        (res) => {
          this.ngAfterViewInit();
          this.rateUpdateMode = false;
          this.setCreateMode();
          this.notifier.showSaved();
        }, error => this.isServiceAvailable = false);
  }

  resetXForm() {
    this.xForm.reset();
  }

  setCreateMode() {
    this.xForm.controls.baseCurrency.reset({value: '', disabled: false});
    this.xForm.controls.quoteCurrency.reset({value: '', disabled: false});
    this.xForm.reset();
    this.rateUpdateMode = false;
  }

  private deleteRate(id) {
    this.apiService.deleteOnly(globals.CURRENCY_RATE_ENDPOINT, id)
      .subscribe(
        (res: boolean) => {
          this.notifier.showDeleted();
          this.ngAfterViewInit();
          this.setCreateMode();
        }/*,
          (err) => this.error = err*/
      );
  }

  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm...', 'Are you sure you want to delete?')
      .then((confirmed) => {
        if (confirmed) this.deleteRate(id);
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
}
