import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import {Color, Label, SingleDataSet} from 'ng2-charts';
import {ApiService} from '../../admin/api.service';
import * as globals from '../../globals';

interface GenderReport {
  male: number;
  female: number;
  unspecified: number;
}

@Component({
  selector: 'app-employee-composition-chart',
  templateUrl: './employee-composition-chart.component.html',
  styleUrls: ['../x-chart.component.css']
  /*styleUrls: ['./employee-composition-chart.component.css']*/
})
export class EmployeeCompositionChartComponent implements OnInit {

  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  /*public pieChartLabels: Label[] = ['Download Sales', 'In-Store Sales', 'Mail Sales'];
  public pieChartData: SingleDataSet = [300, 500, 100];*/
  public pieChartLabels: Label[] = ['Men', 'Women', 'Unspecified'];
  public pieChartData: SingleDataSet = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public pieChartColors: Color[] = [
    { backgroundColor: ['blueviolet', 'green', 'red'] }
  ];

  constructor(public apiService: ApiService) { }

  ngOnInit() {
    this.apiService.get(globals.REPORTS_GENDER).subscribe(
      (genderReport: GenderReport) => {
        this.pieChartData.push(genderReport.male);
        this.pieChartData.push(genderReport.female);
        this.pieChartData.push(genderReport.unspecified);
      }
    );
  }

}
