import {Component, OnInit} from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import {ApiService} from '../admin/api.service';
import * as globals from '../globals';

interface Label {
  recordId?: number;
  labelName: string;
  labelValue: string;
}
@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.scss']
})
export class DashComponent implements OnInit {
  cardLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return {
          columns: 1,
          miniCard: { cols: 1, rows: 1 },
          chart: { cols: 1, rows: 2 },
          table: { cols: 1, rows: 4 },
        };
      }

      return {
        columns: 4,
        miniCard: { cols: 1, rows: 1 },
        chart: { cols: 2, rows: 2 },
        table: { cols: 4, rows: 4 },
      };
    })
  );
  /** Based on the screen size, switch from standard to one column per row */
  /*cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return [
          { title: 'Card 1', cols: 1, rows: 1 },
          { title: 'Card 2', cols: 1, rows: 1 },
          { title: 'Card 3', cols: 1, rows: 1 },
          { title: 'Card 4', cols: 1, rows: 1 }
        ];
      }

      return [
        { title: 'Card 1', cols: 2, rows: 1 },
        { title: 'Card 2', cols: 1, rows: 1 },
        { title: 'Card 3', cols: 1, rows: 2 },
        { title: 'Card 4', cols: 1, rows: 1 }
      ];
    })
  );*/

  employeeCount: number;
  lastPeriodSummary: Label = {labelName: '', labelValue: '0'};
  periods: [];

  // Open periods
  periodIndex = 0;
  nextDisabled = true;
  numberOfPeriods = 0;

  processedPayrollCount = 0;
  pastCosts = [];

  constructor(private breakpointObserver: BreakpointObserver, public apiService: ApiService) {}

  ngOnInit() {
    this.apiService.get(globals.REPORTS_ACTIVE_EMPLOYEE_COUNT).subscribe({
      next: employeeCount => {
        this.employeeCount = employeeCount;
      }
    });

    this.apiService.get(globals.REPORTS_LAST_PERIOD_COST).subscribe(
      (lastPeriodSummary: Label) => {
        if (lastPeriodSummary !== null) {
          this.lastPeriodSummary.recordId = lastPeriodSummary['recordId'];
          this.lastPeriodSummary.labelName = lastPeriodSummary['labelName'];
          this.lastPeriodSummary.labelValue = lastPeriodSummary['labelValue'];
        }
    });

    this.apiService.get(globals.REPORTS_OPEN_PERIODS).subscribe(
      (report: {
        entity: [];
      }) => {
        this.periods = report?.entity;

        this.numberOfPeriods = this.periods.length;
        if (this.numberOfPeriods > 1) {
          this.nextDisabled = false;
        }
    });

    this.apiService.get(globals.REPORTS_PROCESSED_PAYROLL_COUNT).subscribe(
      (count: number) => {
        this.processedPayrollCount = count;
      });
  }

  /*This is a workaround to allow expenses by cost centre to fit in the resized screen after reload. Invoked from html*/
  onResize(event) {
    window.location.reload();
  }
}
