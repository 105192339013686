import {Component, Injector, AfterViewInit, ViewChild, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import { Absence } from './absence';
import { NotificationService } from '../../services/notification.service';
import { ApiService } from 'src/app/admin/api.service';
import * as globals from 'src/app/globals';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {ConfirmationDialogService} from '../../services/confirmation-dialog/confirmation-dialog.service';
import {UtilitiesService} from '../../services/utilities.service';
import {CustomValidators} from "../../services/custom_validators";
import {FormService} from "../../services/form";
import {GlobalBehavioursService} from '../../services/global-behaviours.service';

@Component({
  selector: 'app-absence',
  templateUrl: './absence.component.html',
  styleUrls: ['./absence.component.css']
})

export class AbsenceComponent implements OnInit, AfterViewInit  {
  title = 'payroll-frontend';
  displayedColumns: string[] = ['employee', 'absenceType', 'date', 'duration', 'approversComment', 'manage'];
  data: any;
  resultsLength = 0;
  isLoadingResults = true;
  isServiceAvailable = true;
  updateMode = false;
  rForm: FormGroup;
  requiredAlert = 'This field is required';
  employees = [];
  absenceTypes = [];
  pageSize = 20;

  public formErrors = {
    employee: '',
    absenceType: '',
    date: '',
    duration: '',
    approversComment: '',
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public injector: Injector,
              public apiService: ApiService,
              public fb: FormBuilder,
              public utilitiesService: UtilitiesService,
              public confirmationDialogService: ConfirmationDialogService,
              public globalBehavioursService: GlobalBehavioursService,
              public formService: FormService) {

    this.rForm = fb.group({
      id: [null],
      employee:  [null, Validators.required],
      absenceType: [null, Validators.required],
      date:  [null, Validators.required],
      duration:  [null, [Validators.required, CustomValidators.validHoursToNumberForLeave]],
      approversComment: [null, [Validators.minLength(1), Validators.maxLength(100)]],
    });

    this.rForm.valueChanges.subscribe((data) => {
      this.formErrors = this.formService.validateForm(this.rForm, this.formErrors, true);
    });

  }

  test() {
    return this.requiredAlert;
  }

  isServiceAvailable$ = this.globalBehavioursService.isServiceAvailable$;
  notifier = this.injector.get(NotificationService);

  createAbsence(f: any) {
    const absence: Absence = {
      'employee': (f.employee) ? this.utilitiesService.generateQuickIdObject(f.employee) : null,
      'absenceType': (f.absenceType) ? this.utilitiesService.generateQuickIdObject(f.absenceType) : null,
      'date': f.date,
      'duration': f.duration,
      'approversComment': f.approversComment
    };

    this.apiService.saveOnly(globals.ABSENCE_ENDPOINT, absence).subscribe(_ => {
      this.notifier.showSaved();
      this.resetForm();
      this.ngAfterViewInit();
      this.updateMode = false;
    }, error => this.isServiceAvailable = false);
  }

  formEdit(id: number) {
    this.apiService.getById(globals.ABSENCE_ENDPOINT, id).subscribe(
      (absence: Absence) => {
        this.rForm.controls.id.setValue(absence.id);
        this.rForm.controls.employee.reset({value: (absence.employee != null) ? absence.employee.id : null, disabled: true});
        this.rForm.controls.absenceType.reset({value: (absence.absenceType != null) ? absence.absenceType.id : null, disabled: true});
        this.rForm.controls.date.reset({value: absence.date, disabled: true});
        this.rForm.controls.duration.reset({value: absence.duration, disabled: true});
        this.rForm.controls.approversComment.setValue(absence.approversComment);
        this.updateMode = true;
        window.scroll(0, 0);
      });
  }

  updateAbsence(f: any) {
    const absence: Absence = {
      'id': f.id,
      'approversComment': f.approversComment
    };

    return this.apiService.updateOnly(globals.ABSENCE_ENDPOINT, absence).subscribe(_ => {
      this.notifier.showSaved();
      this.setCreateMode();
      this.ngAfterViewInit();
    }, error => this.isServiceAvailable = false);
  }

  ngOnInit() {
    this.getEmployees();
    this.getAbsenceTypes();
  }

  getEmployees() {
    this.apiService.getAll(globals.EMPLOYEE_ENDPOINT).subscribe(
      (res: []) => {
        this.employees = res;
      }
    );
  }

  getAbsenceTypes() {
    this.apiService.getAll(globals.ABSENCE_TYPE_ENDPOINT).subscribe(
      (res: []) => {
        this.absenceTypes = res;
      }
    );
  }

  ngAfterViewInit() {

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // @ts-ignore
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.apiService.getAllPaginated(globals.ABSENCE_ENDPOINT, {pageNo: this.paginator.pageIndex, pageSize: this.pageSize, sortBy: this.sort.active, sortOrder: this.sort.direction}
          ).pipe(catchError(error => {
              this.isServiceAvailable = false;
              return observableOf(null);
            })
          );
        }),
        map(data => {
          this.isLoadingResults = false;

          if (data === null) {
            return [];
          }

          // @ts-ignore
          this.resultsLength = data.totalElements;
          return data.content;
        })
      ).subscribe(data => this.data = data);
  }

  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm...', 'Are you sure?')
      .then((confirmed) => {
        if (confirmed) this.deleteAbsence(id);
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  private deleteAbsence(id) {
    this.apiService.deleteOnly(globals.ABSENCE_ENDPOINT, id)
      .subscribe(
        (res: boolean) => {
          this.notifier.showDeleted();
          this.ngAfterViewInit(); // Amend to only do this if above returns 200
          this.setCreateMode();
        }/*,
          (err) => this.error = err*/
      );
  }

  addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  resetForm() {
    this.rForm.reset();
  }

  setCreateMode() {
    this.rForm.reset();
    this.rForm.controls.employee.reset({value: null, disabled: false});
    this.rForm.controls.absenceType.reset({value: null, disabled: false});
    this.rForm.controls.date.reset({value: null, disabled: false});
    this.rForm.controls.duration.reset({value: null, disabled: false});
    this.updateMode = false;
  }
}
