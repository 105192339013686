import {Component, Injector, AfterViewInit, ViewChild} from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import { NotificationService } from '../../services/notification.service';
import { ApiService } from 'src/app/admin/api.service';
import * as globals from 'src/app/globals';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {ConfirmationDialogService} from '../../services/confirmation-dialog/confirmation-dialog.service';
import {UtilitiesService} from '../../services/utilities.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-calendar',
  templateUrl: './employeePeriods.component.html'
})

export class EmployeePeriodsComponent implements AfterViewInit  {
  title = 'payroll-system';
  displayedColumns: string[] = ['payDate', 'action'];
  data: any;
  resultsLength = 0;
  isLoadingResults = true;
  isServiceAvailable = true;
  pageSize = 20;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public injector: Injector,
              public apiService: ApiService,
              private router: Router) {
  }

  notifier = this.injector.get(NotificationService);

  ngAfterViewInit() {

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // @ts-ignore
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.apiService.getAllPaginated(globals.PERIODS_BY_EMPLOYEE,
            {pageNo: this.paginator.pageIndex,
              pageSize: this.pageSize,
              sortBy: 'payrollPeriod.payDate', /*Cannot change this value as it will require a backend change since it needs to be in the select statement*/
              sortOrder:
              this.sort.direction}
          ).pipe(catchError(error => {
              this.isServiceAvailable = false;
              return observableOf(null);
            })
          );
        }),
        map(data => {
          this.isLoadingResults = false;

          if (data === null) {
            return [];
          }

          // @ts-ignore
          this.resultsLength = data.totalElements;
          return data.content;
        })
      ).subscribe(data => this.data = data);
  }

  viewPayslip(payrollPeriodId) {
    this.router.navigate(['/payslip/' + payrollPeriodId]);
  }

  downloadPayslip(payrollPeriodId) {
    this.apiService.getFileWithParam(globals.EMPLOYEE_ENDPOINT + '/download-payslip', {payrollPeriodId: payrollPeriodId}).subscribe((data) => {
      // @ts-ignore
      const blob = new Blob([data], {type: 'application/pdf'});

      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = this.generateFilename();
      link.click();

    });
  }

  generateFilename(): string {
    const date = new Date();
    console.log('->' + date);
    const day = date.getDate();
    const monthIndex = date.getMonth() + 1;
    const year = date.getFullYear();
    const minutes = date.getMinutes();
    const hours = date.getHours();
    const seconds = date.getSeconds();

    return 'Payslip_' + year + '-' + monthIndex + '-' + day + '_' + hours + '_' + minutes + '_' + seconds + '.pdf';
  }
}
