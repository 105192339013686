import {Injectable, OnInit} from "@angular/core";
import {ApiService} from "../admin/api.service";
import {Country} from "../admin/countries/country";
import * as globals from "../globals";

import { registerLocaleData } from '@angular/common';
import frCDLocale from '@angular/common/locales/fr-CD';
registerLocaleData(frCDLocale, 'fr-CD');
import enZWLocale from '@angular/common/locales/en-ZW';
registerLocaleData(enZWLocale, 'en-ZW');
import enUSLocale from '@angular/common/locales/en-US-POSIX';
registerLocaleData(enUSLocale, 'en-ZW');
import enZALocale from '@angular/common/locales/en-ZA';
registerLocaleData(enZALocale, 'en-ZA');

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  isServiceAvailable = true;

  payslipRate = 0; // 1 By default
  systemCurrency: Country = null;
  payslipCurrency: Country = null;
  exchangeRate;
  locale = null;

  constructor(private apiService: ApiService) {
  }

  setDefaultCurrency() {
    const self = this;
    return new Promise((resolve, reject) => {
      this._setDefaultCurrency('system').then(function (systemCurrency) {
        self.systemCurrency = systemCurrency;
      }).then(x => {
        this._setDefaultCurrency('payslip').then(function (payslipCurrency) {
          self.payslipCurrency = payslipCurrency;
        }).then(x => {
          this.getExchangeRate().then(function (rate) {
            self.exchangeRate = rate;
            resolve();
          }).catch(function(e) {
            console.log('Error setting exchange rate! Please contact system administrator');
          });
        }).catch(function(e) {
          console.log('Error setting currencies! Please contact system administrator');
        });
      });
    });
  }

  private _setDefaultCurrency(what: string) {
    const self = this;
    return new Promise((resolve, reject) => {
      this.apiService.getById(globals.CURRENCY_ENDPOINT + '/' + what, '').subscribe(
        (country: Country) => {
          if (what === 'system') {
            resolve(country);
          }
          if (what === 'payslip') {
            resolve(country);
          }
        }, error => this.isServiceAvailable = false);
    });
  }

  getExchangeRate() {
    const self = this;
    return new Promise((resolve, reject) => {
      if (this.systemCurrency.id === this.payslipCurrency.id) {
        resolve(1);
      }
      this.apiService.getById(globals.CURRENCY_RATE_ENDPOINT + '/pair/' + this.systemCurrency.id + '/' + this.payslipCurrency.id, '').subscribe(
        (exchangeRate: {rate: number;}) => {
          if (exchangeRate?.rate) {
            resolve(exchangeRate['rate']);
          } else {
            console.log('Unable to retrieve exchange rate ' + self.systemCurrency.id + '/' + self.payslipCurrency.id);
            reject();
          }
        }, error => this.isServiceAvailable = false);
    });
  }

  getCurrencyCode(forPayslip?: boolean): string { //new
    if (forPayslip !== undefined) {
      if (forPayslip) {
        return this.payslipCurrency?.currencyCode;
      } else { return this.systemCurrency?.currencyCode; }
    }
    return this.systemCurrency?.currencyCode;
  }

  convertToPayslipCurrency(amount: number) {
    return amount * this.exchangeRate;
  }

  getLocale(forPayslip?: boolean) {
    let country;
    if (forPayslip !== undefined) {
      if (forPayslip) {
        country = this.payslipCurrency;
        return this._getLocale(country?.code);
      } else {
        country = this.systemCurrency;
        return this._getLocale(country?.code);
      }
    }
    country = this.systemCurrency;
    return this._getLocale(country?.code);
  }

  private _getLocale(countryCode: string) {
    switch (countryCode) {
      case 'CD':
        return 'fr-CD';
        break;
      case 'US':
        return 'en-US-POSIX';
        break;
      case 'ZA':
        return 'en-ZA';
        break;
      case 'ZW':
        return 'en-ZW';
        break;
      default:
       return null;
       break;
    }
  }
}
