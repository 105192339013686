import {Component, OnInit, Input, Injector} from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { Location } from '@angular/common';
import { PayslipService } from './payslip.service';
import { DataService } from '../../admin/data.service';
import { PayrollPeriod } from '../../admin/payrollPeriod/payrollPeriod';
import { FormulaService } from '../../admin/formula/formula.service';
import { Formula } from '../../admin/formula/formula';

import { AuthService } from '../../services/auth.service';
import {Employee} from '../../admin/employee/employee';
import {Company} from '../../admin/company/company';
import {ApiService} from '../../admin/api.service';
import {UtilitiesService} from '../../services/utilities.service';
import {CurrencyService} from '../../services/currency.service';
import * as globals from '../../globals';

interface Logo {
  picByte?: any;
  company: Company;
  hasImage?: boolean; //FOR DOWNLOAD
  file?: any; //FOR UPLOAD
}

@Component({
    /*templateUrl: './payslip.component.html'*/
  templateUrl: '../../shared/view-payslip.html',
  styleUrls: ['../../shared/view-payslip.css']
  })

// This class is an almost exact duplicate of PayslipComponent (Employee facing). Make sure the changes are in sync
export class PayslipComponent implements OnInit {

  payrollPeriodId = '';
  employeeId = '';
  employee: Employee = {};
  payslipEntries = {};
  company: Company = {};
  payrollPeriod: PayrollPeriod = {};

  constructor(
    private injector: Injector,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    public utilitiesService: UtilitiesService,
    public currencyService: CurrencyService,
    private location: Location
  ) {}

  ngOnInit() {
    this.currencyService.setDefaultCurrency().then(r => { // Wrapped inside promise to ensure currencies are set first.
      this.payrollPeriodId = this.route.snapshot.paramMap.get('ppId');
      this.employeeId = this.route.snapshot.paramMap.get('eId');
      this.getEmployeeDetails();
      this.getPayslipEntries(); // DONE
      this.setPayrollPeriod();
      /*alert('this.currencyService.systemCurrency?.currencyCode: ' + this.currencyService.systemCurrency?.currencyCode);
      alert('this.currencyService.payslipCurrency?.currencyCode: ' + this.currencyService.payslipCurrency?.currencyCode);
      alert('this.currencyService.exchangeRate ' + this.currencyService.exchangeRate);*/
    }).catch(function(e) {
      console.log('Error setting currencies! Please contact system administrator');
    });
  }

  getEmployeeDetails() {
    this.apiService.getSelf(globals.EMPLOYEE_ENDPOINT)
      .subscribe(
        (employee: Employee) => {
          this.employee = employee;
          this.setCompany(this.employee.department?.company?.id);
        }/*,
          (err) => {
            this.error = err;
          }*/
      );
  }

  setCompany(companyId) {
    this.apiService.getById(globals.COMPANY_ENDPOINT, companyId)
      .subscribe(
        (company: Company) => {
          this.company = company;
          this.getCompanyLogo();
        }/*,
          (err) => {
            this.error = err;
          }*/
      );
  }

  setPayrollPeriod() {
    this.apiService.getById(globals.PAYROLL_PERIOD_ENDPOINT, this.payrollPeriodId)
      .subscribe(
        payrollPeriodId => this.payrollPeriodId = payrollPeriodId/*,
          (err) => {
            this.error = err;
          }*/
      );
  }

  getPayslipEntries() { // DONE
    this.apiService.getWithParams(globals.EMPLOYEE_ENDPOINT + '/payslip', {'ppId': this.payrollPeriodId})
      .subscribe(
        payslipEntries => this.payslipEntries = payslipEntries/*,
          (err) => {
            this.error = err;
          }*/
      );
  }

  getSingleFormulaByType(formulaType) {
    let formula = {};
    if (this.payslipEntries['payslip']) {
      this.payslipEntries['payslip'].forEach(function (entry) {
        if (entry.formula.formulaType.value.toLowerCase() === formulaType.toLowerCase()) {
          formula = {
            'name': entry.formula.name,
            'formula_value': entry.formula_value,
            'notMonetary': entry.formula.notMonetary
          };
        }
      });
    }
    return formula;
  }

  getFormulasByType(formulaType, exceptUniqueCodeList: string[]) { // Should only be used for universal codes i.e. grade, brut...
    let response = [];
    const self = this;
    if (this.payslipEntries['payslip']) {
      this.payslipEntries['payslip'].forEach(function (entry) {
        if (entry.formula.formulaType.value.toLowerCase() === formulaType.toLowerCase()) {
          if (self.isExempted(entry.formula.uniqueCode, exceptUniqueCodeList) === false) {
            let formula = {
              'name': entry.formula.name,
              'formula_value': entry.formula_value,
              'notMonetary': entry.formula.notMonetary
            };
            response.push(formula);
          }
        }
      });
    }
    return response;
  }

  isExempted(uniqueCode: string, exceptUniqueCodeList: string[]): boolean {
    if (exceptUniqueCodeList.length > 0) {
      if (exceptUniqueCodeList.includes(uniqueCode)) {
        // console.log(uniqueCode + ' --is not included');
        return true;
      }
    }
    // console.log(uniqueCode + ' ++is included');
    return false;
  }

  selectedFile: File;
  retrievedLogo: any;
  base64Data: any;
  retrievedLogoResponse: Logo;
  imageName: any;
  sizeToUse = 'width';

  getCompanyLogo() {
    const self = this;
    this.apiService.getWithParams(globals.LOGO_ENDPOINT, {companyId: this.company.id, getParentLogoIfNull: true})
      .subscribe(
        (res: Logo) => {
          this.retrievedLogoResponse = res;
          if (this.retrievedLogoResponse.hasImage) {
            this.base64Data = this.retrievedLogoResponse.picByte;
            this.retrievedLogo = 'data:image/jpeg;base64,' + this.base64Data;

            const img = new Image();
            img.src = this.retrievedLogo; // myBase64;
            img.addEventListener('load', function() {
              if (img.width > img.height) {
                self.sizeToUse = 'width';
              } else if (img.height > img.width) {
                self.sizeToUse = 'height';
              }
            });
          }
        }
      );
  }
}
