import {Component, Injector, AfterViewInit, ViewChild, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import {TaxYear} from 'src/app/admin/taxyear/taxyear';
import { NotificationService } from '../../services/notification.service';
import { ApiService } from 'src/app/admin/api.service';
import * as globals from 'src/app/globals';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {ConfirmationDialogService} from '../../services/confirmation-dialog/confirmation-dialog.service';
import {UtilitiesService} from '../../services/utilities.service';
import {Country} from '../countries/country';
import {FormService} from "../../services/form";
import {GlobalBehavioursService} from '../../services/global-behaviours.service';

@Component({
  selector: 'app-taxYear',
  templateUrl: './tax-year.component.html'
})

export class TaxYearComponent implements OnInit, AfterViewInit  {
  title = 'payroll-system';
  displayedColumns: string[] = ['description', 'startDate', 'endDate', 'country', 'usePayslipCurrency', 'manage'];
  data: any;
  resultsLength = 0;
  isLoadingResults = true;
  isServiceAvailable = true;
  updateMode = false;
  rForm: FormGroup;
  requiredAlert = 'This field is required';
  countries = [];
  pageSize = 20;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public formErrors = {
    country: '',
    description: '',
    startDate: '',
    endDate: '',
    usePayslipCurrency: ''
  };

  constructor(public injector: Injector,
              public apiService: ApiService,
              public fb: FormBuilder,
              public utilitiesService: UtilitiesService,
              public confirmationDialogService: ConfirmationDialogService,
              public globalBehavioursService: GlobalBehavioursService,
              public formService: FormService) {

    this.rForm = fb.group({
      id: [null],
      country:  [null, Validators.required],
      description: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      startDate:  [null, Validators.required],
      endDate:  [null, Validators.required],
      usePayslipCurrency: [null]
    });

    this.rForm.valueChanges.subscribe((data) => {
      this.formErrors = this.formService.validateForm(this.rForm, this.formErrors, true);
    });
  }

  isServiceAvailable$ = this.globalBehavioursService.isServiceAvailable$;
  notifier = this.injector.get(NotificationService);

  createTaxYear(f: any) {
    const taxYear: TaxYear = {
      'country': (f.country) ? this.utilitiesService.generateQuickIdObject(f.country) : null,
      'description': f.description,
      'startDate': f.startDate,
      'endDate': f.endDate,
      'usePayslipCurrency': f.usePayslipCurrency,
      'id': f.id
    };

    this.apiService.saveOnly(globals.TAX_YEAR_ENDPOINT, taxYear).subscribe(_ => {
      this.notifier.showSaved();
      this.resetForm();
      this.ngAfterViewInit();
      this.updateMode = false;
    }, error => this.isServiceAvailable = false);
  }

  formEdit(id: number) {
    this.apiService.getById(globals.TAX_YEAR_ENDPOINT, id).subscribe(
      (taxYear: TaxYear) => {
        this.rForm.controls.id.setValue(taxYear.id);
        this.rForm.controls.country.setValue((taxYear.country != null) ? taxYear.country.id : null);
        this.rForm.controls.description.setValue(taxYear.description);
        this.rForm.controls.startDate.setValue(taxYear.startDate);
        this.rForm.controls.endDate.setValue(taxYear.endDate);
        this.rForm.controls.usePayslipCurrency.setValue(taxYear.usePayslipCurrency);
        this.updateMode = true;
        window.scroll(0, 0);
      });
  }

  updateTaxYear(f: any) {
    const taxYear: TaxYear = {
      'id': f.id,
      'description': f.description,
      'startDate': f.startDate,
      'endDate': f.endDate,
      'country': this.utilitiesService.generateQuickIdObject(f.country),
      'usePayslipCurrency': f.usePayslipCurrency
    };

    return this.apiService.updateOnly(globals.TAX_YEAR_ENDPOINT, taxYear).subscribe(_ => {
      this.notifier.showSaved();
      this.setCreateMode();
      this.ngAfterViewInit();
    }, error => this.isServiceAvailable = false);
  }

  ngOnInit() {
    this.getCountries();
  }

  getCountries() {
    this.apiService.getAll(globals.COUNTRY_ENDPOINT).subscribe(
      (res: Country[]) => {
        this.countries = res;
      }
    );
  }

  ngAfterViewInit() {

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // @ts-ignore
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.apiService.getAllPaginated(globals.TAX_YEAR_ENDPOINT, {pageNo: this.paginator.pageIndex, pageSize: this.pageSize, sortBy: this.sort.active, sortOrder: this.sort.direction}
          ).pipe(catchError(error => {
              this.isServiceAvailable = false;
              return observableOf(null);
            })
          );
        }),
        map(data => {
          this.isLoadingResults = false;

          if (data === null) {
            return [];
          }

          // @ts-ignore
          this.resultsLength = data.totalElements;
          return data.content;
        })
      ).subscribe(data => this.data = data);
  }

  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm...', 'This may cause problems if used elsewhere in the application.')
      .then((confirmed) => {
        if (confirmed) this.deleteTaxYear(id);
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  private deleteTaxYear(id) {
    this.apiService.deleteOnly(globals.TAX_YEAR_ENDPOINT, id)
      .subscribe(
        (res: boolean) => {
          this.notifier.showDeleted();
          this.ngAfterViewInit(); // Amend to only do this if above returns 200
          this.setCreateMode();
        }/*,
          (err) => this.error = err*/
      );
  }

  resetForm() {
    this.rForm.reset();
  }

  setCreateMode() {
    this.rForm.reset();
    this.updateMode = false;
  }
}
