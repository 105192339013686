import {Component, Input} from '@angular/core';
import {GlobalBehavioursService} from '../../services/global-behaviours.service';
import {Observable, of} from 'rxjs';

@Component({
  selector: 'app-error-banner',
  templateUrl: './error-banner.component.html',
  styleUrls: ['./error-banner.component.scss']
})
export class ErrorBannerComponent {
  @Input()
  isServiceAvailable = false;

  //isServiceAvailable$ = of(true);
  constructor(private globalBehavioursService: GlobalBehavioursService) {
  }

  dismiss() {
    // this.isServiceAvailable = true;
    this.globalBehavioursService.updateIsServiceAvailable(true);
    //this.isServiceAvailable$ = this.globalBehavioursService.isServiceAvailable$;
  }

  refresh() {
    window.location.reload();
  }
}
