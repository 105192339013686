import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalBehavioursService} from './global-behaviours.service';

import { NotificationService } from '../services/notification.service'; // new

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {

    constructor(private injector: Injector, private globalBehavioursService: GlobalBehavioursService) { }

    handleError(error: any) {
        const notifier = this.injector.get(NotificationService); // new
        let router = this.injector.get(Router);
        console.log('URL: ' + router.url);

    if (error instanceof HttpErrorResponse) {
        if (error.status === 0) {
            if (error.statusText) {
                if (error.statusText === 'Unknown Error') {
                    console.log('Server not reachable.');
                }
            }
        }
        if (error.error) {
            if (error.status >= 400 && error.status < 500 ) {

                if (error.error.error_description) {// when error.error="invalid_grant" or related errors
                    notifier.showError(error.error.error_description);
                } else if (((error.error.error) && (error.error.message === "No message available")) || (error.error.message === '')) {
                    notifier.showError(error.error.error);
                    console.error('An error occurred:', error.error.error);
                } else if (error.error.message) {
                    notifier.showError(error.error.message);
                    console.error('An error occurred:', error.error.message);
                } else if ((error.error.error) || (error.error.message === 'No message available')) {
                    notifier.showError(error.error.error);
                    console.error('An error occurred:', error.error.error);
                } else {
                    notifier.showError('An error occurred!');
                    console.error('An error occurred!');
                }

            } else if (error.status === 500) {
                let statusText = error.statusText;
                if (error.error){
                    if (error.error['message']) {
                      console.error(error.error.message);
                      if (error.error.message !== '') {
                        notifier.showError(error.error.message);
                      } else {
                        notifier.showError('A server error occurred!');
                      }
                      // notifier.showError('FOR DEBUGGING PURPOSES ONLY - 500\n' + statusText + ' - ' + JSON.stringify(error));
                      return;
                    } else {
                      notifier.showError('An error occurred!');
                    }
                    /*if (error.error?.startsWith('IntegrityError')) { // startsWith causing issues
                        notifier.showError(statusText + ' - Check if this record already exists or contact system administrator.' );
                    }*/
                } else {
                    notifier.showError('FOR DEBUGGING PURPOSES ONLY - 500\n' + statusText + ' - ' + JSON.stringify(error));
                }
            } else {
              this.globalBehavioursService.updateIsServiceAvailable(false); // Server not running
            }

            //Backend returns unsuccessful response codes such as 404, 500 etc.
            console.error('Backend returned status code: ', error.status);
            console.error('Response body:', error.message);
        } else {
            notifier.showError('An error occurred!');
        }
    } else { // NEVER POP UP BROWSER ERRORS
            if (error.status >= 900) {
                console.error(error.message);
                // notifier.showError(error.message);
            } else {
                // notifier.showError('FOR DEBUGGING PURPOSES ONLY - ' + error.message);
                console.error(error.message);
            }

      }

      //alert('Navigating to error page now');
      //router.navigate(['/error']);
    }
}
