import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import {Color, Label} from 'ng2-charts';
import * as globals from '../../globals';
import {ApiService} from '../../admin/api.service';

interface PayrollCtcAndNetDto {
  periodDates: string;
  totalTax: number;
  totalCtc: number;
  totalNet: number;
}

interface Report {
  labels: [{
    labelName,
    labelValue
  }];
  entity: PayrollCtcAndNetDto[];
}

@Component({
  selector: 'app-recent-payrolls-chart',
  templateUrl: './recent-payrolls-chart.component.html',
  styleUrls: ['../x-chart.component.css']
  /*styleUrls: ['./recent-payrolls-chart.component.css']*/
})
export class RecentPayrollsChartComponent implements OnInit {

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales : {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };
  public barChartLabels: Label[] = []; // public barChartLabels: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  /*public barChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
  ];*/
  /*public barChartData: ChartDataSets[] = [
    { data: [], label: '' },
    { data: [], label: '' },
    { data: [], label: '' }
  ];*/
  public barChartData: ChartDataSets[] = [{}, {}, {}];


  public barChartColors: Color[] = [
    { borderColor: 'red' },
    { backgroundColor: 'blueviolet' },
    { backgroundColor: 'green' }
  ];

  constructor(public apiService: ApiService) { }

  ngOnInit() {
    this.apiService.get(globals.REPORTS_RECENT_PAYROLLS).subscribe(
      (report: Report) => {
        this.barChartData[0].label = report.labels.find(x => x.labelName === 'totalTax')?.labelValue;
        this.barChartData[1].label = report.labels.find(x => x.labelName === 'totalCtc')?.labelValue;
        this.barChartData[2].label = report.labels.find(x => x.labelName === 'totalNet')?.labelValue;
        for (const p of report.entity) {
          this.barChartData[0].data.push(p.totalTax);
          this.barChartData[1].data.push(p.totalCtc);
          this.barChartData[2].data.push(p.totalNet);
          this.barChartLabels.push(p.periodDates);
        }
        this.barChartData[0].type = 'line';
      }
    );
  }

  /*public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public barChartLabels: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A', type: 'line' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
  ];

  public barChartColors: Color[] = [
    { backgroundColor: 'blueviolet' },
    { backgroundColor: 'green' }
  ];

  constructor(public apiService: ApiService) { }

  ngOnInit() {
    this.apiService.getAll(globals.REPORTS_RECENT_PAYROLLS).subscribe(
      (periodsTaxes: []) => {

      }
    );
  }*/

}
