import { FormControl, Validators } from '@angular/forms';

// setup simple regex for white listed characters
const validCharacters = /[^a-zA-Z0-9 _()'àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ.-]/;
const positiveNumberOrDecimal = '^(\\d+\\.)?\\d+$';
const negativeNumberOrDecimal = '-(\\d+\\.)?\\d+$';
const numberOrDecimal = '^-?(\\d+\\.)?\\d+$';
const positiveIntegerOnly = /^[0-9]*$/;
const negativeIntegerOnly = /-[0-9]*$/;
const integerOnly = /^-?[0-9]*$/;

// create your class that extends the angular validator class
export class CustomValidators extends Validators {

  // create a static method for your validation
  static validateCharacters(control: FormControl) {

    // first check if the control has a value
    if (control.value && control.value.length > 0) {

      // match the control value against the regular expression
      const matches = control.value.match(validCharacters);

      // if there are matches return an object, else return null.
      return matches && matches.length ? { invalid_characters: matches } : null;
    } else {
      return null;
    }
  }

  /*
  1 = Full day (8 hours)
  Min = 0.125 (1hour)
  Max = 1
   */
  static validHoursToNumberForLeave(control: FormControl) {

    // first check if the control has a value
    if (control.value && control.value.length > 0) {
      if (control.value >= 0.125 && control.value <=1) {
        // Valid. Do nothing
      } else {
        return { invalidNumber: true };
      }
    } else {
      return null;
    }
  }

  /*
  1 = Full day (8 hours)
  Min = 0.125 (1hour)
  Max = 4 (24 hours of uninterrupted work)
   */
  static validHoursToNumberForOvertime(control: FormControl) {

    // first check if the control has a value
    if (control.value && control.value.length > 0) {
      if (control.value >= 0.125 && control.value <=4) {
        // Valid. Do nothing
      } else {
        return { invalidNumber: true };
      }
    } else {
      return null;
    }
  }

  static validPercentageValue(control: FormControl) {

    // first check if the control has a value
    if (control.value && control.value.length > 0) {
      if (control.value >= 0 && control.value <=100) {
        // Valid. Do nothing
      } else {
        return { invalidNumber: true };
      }
    } else {
      return null;
    }
  }

  static positiveNumberOrDecimal(control: FormControl) {

    if (control.value && control.value.length > 0) {

      if (!control.value.match(positiveNumberOrDecimal)) {
            return { positiveNumberOrDecimal: true };
        } else {
            return null;
        }
    } else {
        return null;
    }
  }

  static negativeNumberOrDecimal(control: FormControl) {

    if (control.value && control.value.length > 0) {

      if (!control.value.match(negativeNumberOrDecimal)) {
        return { negativeNumberOrDecimal: true };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  static numberOrDecimal(control: FormControl) {

    if (control.value && control.value.length > 0) {

      if (!control.value.match(numberOrDecimal)) {
        return { numberOrDecimal: true };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  static positiveIntegerOnly(control: FormControl) {

    if (control.value && control.value.length > 0) {

      if (!control.value.match(positiveIntegerOnly)) {
        return { positiveIntegerOnly: true };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  static negativeIntegerOnly(control: FormControl) {

    if (control.value && control.value.length > 0) {

      if (!control.value.match(negativeIntegerOnly)) {
        return { negativeIntegerOnly: true };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  static integerOnly(control: FormControl) {

    if (control.value && control.value.length > 0) {

      if (!control.value.match(integerOnly)) {
        return { integerOnly: true };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  static DateOfBirth(control: FormControl) {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!
    const yyyy = today.getFullYear();

    let day = String(dd);
    let month = String(mm);
    if (dd < 10) {
      day = '0' + dd;
    }
    if (mm < 10) {
      month = '0' + mm;
    }

    let todayStr = yyyy + '-' + month + '-' + day;

    const dob = new Date(control.value);
    const todayDate = new Date(todayStr);

    if (dob > todayDate) {
        return { incorrect_dob : true };
    } else {
        return null;
    }
  }

  static PayrollPeriodDate(control: FormControl) {
    const d = new Date(control.value);
    let dd = d.getDate();
    if (dd>1) {
      return { incorrect_payroll_period : true };
    } else {
      return null;
    }
  }

}
