import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalBehavioursService {

  private isServiceAvailable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isServiceAvailable$: Observable<boolean> = this.isServiceAvailable.asObservable();

  updateIsServiceAvailable(updatedIsServiceAvailable) {
    this.isServiceAvailable.next(updatedIsServiceAvailable);
  }
}
