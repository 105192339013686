import {Component, OnInit} from '@angular/core';
import * as globals from 'src/app/globals';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ApiService} from "../api.service";


@Component({
  selector: 'app-root',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent {

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient, private apiService: ApiService) { }

  /*test() {
    this.http.post<any>(`${this.baseUrl + '/test'}/`, null)
      .pipe(map((res) => {

      })).subscribe();

    //alert('Tested');
  }*/

  /*test() {
    this.apiService.getWithParams('/test', {amount: 988, children: 0}).subscribe();
  }*/

  test() {
    this.getPdf().subscribe((data) => {

      // @ts-ignore
      const blob = new Blob([data], {type: 'application/pdf'});

      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = "help.pdf";
      link.click();

    });
  }

  getPdf() {

    const httpOptions = {
      responseType: 'blob' as 'json'
  };

    return this.http.get(`http://localhost:8080/api/v1/test`, httpOptions);
  }

}
