import {Component, Injector, AfterViewInit, ViewChild, OnInit} from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Formula } from './formula';
import { NotificationService } from '../../services/notification.service';
import { ApiService } from 'src/app/admin/api.service';
import * as globals from 'src/app/globals';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {ConfirmationDialogService} from '../../services/confirmation-dialog/confirmation-dialog.service';
import {InfoPopupService} from '../../services/info-popup/info-popup.service';
import {UtilitiesService} from '../../services/utilities.service';
import {CustomValidators} from '../../services/custom_validators';
import {AuthService} from "../../services/auth.service";
import {FormService} from "../../services/form";
import {GlobalBehavioursService} from '../../services/global-behaviours.service';
import {FORMULA_BY_EMPLOYEE_TYPE_ENDPOINT} from "src/app/globals";

@Component({
  selector: 'app-formula',
  templateUrl: './formula.component.html'
})

export class FormulaComponent implements OnInit, AfterViewInit  {
  title = 'payroll-system';
  displayedColumns: string[] = ['name', 'description', 'formulaType', 'uniqueCode', 'formula', 'expression', 'employeeType', 'manage'];
  data: any;
  resultsLength = 0;
  isLoadingResults = true;
  isServiceAvailable = true;
  updateMode = false;
  rForm: FormGroup;
  requiredAlert = 'This field is required';
  formulaTypes = [];
  employeeTypes = [];
  selectedEmployeeType: number = undefined;
  pageSize = 50;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public formErrors = {
    name: '',
    description: '',
    formulaType: '',
    employeeType: '',
    uniqueCode: '',
    formula: '',
    expression: '',
    notMonetary: '',
  };

  constructor(public auth: AuthService,
              public injector: Injector,
              public apiService: ApiService,
              public fb: FormBuilder,
              public utilitiesService: UtilitiesService,
              public confirmationDialogService: ConfirmationDialogService,
              public infoPopupService: InfoPopupService,
              public globalBehavioursService: GlobalBehavioursService,
              public formService: FormService) {

    this.rForm = fb.group({
      id: [{value: null, disabled: this.missesRequiredRole()}],
      name: [{value: null, disabled: this.missesRequiredRole()}, Validators.required],
      description: [{value: null, disabled: this.missesRequiredRole()}],
      formulaType: [{value: null, disabled: this.missesRequiredRole()}, Validators.required],
      employeeType: [{value: null, disabled: this.missesRequiredRole()}, Validators.required],
      uniqueCode: [{value: null, disabled: this.missesRequiredRole()}, Validators.required],
      formula: [{value: null, disabled: this.missesRequiredRole()}, Validators.required],
      expression: [{value: null, disabled: this.missesRequiredRole()}, Validators.required],
      notMonetary: [{value: null, disabled: this.missesRequiredRole()}]
    });

    this.rForm.valueChanges.subscribe((data) => {
      this.formErrors = this.formService.validateForm(this.rForm, this.formErrors, true);
    });
  }

  isServiceAvailable$ = this.globalBehavioursService.isServiceAvailable$;
  notifier = this.injector.get(NotificationService);

  requiredRole = 'ROLE_RESTRICTED';
  missesRequiredRole() {
    return !this.hasRequiredRole();
  }
  hasRequiredRole() {
    return this.auth.hasRole(this.requiredRole);
  }

  createFormula(f: any) {
    const formula: Formula = {
      'name': f.name,
      'description': f.description,
      'formulaType': (f.formulaType) ? this.utilitiesService.generateQuickIdObject(f.formulaType) : null,//f.formulaType,
      'employeeType': (f.employeeType) ? this.utilitiesService.generateQuickIdObject(f.employeeType) : null,
      'uniqueCode': f.uniqueCode,
      'formula': f.formula,
      'expression': f.expression,
      'notMonetary': f.notMonetary,
      'id': f.id
    };

    this.apiService.saveOnly(globals.FORMULA_ENDPOINT, formula).subscribe(_ => {
      this.resetForm();
      this.ngAfterViewInit();
      this.updateMode = false;
    }, error => this.isServiceAvailable = false);
  }

  formEdit(id: number){
    this.apiService.getById(globals.FORMULA_ENDPOINT, id).subscribe(
      (formula: Formula) => {
        this.rForm.setValue({
          id: formula.id,
          name: formula.name,
          description: formula.description,
          formulaType: (formula.formulaType != null) ? formula.formulaType.id : null,//formula.formulaType,
          employeeType: (formula.employeeType != null) ? formula.employeeType.id : null,
          uniqueCode: formula.uniqueCode,
          formula: formula.formula,
          expression: formula.expression,
          notMonetary: formula.notMonetary,
        });
        this.updateMode = true;
        window.scroll(0, 0);
      });
  }

  updateFormula(f:any) {
    const formula: Formula = {
      'id': f.id,
      'name': f.name,
      'description': f.description,
      'formulaType': (f.formulaType) ? this.utilitiesService.generateQuickIdObject(f.formulaType) : null,//f.formulaType,
      'employeeType': (f.employeeType) ? this.utilitiesService.generateQuickIdObject(f.employeeType) : null,
      'uniqueCode': f.uniqueCode,
      'formula': f.formula,
      'expression': f.expression,
      'notMonetary': f.notMonetary
    };

    return this.apiService.updateOnly(globals.FORMULA_ENDPOINT, formula).subscribe(_ => {
      this.setCreateMode();
      this.ngAfterViewInit();
    }, error => this.isServiceAvailable = false);
  }

  ngOnInit() {
    this.getFormulaTypes();
    this.getEmployeeTypes();
  }

  getFormulaTypes() {
    this.apiService.getAll(globals.FORMULA_TYPE_ENDPOINT).subscribe(
      (res: []) => {
        this.formulaTypes = res;
      }
    );
  }

  getEmployeeTypes(): void {
    this.apiService.getAll(globals.EMPLOYEE_TYPE_ENDPOINT).subscribe(
      (res: []) => {
        this.employeeTypes = res;
      }
    );
  }

  public onEmployeeTypeChange(selectedType: number) {
    this.selectedEmployeeType = selectedType;
    this.ngAfterViewInit();
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // @ts-ignore
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          let endpoint = globals.FORMULA_ENDPOINT;
          if (this.selectedEmployeeType !== undefined) {
            endpoint = globals.FORMULA_BY_EMPLOYEE_TYPE_ENDPOINT + '/' + this.selectedEmployeeType;
          }
          return this.apiService.getAllPaginated(endpoint, { pageNo: this.paginator.pageIndex, pageSize: this.pageSize, sortBy: this.sort.active, sortOrder: this.sort.direction}
          ).pipe(catchError(error => {
              this.isServiceAvailable = false;
              return observableOf(null);
            })
          );
        }),
        map(data => {
          this.isLoadingResults = false;

          if (data === null) {
            return [];
          }

          // @ts-ignore
          this.resultsLength = data.totalElements;
          return data.content;
        })
      ).subscribe(data => this.data = data);
  }

  resetForm() {
    this.rForm.reset();
  }

  setCreateMode() {
    this.rForm.reset();
    this.updateMode = false;
  }

  showHelp() {
    const content = '<div>Keywords</div>\n' +
      '          <ul>\n' +
      '            <li>Methods</li>\n' +
      '            <ul>\n' +
      '              <li>get()</li>\n' +
      '              <ul>\n' +
      '                <li>unique codes</li>\n' +
      '                <li>days</li>\n' +
      '                <li>aleavecount</li>\n' +
      '                <li>uleavecount</li>\n' +
      '                <li>sleavecount</li>\n' +
      '                <li>mleavecount</li>\n' +
      '                <li>leavecount</li>\n' +
      '                <li>phcount</li>\n' +
      '              </ul>\n' +
      '              <li>find()</li>\n' +
      '              <ul>\n' +
      '                <li>paths (only employee paths supported)</li>\n' +
      '              </ul>\n' +
      '              <li>ohadaipr()</li>\n' +
      '            </ul>\n' +
      '          </ul>';
    this.infoPopupService.show('About formulas', content);
  }
}
