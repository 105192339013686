import {Component, Input, OnInit} from '@angular/core';
import {CurrencyService} from "../../services/currency.service";

@Component({
  selector: 'app-last-summary-mini',
  templateUrl: './last-summary-mini.component.html',
  styleUrls: ['../mini.scss']
})
export class LastSummaryMiniComponent implements OnInit {
  @Input() lastPeriodSummary: {
    labelValue: string;
    recordId: string;
  };
  constructor(public currencyService: CurrencyService) { }

  ngOnInit(): void {
    this.currencyService.setDefaultCurrency().then(r => { // Wrapped inside promise to ensure currencies are set first.
    }).catch(function(e) {
      console.log('Error setting currencies! Please contact system administrator');
    });
  }

}
