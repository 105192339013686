import { Component, OnInit, Injector, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Company } from './company';
import { CustomValidators } from '../../services/custom_validators';
import { FormService } from '../../services/form';
import { DataService } from '../data.service';
import { ApiService } from 'src/app/admin/api.service';
import { NotificationService } from '../../services/notification.service';
import { Country } from '../countries/country'; // For dropdown
import { Observable } from 'rxjs'; //////////////////////////////////////////////////
import { filter, startWith, map, switchMap } from 'rxjs/operators'; /////////////////////////////////
import { Subscription } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete'; // .................................
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table'; // pagination
// import { CompanyType } from '../companyType/companyType';
import { UtilitiesService } from '../../services/utilities.service';
import * as globals from 'src/app/globals';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog/confirmation-dialog.service';
import {HttpClient} from '@angular/common/http';
import {GlobalBehavioursService} from '../../services/global-behaviours.service';

interface Logo {
  picByte?: any;
  company: Company;
  hasImage?: boolean; // FOR DOWNLOAD
  file?: any; // FOR UPLOAD
}

@Component({
  selector: '',
  templateUrl: './company.component.html'
})

export class CompanyComponent implements OnInit {

  constructor(private injector: Injector,
              private dataService: DataService,
              private apiService: ApiService,
              private utilitiesService: UtilitiesService,
              private fb: FormBuilder,
              private confirmationDialogService: ConfirmationDialogService,
              public formService: FormService,
              public globalBehavioursService: GlobalBehavioursService,
              private httpClient: HttpClient) {

    this.rForm = fb.group({
      name: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(50), CustomValidators.validateCharacters]],
      registrationNumber: [null, [Validators.required]],
      workingDaysPerWeek: [null],
      companyType: [null, [Validators.required]],
      line1: [null, [Validators.required]],
      line2: [null],
      postalCode: [null],
      country: [null, [Validators.required]]
    });

    this.logoForm = fb.group({
      file: [null, [Validators.required]]
    });

    this.rForm.valueChanges.subscribe((data) => {
      this.formErrors = this.formService.validateForm(this.rForm, this.formErrors, true);
    });
  }

  get thecountry() {
    return this.rForm.get('country');
  }
  title = 'payroll-system';
  companies: MatTableDataSource<Company>;
  // countries: Country[]; // For dropdown
  countries: Observable<Country[]>;
  filteredCountries: Observable<Country[]>;

  // company type
  companyTypes: [];
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// filteredCompanyTypes: Observable<CompanyType[]>;

  displayedColumns: string[] = ['name', 'registrationNumber', 'companyType', 'workingDaysPerWeek', 'manageColumn'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  company = new Company();

  rForm: FormGroup;
  logoForm: FormGroup;
  showList = false;
  updateMode = false;
  name = '';
  registrationNumber = '';
  workingDaysPerWeek = '';
  workingDaysPerWeekVisible = false;
  companyType = 0;
  line1 = '';
  line2 = '';
  postalCode = '';
  country = 0;

  public formErrors = {
    name: '',
    registrationNumber: '',
    workingDaysPerWeek: '',
    companyType: '',
    line1: '',
    line2: '',
    postalCode: '',
    country: ''
  };

  @ViewChildren(MatAutocompleteTrigger) triggerCollection: QueryList<MatAutocompleteTrigger>;

  subscription: Subscription;
  isServiceAvailable$ = this.globalBehavioursService.isServiceAvailable$;
  notifier = this.injector.get(NotificationService);

  // LOGO
  selectedFile: File;
  retrievedImage: any;
  base64Data: any;
  retrievedResponse: Logo;
  imageName: any;

  ngOnInit() {
    this.getCountries();
    this.filteredCountries = this.thecountry.valueChanges
      .pipe(
        startWith(''),
        switchMap(value => this.filterCountries(value))
      );
    if (this.showList) {
      this.getCompanies();
    }
    // country type
    this.getCompanyTypes();
    if (this.showList) {
      this.getCountries();
    }
  }
  getCompanyTypes(): void {
    this.apiService.getAll(globals.COMPANY_TYPE_ENDPOINT).subscribe(
      (res: []) => {
        this.companyTypes = res;
      }
    );
  }

  showHideList($isChecked): void {
    if ($isChecked) {
      if (!this.companies) {
        // Above Condition added to make the list available on demand. can't retrieve list if not defined (or it throws an error)
        this.getCompanies();
      }
      this.showList = true;
    } else {
      this.showList = false;
    }
  }

  applyFilter(filterValue: string) {
    this.companies.filter = filterValue.trim().toLowerCase();

    if (this.companies.paginator) {
      this.companies.paginator.firstPage();
    }
  }

  getCountries(): void {
    // this.countries = this.dataService.getAllCountries();
    this.countries = this.apiService.getAll(globals.COUNTRY_ENDPOINT);
  }

  getCompanies(): void {
    this.apiService.getAll(globals.COMPANY_ENDPOINT).subscribe(
      (res: Company[]) => {
        this.companies = new MatTableDataSource(res);
        this.companies.paginator = this.paginator;
        this.companies.sort = this.sort;
      }
    );
  }

  getCompany(id): void {
    this.apiService.getById(globals.COMPANY_ENDPOINT, id).subscribe(
      (res: Company) => {
        this.company = res;
      }
    );
  }

  addCompany(f) {
    const company = new Company();
    company.name = f.name;
    company.registrationNumber = f.registrationNumber;
    company.workingDaysPerWeek = f.workingDaysPerWeek;
    const address = {};
    address['line1'] = f.line1;
    address['line2'] = f.line2;
    address['postalCode'] = f.postalCode;
    address['country'] = this.utilitiesService.generateQuickIdObject(f.country);
    company.address = address;
    if (f.companyType) {
      company.companyType = {};
      company.companyType.id = this.rForm.get('companyType').value;
      company.companyType.value = this.companyTypes.filter(v => v['id'] == this.rForm.get('companyType').value)[0]['value'];
    }

    this.apiService.save(globals.COMPANY_ENDPOINT, company, (this.companies) ? this.companies.data : null)
      .subscribe(
        (res: Company[]) => {
          // Update the list of companies
          // Above Condition added to make the list available on demand. service will only populate list if requested.
          if (this.showList) {
            // Refresh the entire list because the update only returns the ID of the FK and not the entire FK object
            // for that reason we cannot replace the changed object with the FK object since we only have a reference ID.
            this.companies.data = res;
          }
          // Inform the user
          this.notifier.showSaved();

          // Reset the form
          this.rForm.reset();
        }
      );
  }

  companyEdit(id) {
    this.apiService.getById(globals.COMPANY_ENDPOINT, id).subscribe(
      (res: Company) => {
        this.company = res;
        this.rForm.patchValue({
          name: this.company.name,
          registrationNumber: this.company.registrationNumber,
          companyType: (this.company.companyType != null) ? this.company.companyType.id : null,
          line1: (this.company.address != null) ? this.company.address.line1 : null,
          line2: (this.company.address != null) ? this.company.address.line2 : null,
          postalCode: (this.company.address != null) ? this.company.address.postalCode : null,
          country: (this.company.address != null) ? this.company.address.country : null,
        });
        if (this.company.companyType?.id == 1) {
          this.workingDaysPerWeekVisible = true;
          this.rForm.controls.workingDaysPerWeek.reset({value: this.company.workingDaysPerWeek, disabled: false});
        } else {
          this.rForm.controls.workingDaysPerWeek.reset({value: this.company.workingDaysPerWeek, disabled: true});
          this.workingDaysPerWeekVisible = false;
        }
        this.logoForm.reset();
        this.getImage();
      }
    );
    this.updateMode = true;
    window.scroll(0, 0);
  }

  updateCompany(f) {
    const updatedCompany = new Company();
    updatedCompany.id = this.company.id;
    updatedCompany.name = f.name;
    updatedCompany.registrationNumber = f.registrationNumber;
    updatedCompany.workingDaysPerWeek = f.workingDaysPerWeek;
    // updatedCompany.companyType = (f.companyType) ? this.utilitiesService.generateQuickIdObject(f.companyType) : null;
    if (f.companyType) {
      updatedCompany.companyType = {};
      updatedCompany.companyType.id = this.rForm.get('companyType').value;
      updatedCompany.companyType.value = this.companyTypes.filter(v => v['id'] == this.rForm.get('companyType').value)[0]['value'];
    }

    /*if (f.address) {
      updatedCompany.address = {};
      updatedCompany.address['line1'] = f.line1;
      updatedCompany.address['line2'] = f.line2;
      updatedCompany.address['postalCode'] = f.postalCode;
      updatedCompany.address['country'] = this.utilitiesService.generateQuickIdObject(f.country); //.id???
    }*/

    const address = {};
    address['line1'] = f.line1;
    address['line2'] = f.line2;
    address['postalCode'] = f.postalCode;
    address['country'] = this.utilitiesService.generateQuickIdObject(f.country);
    if (!this.utilitiesService.allPropertiesNull(address)) {
      if ((address['line1']) && (address['country'])) {
        if (this.company.address) { address['id'] = this.company.address.id; }
        updatedCompany.address = address;
      } else {
        alert('If the address is supplied, please make sure it is valid or remove it entirely.'); // KEEP THIS ALERT - IT IS INTENDED
        return;
      }
    } else {
      updatedCompany.address = null; // Do not discard but set it to null
    }

    this.apiService.update(globals.COMPANY_ENDPOINT, updatedCompany, this.companies.data)
      .subscribe(
        (res) => {
          if (this.showList) {
            this.companies.data = res;
          }
          this.company = new Company();
          this.notifier.showSaved();
          this.updateMode = false;
          this.rForm.reset();
        }
      );
  }

  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm...', 'Are you sure you want to delete?')
      .then((confirmed) => {
        if (confirmed) { this.deleteCompany(id); }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  deleteCompany(id) {
    this.apiService.delete(globals.COMPANY_ENDPOINT, id, this.companies.data)
      .subscribe(
        (res: Company[]) => {
          if (this.showList) {
            this.companies.data = res;
          }
          this.notifier.showDeleted();
          this.updateMode = false;
          this.rForm.reset();
        }
      );
  }

  ngAfterViewInit() {
    this._subscribeToClosingActions();
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  private _subscribeToClosingActions(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.triggerCollection.toArray()[0].panelClosingActions
      .subscribe(e => {
          if (!e || !e.source) {
            if (!this.utilitiesService.isObject(this.rForm.getRawValue().country)) {
              this.rForm.controls.country.setValue(null);
            }
          }
        },
        err => this._subscribeToClosingActions(),
        () => this._subscribeToClosingActions());

    /*this.subscription = this.triggerCollection.toArray()[1].panelClosingActions
      .subscribe(e => {
          if (!e || !e.source) {
            if (!this.utilitiesService.isObject(this.rForm.getRawValue().hod))
              this.rForm.controls.hod.setValue(null);
          }
        },
        err => this._subscribeToClosingActions(),
        () => this._subscribeToClosingActions());*/
  }

  private filterCountries(value: string | Country) {
    let filterValue = '';
    if (value) {
      filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
      return this.countries.pipe(
        map(countries => countries.filter(country => country.name.toLowerCase().includes(filterValue)))
      );
    } else {
      return this.countries;
    }
  }
  displayCountryFn(country?: Country): string | undefined {
    return country ? country.name : undefined;
  }

  public onFileChanged(event) {
    this.selectedFile = event.target.files[0];
  }

  onUpload() {
    console.log(this.selectedFile);
    const uploadImageData = new FormData();
    uploadImageData.append('imageFile', this.selectedFile, this.selectedFile.name);
    uploadImageData.append('companyId', this.company.id);

    this.apiService.postWithParams(globals.LOGO_ENDPOINT, uploadImageData)
      .subscribe((res: Logo) => {
          if (res) {
            this.retrievedResponse = res;
            if (this.retrievedResponse.hasImage) {
              this.base64Data = this.retrievedResponse.picByte;
              this.retrievedImage = 'data:image/jpeg;base64,' + this.base64Data;
            }
          } else {
            console.log('Image not uploaded successfully');
          }
        this.logoForm.reset();
        }
      );
  }

  getImage() {
    this.apiService.getWithParams(globals.LOGO_ENDPOINT, {companyId: this.company.id, getParentLogoIfNull: false})
      .subscribe(
        (res: Logo) => {
          this.retrievedResponse = res;
          if (this.retrievedResponse.hasImage) {
            this.base64Data = this.retrievedResponse.picByte;
            this.retrievedImage = 'data:image/jpeg;base64,' + this.base64Data;
          }
        }
      );
  }

  companyTypeChange(companyTypeId: number) {
    if (+companyTypeId === 1) {
      this.rForm.controls.workingDaysPerWeek.reset({value: null, disabled: false});
      this.workingDaysPerWeekVisible = true;
    } else {
      this.rForm.controls.workingDaysPerWeek.reset({value: null, disabled: true});
      this.workingDaysPerWeekVisible = false;
    }
  }

  setCreateMode() {
    this.rForm.reset();
    this.logoForm.reset();
    this.updateMode = false;
  }
}
