export class Country {
    name?: any;
    code?: any;
    zip?: any;
    currency?: any;
    currencyCode?: any;
    symbol?: any;
    id?: any;

    constructor(
      name?: string,
      code?: string,
      zip?: string,
      currency?: string,
      currencyCode?: string,
      symbol?: string,
      id?: number) {}
  }
