import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { UtilitiesService } from './services/utilities.service';
import { AuthService } from './services/auth.service';
import * as globals from "./globals";
import {Company} from "./admin/company/company";
import {ApiService} from "./admin/api.service";

interface Logo {
  picByte?: any;
  company: Company;
  hasImage?: boolean; //FOR DOWNLOAD
  file?: any; //FOR UPLOAD
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'payroll-system';

  error = '';
  success = '';

  constructor(public auth: AuthService,
              private apiService: ApiService,
              private utilitiesService: UtilitiesService,
              private router: Router) {
  }

  ngOnInit() {
    this.getCompanyLogo();
    if (this.auth.getJwtToken()) this.utilitiesService.setCurrencySymbol();
  }


  hasRole(_role) {
    return this.auth.hasRole(_role);
  }

  logout() {
    this.auth.logout();
  }

  selectedFile: File;
  retrievedLogo: any;
  base64Data: any;
  retrievedLogoResponse: Logo;
  imageName: any;
  sizeToUse = 'width';

  getCompanyLogo() {
    const self = this;
    this.apiService.get(globals.COMPANY_ENDPOINT + '/parent')
      .subscribe((company: Company) => {
        if (company) {
          this.apiService.getWithParams(globals.LOGO_ENDPOINT, {
            companyId: company.id/*this.company.id*/,
            getParentLogoIfNull: true
          })
            .subscribe(
              (res: Logo) => {
                this.retrievedLogoResponse = res;
                if (this.retrievedLogoResponse.hasImage) {
                  this.base64Data = this.retrievedLogoResponse.picByte;
                  this.retrievedLogo = 'data:image/jpeg;base64,' + this.base64Data;

                  const img = new Image();
                  img.src = this.retrievedLogo; // myBase64;
                  img.addEventListener('load', function () {
                    if (img.width > img.height) {
                      self.sizeToUse = 'width';
                    } else if (img.height > img.width) {
                      self.sizeToUse = 'height';
                    }
                  });
                }
              }
            );
        }
      });
  }

}
