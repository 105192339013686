import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { LoginComponent } from './user/login.component';
import { PayGradeComponent } from './admin/pay-grade/pay-grade.component';
import { CostcentreComponent } from './admin/costcentre/costcentre.component';
import { DepartmentComponent } from './admin/department/department.component';
import { PositionComponent } from './admin/position/position.component';
import { EmployeeListComponent } from './admin/employee/employee-list.component'; // Not sure about this one!
import { EmployeeDetailsComponent } from './admin/employee/employee-details.component'; // New
import { ManageEmployeeComponent } from './admin/employee/manage-employee.component';
import { FormulaComponent } from './admin/formula/formula.component';
import { PayrollPeriodComponent } from './admin/payrollPeriod/payrollPeriod.component';
import { AbsenceComponent } from './admin/Absence/absence.component';
import { OvertimeComponent} from './admin/overtime/overtime.component';
import { TaxYearComponent } from './admin/taxyear/tax-year.component';
import { TaxTableComponent } from './admin/taxtable/taxtable.component';
import { CalendarComponent } from './admin/calendar/calendar.component';
import { PayrollComponent } from './admin/payroll/payroll.component';
import { PPDetailsComponent } from './admin/payrollPeriod/ppdetails.component';
import { ViewPayslipComponent } from './admin/payslip/view-payslip.component';
import { CountryComponent } from './admin/countries/country.component';
import { CompanyComponent } from './admin/company/company.component';
import { UserListComponent } from './user/user-list.component';
import { ManageUserComponent } from  './user/manage-user.component';
import { ConfigComponent } from './admin/config/config.component';
import { TestComponent } from './admin/test/test.component';
import { EmployeePeriodsComponent } from './employee/period/employeePeriods.component';
import { ChartsModule } from 'ng2-charts';
import { PayslipComponent } from './employee/payslip/payslip.component';
import { PersonalDetailsComponent } from './employee/details/personal-details.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GlobalErrorComponent } from './services/global-error.component';
import { PageNotFoundComponent } from './services/page-not-found.component';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { CustomValidators } from './services/custom_validators';
import { FormService } from './services/form';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar'; // new
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './services/TokenInterceptor';
import { AuthInterceptor } from './services/auth-request';
import { GlobalErrorHandlerService } from './services/global-error-handler.service';
import { AngularmaterialModule } from './material/angularmaterial/angularmaterial.module';
import { PositionService } from './admin/position/position.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { AuthService } from './services/auth.service';
import { SettingsComponent } from './user/settings.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PasswordModalComponent } from './user/password-modal.component';
import { ConfirmationDialogComponent } from './services/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './services/confirmation-dialog/confirmation-dialog.service';
import { InfoPopupComponent } from './services/info-popup/info-popup.component';
import { InfoPopupService } from './services/info-popup/info-popup.service';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { DashComponent} from './dash/dash.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LayoutModule } from '@angular/cdk/layout';
import { CardComponent } from './card/card.component';
import { EmployeeCompositionChartComponent } from './charts/employee-composition-chart/employee-composition-chart.component';
import { TaxesByPeriodChartComponent } from './charts/taxes-by-period-chart/taxes-by-period-chart.component';
import { RecentPayrollsChartComponent } from './charts/recent-payrolls-chart/recent-payrolls-chart.component';
import { ExpensesByCostcentreChartComponent } from './charts/expenses-by-costcentre-chart/expenses-by-costcentre-chart.component';
import { MiniComponent } from './card/mini/mini.component';
import { LastSummaryMiniComponent } from './card/last-summary-mini/last-summary-mini.component';
import { OpenPeriodsMiniComponent } from './card/open-periods-mini/open-periods-mini.component';
import { ProcessedPayrollCountMiniComponent } from './card/processed-payroll-count-mini/processed-payroll-count-mini.component';
import { DashTableComponent } from './card/table/dash-table.component';
import { ExtraComponent } from './restricted/extra/extra.component';
import { ErrorBannerComponent } from './directive/error-banner/error-banner.component';
import { TaxRebateComponent } from './admin/taxrebate/tax-rebate.component';
import { TaxThresholdComponent } from './admin/taxthreshold/tax-threshold.component';
import { SalaryComponent } from './admin/salary/salary.component';
import { BonusComponent } from './admin/bonus/bonus.component';


const schemas: any[] = [];
schemas.push(CUSTOM_ELEMENTS_SCHEMA);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PayGradeComponent,
    CostcentreComponent,
    DepartmentComponent,
    PositionComponent,
    DashboardComponent,
    EmployeeDetailsComponent,
    EmployeeListComponent,
    ManageEmployeeComponent,
    TestComponent,
    FormulaComponent,
    PayrollPeriodComponent,
    AbsenceComponent,
    OvertimeComponent,
    TaxYearComponent,
    TaxTableComponent,
    CalendarComponent,
    PayrollComponent,
    PPDetailsComponent,
    PayslipComponent,
    ViewPayslipComponent,
    PersonalDetailsComponent,
    UserListComponent,
    ManageUserComponent,
    CountryComponent,
    CompanyComponent,
    GlobalErrorComponent,
    SettingsComponent,
    PasswordModalComponent,
    PageNotFoundComponent,
    ConfirmationDialogComponent,
    InfoPopupComponent,
    ConfigComponent,
    EmployeePeriodsComponent,
    DashComponent,
    CardComponent,
    EmployeeCompositionChartComponent,
    TaxesByPeriodChartComponent,
    RecentPayrollsChartComponent,
    ExpensesByCostcentreChartComponent,
    MiniComponent,
    LastSummaryMiniComponent,
    OpenPeriodsMiniComponent,
    ProcessedPayrollCountMiniComponent,
    DashTableComponent,
    ExtraComponent,
    ErrorBannerComponent,
    TaxRebateComponent,
    TaxThresholdComponent,
    SalaryComponent,
    BonusComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    AngularmaterialModule, // pagination
    MatAutocompleteModule,
    MatInputModule,
    AppRoutingModule,
    NgbModule,
    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule,
    ChartsModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule
  ],
  providers: [
    AuthService,
    CustomValidators,
    FormService,
    PositionService, // pagination
    ConfirmationDialogService,
    InfoPopupService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    /*{
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },*/
    GlobalErrorHandlerService,
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    PasswordModalComponent,
    ConfirmationDialogComponent,
    InfoPopupComponent
  ],
  schemas: schemas
})
export class AppModule { }
