export class Department {
  name: any;
  costcentre: any;
  hod: any;
  company: any;
  id: any;

  constructor(
    name?: string,
    costcentre?: number,
    hod?: string,
    company?: string,
    id?: number) {}
}
