import {Component, Injector, AfterViewInit, ViewChild} from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import { NotificationService } from '../../services/notification.service';
import { Calendar} from './calendar';
import { ApiService } from 'src/app/admin/api.service';
import * as globals from 'src/app/globals';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {ConfirmationDialogService} from '../../services/confirmation-dialog/confirmation-dialog.service';
import {UtilitiesService} from '../../services/utilities.service';
import {FormService} from "../../services/form";
import {CustomValidators} from "../../services/custom_validators";
import {GlobalBehavioursService} from '../../services/global-behaviours.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html'
})

export class CalendarComponent implements AfterViewInit  {
  title = 'payroll-system';
  displayedColumns: string[] = ['description', 'date', 'manage'];
  data: any;
  resultsLength = 0;
  isLoadingResults = true;
  isServiceAvailable = true;
  updateMode = false;
  rForm: FormGroup;
  requiredAlert = 'This field is required';
  calendarItems = [];
  pageSize = 20;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public formErrors = {
    description: '',
    date: ''
  };

  constructor(public injector: Injector,
              public apiService: ApiService,
              public fb: FormBuilder,
              public utilitiesService: UtilitiesService,
              public confirmationDialogService: ConfirmationDialogService,
              public globalBehavioursService: GlobalBehavioursService,
              public formService: FormService) {

    this.rForm = fb.group({
      id: [null],
      description: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
      date: [null, Validators.required]
    });

    this.rForm.valueChanges.subscribe((data) => {
      this.formErrors = this.formService.validateForm(this.rForm, this.formErrors, true);
    });
  }

  isServiceAvailable$ = this.globalBehavioursService.isServiceAvailable$;
  notifier = this.injector.get(NotificationService);

  createCalendarItem(f: any) {
    const calendarItem: Calendar = {
      'description': f.description,
      'date': f.date,
      'id': f.id
    };

    this.apiService.saveOnly(globals.CALENDAR_ENDPOINT, calendarItem).subscribe(_ => {
      this.notifier.showSaved();
      this.resetForm();
      this.ngAfterViewInit();
      this.updateMode = false;
    }, error => this.isServiceAvailable = false);
  }

  formEdit(id: number) {
    this.apiService.getById(globals.CALENDAR_ENDPOINT, id).subscribe(
      (calendarItem: Calendar) => {
        this.rForm.controls.id.setValue(calendarItem.id);
        this.rForm.controls.description.setValue(calendarItem.description);
        this.rForm.controls.date.setValue(calendarItem.date);
        this.updateMode = true;
        window.scroll(0, 0);
      });
  }

  updateCalendarItem(f: any) {
    const calendarItem: Calendar = {
      'id': f.id,
      'description': f.description,
      'date': f.date
    };

    this.confirmationDialogService.confirm('Please confirm...', 'This may affect open periods and you may need to rerun payroll. \nDo you want to proceed?')
      .then((confirmed) => {
        if (confirmed) {
          return this.apiService.updateOnly(globals.CALENDAR_ENDPOINT, calendarItem).subscribe(_ => {
            this.notifier.showSaved();
            this.setCreateMode();
            this.ngAfterViewInit();
          }, error => this.isServiceAvailable = false);
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  ngAfterViewInit() {

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // @ts-ignore
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.apiService.getAllPaginated(globals.CALENDAR_ENDPOINT, {pageNo: this.paginator.pageIndex, pageSize: this.pageSize, sortBy: this.sort.active, sortOrder: this.sort.direction}
          ).pipe(catchError(error => {
              this.isServiceAvailable = false;
              return observableOf(null);
            })
          );
        }),
        map(data => {
          this.isLoadingResults = false;

          if (data === null) {
            return [];
          }

          // @ts-ignore
          this.resultsLength = data.totalElements;
          return data.content;
        })
      ).subscribe(data => this.data = data);
  }

  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm...', 'This may affect open periods and you may need to rerun payroll. \nAre you sure you want to delete?')
      .then((confirmed) => {
        if (confirmed) this.deleteCalendar(id);
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  private deleteCalendar(id) {
    this.apiService.deleteOnly(globals.CALENDAR_ENDPOINT, id)
      .subscribe(
        (res: boolean) => {
          this.notifier.showDeleted();
          this.ngAfterViewInit(); // Amend to only do this if above returns 200
        }/*,
          (err) => this.error = err*/
      );
  }

  resetForm() {
    this.rForm.reset();
  }

  setCreateMode() {
    this.rForm.reset();
    this.updateMode = false;
  }
}
