import {AfterViewInit, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {Salary} from './salary';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../api.service';
import {UtilitiesService} from '../../services/utilities.service';
import * as globals from '../../globals';
import {Employee} from '../employee/employee';
import {NotificationService} from '../../services/notification.service';
import {GlobalBehavioursService} from '../../services/global-behaviours.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormService} from '../../services/form';
import {CustomValidators} from '../../services/custom_validators';
import {PayrollPeriod} from '../payrollPeriod/payrollPeriod';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {ConfirmationDialogService} from '../../services/confirmation-dialog/confirmation-dialog.service';
import {CurrencyService} from "../../services/currency.service";

@Component({
  selector: 'app-salary',
  templateUrl: './salary.component.html',
  styleUrls: ['./salary.component.css']
})
export class SalaryComponent implements OnInit , AfterViewInit  {

  employee: Employee;
  salaries: Salary[];
  payrollPeriods: PayrollPeriod[];
  lastOpenPeriodId: number;
  rForm: FormGroup;
  displayedColumns: string[] = ['period', 'beforeSalary', 'increasePercentage', 'increaseAmount', 'afterSalary', 'manage'];
  data: any;
  isLoadingResults = true;
  isServiceAvailable = true;
  pageSize = 20;
  resultsLength = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public formErrors = {
    method: '',
    period: '',
    beforeSalary: '',
    afterSalary: '',
    increasePercentage: '',
    increaseAmount: '',
    comment: ''
  };

  constructor(
    private injector: Injector,
    private route: ActivatedRoute,
    public fb: FormBuilder,
    public formService: FormService,
    private apiService: ApiService,
    public utilitiesService: UtilitiesService,
    public currencyService: CurrencyService,
    public confirmationDialogService: ConfirmationDialogService,
    public globalBehavioursService: GlobalBehavioursService,
  ) {
    this.rForm = fb.group({
      method: [null, Validators.required],
      id: [null],
      period: [null, Validators.required],
      beforeSalary: [null, CustomValidators.positiveNumberOrDecimal],
      afterSalary: [null, [Validators.required, CustomValidators.positiveNumberOrDecimal]],
      increasePercentage: [null, [Validators.required, CustomValidators.positiveNumberOrDecimal]],
      increaseAmount: [null, [Validators.required, CustomValidators.positiveNumberOrDecimal]],
      comment: [null],
      usePercentage: [null]
    });

    this.rForm.valueChanges.subscribe((data) => {
      this.formErrors = this.formService.validateForm(this.rForm, this.formErrors, true);
    });
  }

  isServiceAvailable$ = this.globalBehavioursService.isServiceAvailable$;
  notifier = this.injector.get(NotificationService);

  ngOnInit(): void {
    this.currencyService.setDefaultCurrency().then(r => { // Wrapped inside promise to ensure currencies are set first.
      this.setEmployee().then(x => {
        return this.getSalaries();
      }).then(salaries => {
        return this.getOpenPeriods();
      }).then(period => {
        this.setLastOpenPeriod().then(lop => {
          // Logic
          this.rForm.controls.beforeSalary.reset({value: this.employee.basicPay, disabled: true});
          this.rForm.controls.afterSalary.reset({value: null, disabled: true});
        }).catch(err => {
          console.error(err);
        });
      }).catch(err => {
        console.error(err);
      });
    }).catch(function(e) {
      console.log('Error setting currencies! Please contact system administrator');
    });
  }

  onPercentageInput(event: Event): void {
    if (this.employee.basicPay === 0) {
      alert('This method is not suited when the current salary is zero.');
      return;
    }
    const inputElement = event.target as HTMLInputElement;
    const percentage: number = Number(inputElement.value);
    const newSalary = this.utilitiesService.calculateIncreasedNumber(this.employee.basicPay, percentage);
    const increase = newSalary - this.employee.basicPay;
    this.rForm.patchValue({
      increaseAmount: increase,
      afterSalary: newSalary
    });
  }
  onIncreaseAmountInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const increaseAmount: number = Number(inputElement.value);
    const newSalary = this.employee.basicPay + increaseAmount;
    const percentage = this.utilitiesService.calculatePercentage(this.employee.basicPay, newSalary);
    this.rForm.patchValue({
      increasePercentage: percentage,
      afterSalary: newSalary
    });
  }

  public methodChange(selectedMethod: string) {
    this.rForm.controls.afterSalary.reset({value: null, disabled: true});
    if (selectedMethod === 'percentage') {
      this.rForm.controls.increasePercentage.reset({value: null, disabled: false});
      this.rForm.controls.increaseAmount.reset({value: null, disabled: true});
    } else if (selectedMethod === 'amount') {
      this.rForm.controls.increaseAmount.reset({value: null, disabled: false});
      this.rForm.controls.increasePercentage.reset({value: null, disabled: true});
    }
  }

  ngAfterViewInit() {

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // @ts-ignore
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.apiService.getAllByIdPaginated(globals.SALARY_ENDPOINT + '/employee', this.employee.id, {pageNo: this.paginator.pageIndex, pageSize: this.pageSize, sortBy: this.sort.active, sortOrder: this.sort.direction}
          ).pipe(catchError(error => {
              this.isServiceAvailable = false;
              return observableOf(null);
            })
          );
        }),
        map(data => {
          this.isLoadingResults = false;

          if (data === null) {
            return [];
          }

          // @ts-ignore
          this.resultsLength = data.totalElements;
          return data.content;
        })
      ).subscribe(data => {
      this.data = data;
      this.resetForm();
    });
  }

  getSalaries() {
    const self = this;
    return new Promise((resolve, reject) => {
      self.apiService.getById(globals.SALARY_ENDPOINT + '/employee', self.employee.id).toPromise().then(salaries => {
        self.salaries = salaries;
        resolve(salaries);
      }).catch(err => {
        reject(err);
      });
    });
  }

  setEmployee() {
    const self = this;
    let employeeId;
    return new Promise((resolve, reject) => {
      const encryptedEmpId = self.route.snapshot.paramMap.get('id');
      employeeId = +self.utilitiesService.Decrypt(encryptedEmpId);
      this.apiService.getById(globals.EMPLOYEE_ENDPOINT, employeeId).toPromise().then(employee => {
        if (employee) {
          self.employee = employee;
          resolve(employee);
        } else {
          reject('Employee not found');
        }
      }).catch(err => {
        reject(err);
      });
    });
  }

  getOpenPeriods() {
    const self = this;
    return new Promise((resolve, reject) => {
      self.apiService.getAll(globals.PAYROLL_PERIOD_ENDPOINT + '/open').toPromise().then(periods => {
        self.payrollPeriods = periods['content'];
        resolve(periods);
      }).catch(err => {
        reject(err);
      });
    });
  }

  setLastOpenPeriod() {
    const self = this;
    return new Promise((resolve, reject) => {
      self.apiService.getById(globals.PAYROLL_PERIOD_ENDPOINT + '/last-open-by-employee-type', self.employee.employeeType.id).toPromise().then(period => {
        if (period) {
          if (period != null) {
            self.lastOpenPeriodId = period.id;
          }
        }
        resolve(period);
      }).catch(err => {
        reject(err);
      });
    });
  }

  saveSalary(f: any) {
    const salary: Salary = {
      'employee' : this.employee,
      'period' : (f.period) ? this.utilitiesService.generateQuickIdObject(f.period) : null,
      'beforeSalary': f.beforeSalary,
      'afterSalary': f.afterSalary,
      'increasePercentage': f.increasePercentage,
      'increaseAmount': f.increaseAmount,
      'usePercentage': (f.method === 'percentage') ? true : false,
      'comment': f.comment
      /*'id': f.id*/ // Not needed since we are not updating
    };

    this.apiService.saveOnly(globals.SALARY_ENDPOINT, salary).subscribe(_ => {
      // this.resetForm(); // already called in ngAfterViewInit()
      this.ngAfterViewInit();
      this.notifier.showSaved();
    }, error => this.isServiceAvailable = false);
  }

  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm...', 'This will revert to the previous salary.')
      .then((confirmed) => {
        if (confirmed) { this.deleteEntry(id); }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  private deleteEntry(id) {
    this.apiService.deleteOnly(globals.SALARY_ENDPOINT, id)
      .subscribe(
        (res: boolean) => {
          this.notifier.showSuccess('Salary successfully reverted.');
          this.ngAfterViewInit(); // Amend to only do this if above returns 200
        }/*,
          (err) => this.error = err*/
      );
  }

  resetForm() {
    this.rForm.reset();
    this.ngOnInit();
  }
}
