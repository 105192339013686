import {AfterViewInit, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ApiService} from '../../admin/api.service';
import {UtilitiesService} from '../../services/utilities.service';
import {ConfirmationDialogService} from '../../services/confirmation-dialog/confirmation-dialog.service';
import {FormService} from '../../services/form';
import {NotificationService} from '../../services/notification.service';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import { PaginatedResponse } from '../../interfaces/paginated-response';
import {ExtraFieldType} from '../../interfaces/extra-field-type';
import { Extra} from './extra';
import * as globals from '../../globals';
import {TaxYear} from '../../admin/taxyear/taxyear';
import {merge, of as observableOf} from 'rxjs';
import {GlobalBehavioursService} from '../../services/global-behaviours.service';

@Component({
  templateUrl: './extra.component.html',
  styleUrls: ['./extra.component.css']
})

export class ExtraComponent implements OnInit, AfterViewInit  {
  displayedColumns: string[] = ['entity', 'fieldName', 'fieldCode', 'hint', 'fieldType', 'text', 'required', 'integer', 'double', 'positive', 'negative', 'manage'];
  data: any;
  isLoadingResults = true;
  isServiceAvailable = true;
  updateMode = false;
  rForm: FormGroup;

  extraFieldTypes: ExtraFieldType[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public formErrors = {
    entity: '',
    fieldName: '',
    fieldCode: '',
    hint: '',
    required: '',
    fieldType: '',
    integer: '',
    text: '',
    double: '',
    positive: '',
    negative: '',
  };

  constructor(public injector: Injector,
              public apiService: ApiService,
              public fb: FormBuilder,
              public utilitiesService: UtilitiesService,
              public confirmationDialogService: ConfirmationDialogService,
              public globalBehavioursService: GlobalBehavioursService,
              public formService: FormService) {

    this.rForm = fb.group({
      id: [null],
      entity: [null, Validators.required],
      fieldName: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      fieldCode: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(15)]],
      hint: [null, [Validators.minLength(1), Validators.maxLength(50)]],
      fieldType: [null, Validators.required],
      required: [null],
      integer: [null],
      text: [null],
      double: [null],
      positive: [null],
      negative: [null]
    });

    this.rForm.valueChanges.subscribe((data) => {
      this.formErrors = this.formService.validateForm(this.rForm, this.formErrors, true);
    });
  }

  isServiceAvailable$ = this.globalBehavioursService.isServiceAvailable$;
  notifier = this.injector.get(NotificationService);

  ngOnInit() {
    this.getExtraFieldType();
  }

  getExtraFieldType() {
    this.apiService.getAllPaginated(globals.EXTRA_FIELD_TYPES, {pageNo: 0, pageSize: 0, sortBy: 'description', sortOrder: 'asc'})
    .subscribe(
      (response: PaginatedResponse) => {
        this.extraFieldTypes = response?.content;
      }
    );
  }

  create(f: any) {
    const extra: Extra = {
      'fieldType': (f.fieldType) ? this.utilitiesService.generateQuickIdObject(f.fieldType) : null,
      'entity': f.entity,
      'fieldName': f.fieldName,
      'fieldCode': f.fieldCode.toUpperCase(),
      'hint': f.hint,
      'required': f.required,
      'integer': f.integer,
      'text': f.text,
      'double': f.double,
      'positive': f.positive,
      'negative': f.negative,
      'id': f.id
    };

    this.apiService.saveOnly(globals.EXTRAS, extra).subscribe(_ => {
      this.notifier.showSaved();
      this.resetForm();
      this.ngAfterViewInit();
      this.updateMode = false;
    }, error => this.isServiceAvailable = false);
  }

  formEdit(id: number) {
    this.apiService.getById(globals.EXTRAS, id).subscribe(
      (extra: Extra) => {
        this.rForm.controls.id.setValue(extra.id);
        this.rForm.controls.fieldType.setValue((extra.fieldType != null) ? extra.fieldType.id : null);
        this.rForm.controls.entity.setValue(extra.entity);
        this.rForm.controls.fieldName.setValue(extra.fieldName);
        this.rForm.controls.fieldCode.setValue(extra.fieldCode);
        this.rForm.controls.hint.setValue(extra.hint);
        this.rForm.controls.required.setValue(extra.required);
        this.rForm.controls.integer.setValue(extra.integer);
        this.rForm.controls.text.setValue(extra.text);
        this.rForm.controls.double.setValue(extra.double);
        this.rForm.controls.positive.setValue(extra.positive);
        this.rForm.controls.negative.setValue(extra.negative);
        this.updateMode = true;
        window.scroll(0, 0);
      });
  }

  update(f: any) {
    const extra: Extra = {
      'fieldType': (f.fieldType) ? this.utilitiesService.generateQuickIdObject(f.fieldType) : null,
      'entity': f.entity,
      'fieldName': f.fieldName,
      'fieldCode': f.fieldCode.toUpperCase(),
      'hint': f.hint,
      'required': f.required,
      'integer': f.integer,
      'text': f.text,
      'double': f.double,
      'positive': f.positive,
      'negative': f.negative,
      'id': f.id
    };

    return this.apiService.updateOnly(globals.EXTRAS, extra).subscribe(_ => {
      this.notifier.showSaved();
      this.setCreateMode();
      this.ngAfterViewInit();
    }, error => this.isServiceAvailable = false);
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // @ts-ignore
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.apiService.getAllPaginated(globals.EXTRAS,
            {pageNo: this.paginator.pageIndex, pageSize: this.paginator.pageSize, sortBy: this.sort.active, sortOrder: this.sort.direction}
          ).pipe(catchError(error => {
              this.isServiceAvailable = false;
              return observableOf(null);
            })
          );
        }),
        map(data => {
          this.isLoadingResults = false;

          if (data === null) {
            return [];
          }
          // @ts-ignore
          this.resultsLength = data.totalElements;
          this.paginator.length = data.totalElements;
          return data.content;
        })
      ).subscribe(data => this.data = data);
  }

  public openDeleteConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm...', 'This may cause problems if used elsewhere in the application.')
      .then((confirmed) => {
        if (confirmed) this.delete(id);
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  private delete(id) {
    this.apiService.deleteOnly(globals.EXTRAS, id)
      .subscribe(
        (res: boolean) => {
          this.notifier.showDeleted();
          this.ngAfterViewInit();
          this.setCreateMode();
        }/*,
          (err) => this.error = err*/
      );
  }

  resetForm() {
    this.rForm.reset();
  }

  setCreateMode() {
    this.rForm.reset();
    this.updateMode = false;
  }

}
