import {Component, Input, OnInit} from '@angular/core';
import { MatIconModule } from '@angular/material/icon'; // Remove if not used

@Component({
  selector: 'app-mini',
  templateUrl: './mini.component.html',
  styleUrls: ['../mini.scss']
})
export class MiniComponent implements OnInit {
  @Input() icon: string;
  @Input() title: string;
  @Input() value: number;
  @Input() color: string;

  constructor() { }

  ngOnInit(): void {
  }

}
