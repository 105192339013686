import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';

import { PayGrade } from './pay-grade';
import { ApiService } from 'src/app/admin/api.service';
import { NotificationService } from '../../services/notification.service'; // new
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table'; //pagination
import * as globals from 'src/app/globals';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog/confirmation-dialog.service';
import {CustomValidators} from "../../services/custom_validators";
import {FormService} from "../../services/form";
import {GlobalBehavioursService} from '../../services/global-behaviours.service';

@Component({
  selector: 'app-root', // WHAT MUST THE SELECTOR BE???
  templateUrl: './pay-grade.component.html',
  styleUrls: ['./pay-grade.component.css'/*,
  '../../../styles/global/libs/css/style.css'*/
]
})
export class PayGradeComponent implements OnInit {
  title = 'payroll-system (BASE - SALARY)';
  payGrades: MatTableDataSource<PayGrade>;
  displayedColumns: string[] = ['gradeName', 'minimumSalary', 'maximumSalary', 'midpointSalary', 'manage'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  payGrade = new PayGrade('', 0,0,0);

  rForm: FormGroup;
  public formErrors = {
    gradeName: '',
    minimumSalary: '',
    maximumSalary: '',
    midpointSalary: ''
  };
  showList = false;
  updateMode = false;

  requiredAlert = 'This field is required';

  constructor(private injector: Injector,
              public apiService: ApiService,
              public fb: FormBuilder,
              public formService: FormService,
              public globalBehavioursService: GlobalBehavioursService,
              public confirmationDialogService: ConfirmationDialogService) {
    this.rForm = fb.group({
        gradeName: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
        minimumSalary: [null, [Validators.required, CustomValidators.positiveNumberOrDecimal]],
        maximumSalary: [null, [Validators.required, CustomValidators.positiveNumberOrDecimal]],
        midpointSalary: [null, CustomValidators.positiveNumberOrDecimal]
      });
    this.rForm.valueChanges.subscribe((data) => {
      this.formErrors = this.formService.validateForm(this.rForm, this.formErrors, true);
    });
  }

  isServiceAvailable$ = this.globalBehavioursService.isServiceAvailable$;
  notifier = this.injector.get(NotificationService); // new

  ngOnInit() {
    if (this.showList) {
      this.getPaygrades();
    }
  }

  showHideList($isChecked): void {
    if ($isChecked) {
      if (!this.payGrades) {
        // Above Condition added to make the list available on demand. can't retrieve list if not defined (or it throws an error)
        this.getPaygrades();
      }
      this.showList = true;
    } else {
      this.showList = false;
    }
  }

  applyFilter(filterValue: string) {
    this.payGrades.filter = filterValue.trim().toLowerCase();

    if (this.payGrades.paginator) {
      this.payGrades.paginator.firstPage();
    }
  }

  getPaygrades(): void {
    this.apiService.getAll(globals.PAY_GRADE_ENDPOINT).subscribe(
      (res: PayGrade[]) => {
        this.payGrades = new MatTableDataSource(res);
        this.payGrades.paginator = this.paginator;
        this.payGrades.sort = this.sort;
      }/*,
      (err) => {
        alert('error: ' + JSON.stringify(err));
        this.error = err;
      }*/
    );
  }

  getSalary(id): void {
    this.apiService.getById(globals.PAY_GRADE_ENDPOINT, id).subscribe(
      (res: PayGrade) => {
        this.payGrade = res;
      }/*,
      (err) => {
        alert('error: ' + JSON.stringify(err));
        this.error = err;
      }*/
    );
  }

  addPayGrade(f) {

    let grade = new PayGrade();
    grade.gradeName = f.gradeName;
    grade.minimumSalary = f.minimumSalary;
    grade.maximumSalary = f.maximumSalary;
    grade.midpointSalary = f.midpointSalary;

    this.apiService.save(globals.PAY_GRADE_ENDPOINT, grade, (this.payGrades) ? this.payGrades.data : null)
      .subscribe(
        (res: PayGrade[]) => {
          // Update the list of paygrade
          if (this.showList) {
            // Above Condition added to make the list available on demand. service will only populate list if requested.
            this.payGrades.data = res;
          }
          // Inform the user
          this.notifier.showSaved();

          // Reset the form
          this.rForm.reset();
        }/*,
        (err) => this.error = err*/
      );
  }

  payGradeEdit(id){
    this.apiService.getById(globals.PAY_GRADE_ENDPOINT, id).subscribe(
      (res: PayGrade) => {
        this.payGrade = res;
        this.rForm.setValue({
          gradeName: this.payGrade.gradeName,
          minimumSalary: this.payGrade.minimumSalary,
          maximumSalary: this.payGrade.maximumSalary,
          midpointSalary: this.payGrade.midpointSalary
        });
      }/*,
      (err) => {
        alert('error: ' + JSON.stringify(err));
        this.error = err;
      }*/
    );
    this.updateMode = true;
    window.scroll(0, 0);
  }

  updateSalary(f) {
    this.payGrade.gradeName = f.gradeName;
    this.payGrade.minimumSalary = f.minimumSalary;
    this.payGrade.maximumSalary = f.maximumSalary;
    this.payGrade.midpointSalary = f.midpointSalary;
    this.apiService.update(globals.PAY_GRADE_ENDPOINT, this.payGrade, this.payGrades.data)
      .subscribe(
        (res) => {
          if (this.showList) {
            this.payGrades.data = res;
          }
          // Inform the user
          this.notifier.showSaved();
          this.payGrade = new PayGrade(); //reset object after update.
          this.updateMode = false;
          this.rForm.reset();
        }/*,
        (err) => this.error = err*/
      );
  }

  public openConfirmationDialog(id) {
    this.confirmationDialogService.confirm('Please confirm...', 'Are you sure you want to delete?')
    .then((confirmed) => {
      if (confirmed) this.deleteSalary(id);
    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  deleteSalary(id) {
    this.apiService.delete(globals.PAY_GRADE_ENDPOINT, id, this.payGrades.data)
      .subscribe(
        (res: PayGrade[]) => {
          if (this.showList) {
            this.payGrades.data = res;
          }
          // Inform the user
          this.notifier.showDeleted();

          this.updateMode = false;
          this.rForm.reset();
        }/*,
        (err) => this.error = err*/
      );
  }

  clearForm() {
    this.updateMode = false;
    this.rForm.reset();
  }
}
