export class Costcentre {
  name: any;
  owner: any;
  description: any;
  company: any;
  id: any;

  constructor(
    name?: string,
    owner?: string,
    description?: string,
    company?: string,
    id?: number) {}
  }
