import { Component, OnInit, AfterViewInit, ViewChild, QueryList, ViewChildren, Injector} from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {FormGroup, FormBuilder, Validators, FormArray, FormControl, ValidatorFn} from '@angular/forms';
import { Employee } from './employee';
import { PayGrade} from '../pay-grade/pay-grade';
import { CustomValidators } from '../../services/custom_validators';
import { FormService } from '../../services/form';
import { ApiService } from 'src/app/admin/api.service';
import { Department } from '../department/department';
import { Position } from '../position/position';
import { UtilitiesService } from '../../services/utilities.service';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import * as globals from 'src/app/globals';
import { Country } from '../countries/country';
import {map, startWith, switchMap} from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import {Company} from '../company/company';
import {Extra} from '../../restricted/extra/extra';
import {PaginatedResponse} from '../../interfaces/paginated-response';
import {EmployeeExtra} from '../../interfaces/employee-extra';
import {GlobalBehavioursService} from '../../services/global-behaviours.service';

interface FormulaType {
  id: number;
  name: string;
  selected: boolean;
}

@Component({
  //selector: 'app-root', // WHAT MUST THE SELECTOR BE???
  selector: 'app-manage-employee',
  templateUrl: './manage-employee.component.html',
  styleUrls: ['./manage-employee.component.css']
})

export class ManageEmployeeComponent implements OnInit, AfterViewInit {

  //title = 'payroll-system';
  employees: Employee[];

  //<Dropdown_Stuff>
  payGrades: Observable<PayGrade[]>;
  filteredPayGrades: Observable<PayGrade[]>;
  departments: Observable<Department[]>;
  filteredDepartments: Observable<Department[]>;
  filteredDepartments_: Observable<Department[]>;
  positions: Observable<Position[]>;
  filteredPositions: Observable<Position[]>;
  countries: Observable<Country[]>;
  filteredCountries: Observable<Country[]>;
  filteredNationalities: Observable<Country[]>;
  allEmployees: Observable<Employee[]>;
  filteredEmployees: Observable<Employee[]>;
  companies: Observable<Company[]>;
  filteredCompanies: Observable<Company[]>;

  employeeTypes: [];
  genders: [];
  titles: [];

  extras: Extra[];

  //</Dropdown_Stuff>

  //employee = new Employee('', '', '', '', '', '', '', '', 0, 0, 0, 0, '', '', '', '', '', '', '', '', '', '', '');
  employee = new Employee();

  rForm: FormGroup;
  showList = false;
  updateMode = false;
  post: any;                     // A property for our submitted form
  firstName = '';
  middleName = '';
  lastName = '';
  dob = '';
  idNumber = '';
  passportNumber = '';
  emailAddress = '';
  payGrade = 0;
  basicPay = 0;
  //company = 0;
  department = 0;
  position = '';
  taxNumber = '';
  hireDate = '';
  address1 = '';
  address2 = '';
  postalCode = '';
  country = '';
  title = 0;
  gender = 0;
  active = 0;
  employeeType = 0;
  maritalStatus = 0;
  nationality = 0;
  hourlyRate = 0;
  leaveDate = 0;
  phone = 0;
  extension = '';
  personalEmail = '';
  manager = 0;
  bankingDetails = 0;



  public formErrors = {
    firstName: '',
    middleName: '',
    lastName: '',
    dob: '',
    idNumber: '',
    passportNumber: '',
    emailAddress: '',
    payGrade: '',
    basicPay: '',
    company: '',
    department: '',
    position: '',
    taxNumber: '',
    hireDate: '',
    address1: '',
    address2: '',
    postalCode: '',
    country: '',
    title: '',
    gender: '',
    active: '',
    employeeType: '',
    maritalStatus: '',
    nationality: '',
    hourlyRate: '',
    leaveDate: '',
    cellNumber: '',
    homeNumber: '',
    extension: '',
    personalEmail: '',
    manager: '',
    bankName: '',
    bankAccount: '',
    bankBranch: '',
    bankSwift: '',
  };

  @ViewChildren(MatAutocompleteTrigger) triggerCollection:  QueryList<MatAutocompleteTrigger>;
  subscription: Subscription;

  get formulasFormArray() {
    //return this.rForm.get('formulas') as FormArray;
    return this.rForm.controls.formulas as FormArray;
  }

  defaultFormulas: [];
  // formulasList = new Array();

  constructor(private injector: Injector,
              private apiService: ApiService,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              private location: Location,
              private utilitiesService: UtilitiesService,
              public globalBehavioursService: GlobalBehavioursService,
              public formService: FormService) {

    this.rForm = fb.group({
      firstName: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(50), CustomValidators.validateCharacters]],
      middleName: [null, [Validators.minLength(1), Validators.maxLength(50)]],
      lastName: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(50), CustomValidators.validateCharacters]],
      dob: [null, [Validators.required, CustomValidators.DateOfBirth]],
      idNumber: [null, [Validators.minLength(13), Validators.maxLength(13)]],
      passportNumber: [null, [Validators.minLength(3), Validators.maxLength(50)]],
      emailAddress: [null, [Validators.required, Validators.email]], // THIS FIELD WAS FORGOTTEN. CATER FOR IT.
      payGrade: [null, [Validators.required]],
      basicPay: [null],
      company: [null, [Validators.required]],
      department: [null, [Validators.required]],
      position: [null, [Validators.required]],
      taxNumber: [null, [Validators.minLength(1), Validators.maxLength(50)]],
      hireDate: [null, [Validators.required]],
      address1: [null, [Validators.minLength(1), Validators.maxLength(50)]],
      address2: [null, [Validators.minLength(1), Validators.maxLength(50)]],
      postalCode: [null, [Validators.minLength(1), Validators.maxLength(10)]],
      country: [null],
      cellNumber: [null, [Validators.minLength(1), Validators.maxLength(15)]],
      homeNumber: [null, [Validators.minLength(1), Validators.maxLength(15)]],
      bankName: [null, [Validators.minLength(1), Validators.maxLength(50)]],
      bankAccount: [null, [Validators.minLength(1), Validators.maxLength(50)]],
      bankBranch: [null, [Validators.minLength(1), Validators.maxLength(50)]],
      bankSwift: [null, [Validators.minLength(1), Validators.maxLength(20)]],
      title: [null],
      gender: [null, [Validators.required]],
      active: [null],
      employeeType: [null, [Validators.required]],
      maritalStatus: [null],
      nationality: [null],
      hourlyRate: [null, [Validators.minLength(1), Validators.maxLength(50)]],
      leaveDate: [null],
      extension: [null, [Validators.minLength(1), Validators.maxLength(15)]],
      personalEmail: [null, [Validators.email]],
      manager: [null],
      formulas: new FormArray([])
    });

    // on each value change we call the validateForm function
    // We only validate form controls that are dirty, meaning they are touched
    // the result is passed to the formErrors object
    this.rForm.valueChanges.subscribe((data) => {
      this.formErrors = this.formService.validateForm(this.rForm, this.formErrors, true);
      // console.log('Invalid:' + this.findInvalidControls() + '\n'); // Useful for debugging
    });
  }

  /*public findInvalidControls() {
    const invalid = [];
    const controls = this.rForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }*/

  isServiceAvailable$ = this.globalBehavioursService.isServiceAvailable$;
  notifier = this.injector.get(NotificationService);

  ngOnInit() {
    //<Dropdown_Stuff>
    this.getPayGrades();
    this.filteredPayGrades = this.thePayGrade.valueChanges
      .pipe(
        startWith(''),
        switchMap(value => this.filterPayGrades(value))
      );

    this.getPositions();
    this.filteredPositions = this.theposition.valueChanges
      .pipe(
        startWith(''),
        switchMap(value => this.filterPositions(value))
      );
    this.getCountries();
    this.filteredCountries = this.thecountry.valueChanges
      .pipe(
        startWith(''),
        switchMap(value => this.filterCountries(value))
      );
    this.filteredNationalities = this.thenationality.valueChanges
      .pipe(
        startWith(''),
        switchMap(value => this.filterCountries(value))
      );
    /*this.getEmployees();
    this.filteredEmployees = this.theManager.valueChanges
    .pipe(
      startWith(''),
      switchMap(value => this.filterEmployees(value))
    );*/
    this.getCompanies();
    this.filteredCompanies = this.theCompany.valueChanges
      .pipe(
        startWith(''),
        switchMap(value => this.filterCompanies(value))
      );

    this.getEmployeeTypes();
    this.getGenders();
    this.getTitles();
    //</Dropdown_Stuff>

    this.getExtras(); // MUST HAPPEN BEFORE this.getEmployeeToEdit(); in order to prepare dynamic fields (additional info)
    this.getEmployeeToEdit();

    this.getFormulas();

  }

  /*getExtras(): void {
    this.apiService.getWithParams(globals.EXTRAS + '/options', {entity: 'employee'}).subscribe(
      (response: Extra[]) => {
        this.extras = response;
        this.extras.forEach(extra => {
          this.formErrors[extra.id + ''] = '';
          this.rForm.addControl(extra.id + '', new FormControl(null, Validators.required));
        });
    });
  }*/

  getExtras(): void {
    this.apiService.getWithParams(globals.EXTRAS + '/options', {entity: 'employee'}).subscribe(
      (response: Extra[]) => {
        this.extras = response;
        this.extras.forEach(extra => {
          this.formErrors[extra.id + ''] = '';
          const validators: ValidatorFn[] = [];
          if (extra.required) {
            validators.push(Validators.required);
          }
          if (extra.text) { // Overwrites the rest
            // validators = [Validators.minLength(1), Validators.maxLength(50)];
            validators.push(Validators.minLength(1));
            validators.push(Validators.maxLength(50));
          } else {
            if (extra.double && extra.integer) {
              extra.integer = false;
            }
            if (extra.double) {
              if (extra.positive && extra.negative) {
                validators.push(CustomValidators.numberOrDecimal);
              } else if (extra.positive && !extra.negative) {
                validators.push(CustomValidators.positiveNumberOrDecimal);
              } else if (!extra.positive && extra.negative) {
                validators.push(CustomValidators.negativeNumberOrDecimal);
              } else if (!extra.positive && !extra.negative) {
                validators.push(CustomValidators.numberOrDecimal);
              }
            }
            if (extra.integer) {
              if (extra.positive && extra.negative) {
                validators.push(CustomValidators.integerOnly);
              } else if (extra.positive && !extra.negative) {
                validators.push(CustomValidators.positiveIntegerOnly);
              } else if (!extra.positive && extra.negative) {
                validators.push(CustomValidators.negativeIntegerOnly);
              } else if (!extra.positive && !extra.negative) {
                validators.push(CustomValidators.integerOnly);
              }
            }
          }
          this.rForm.addControl(extra.id + '', new FormControl(null, validators));
        });
      });
  }

  getFormulas(): void {
    this.formulasFormArray.controls.splice(0); // remove all
    this.apiService.getAll(globals.FORMULA_ENDPOINT + '/pretax'/*'/formula-type/1'*/).subscribe(
      (response: []) => {
        this.defaultFormulas = response;
        response.forEach(formula => {
          const formulaObject: FormulaType = {
            id: formula['id'],
            name: formula['name'],
            selected: false
          };
          const chkGrp = this.fb.group(formulaObject);
          this.formulasFormArray.push(chkGrp);
        });
      }
    );
  }


  /*private addCheckboxesToForm() {
    this.formulasList.forEach(() => this.formulasFormArray.push(new FormControl(false)));
  }*/

  getEmployeeToEdit(): void {
    const id = +this.utilitiesService.Decrypt(this.route.snapshot.paramMap.get('id'));
    if (id>0){
      this.updateMode = true;
      this.employeeEdit(id);
    }
  }

  goBack(): void {
    this.location.back();
  }

  getEmployee(id): void {
    this.apiService.getById(globals.EMPLOYEE_ENDPOINT, id).subscribe(
      (res: Employee) => {
        this.employee = res;
      },
      (err) => {
        this.notifier.showError('Could not retrieve employee!');
      }
    );
  }

  addEmployee(f) {

    this.employee.empCode = Math.floor(Math.random() * 10001); // Temp random
    this.employee.firstName = f.firstName;
    this.employee.middleName = f.middleName;
    this.employee.lastName = f.lastName;
    this.employee.dob = f.dob;
    this.employee.idNumber = f.idNumber;
    this.employee.passportNumber = f.passportNumber;
    this.employee.emailAddress = f.emailAddress;
    this.employee.payGrade = this.utilitiesService.generateQuickIdObject(f.payGrade);//f.payGrade;
    this.employee.basicPay = 0; //f.basicPay;
    this.employee.department = this.utilitiesService.generateQuickIdObject(f.department);//f.department;
    this.employee.position = this.utilitiesService.generateQuickIdObject(f.position);//f.position;
    this.employee.taxNumber = f.taxNumber;
    this.employee.hireDate = f.hireDate;

    this.employee.active = true;
    this.employee.title = (f.title) ? this.utilitiesService.generateQuickIdObject(f.title) : null;//id?
    this.employee.gender = (f.gender) ? this.utilitiesService.generateQuickIdObject(f.gender) : null;//.id?
    this.employee.employeeType = (f.employeeType) ? this.utilitiesService.generateQuickIdObject(f.employeeType) : null;//.id?
    this.employee.maritalStatus = (f.maritalStatus) ? f.maritalStatus : null;//.id?
    this.employee.nationality = (f.nationality) ? this.utilitiesService.generateQuickIdObject(f.nationality) : null;
    this.employee.hourlyRate = f.hourlyRate;
    this.employee.leaveDate = f.leaveDate;
    this.employee.extension = f.extension;
    this.employee.personalEmail = f.personalEmail;
    this.employee.manager = (f.manager) ? this.utilitiesService.generateQuickIdObject(f.manager) : null;////////////

    let phone = {};
    phone['home'] = f.homeNumber;
    phone['cell'] = f.cellNumber;
    if (!this.utilitiesService.allPropertiesNull(phone)) {
      this.employee.phone = phone;
    }

    let bankingDetails = {};
    bankingDetails['bank_name'] = f.bankName;
    bankingDetails['bank_account'] = f.bankAccount;
    bankingDetails['branch_code'] = f.bankBranch;
    bankingDetails['swift_code'] = f.bankSwift;
    if (!this.utilitiesService.allPropertiesNull(bankingDetails)) {
      if ((bankingDetails['bank_name']) && (bankingDetails['bank_name']))
        this.employee.bankingDetails = bankingDetails;
      else {
        alert('If banking details are supplied, please make sure to include bank name and account number or remove it entirely.');//KEEP THIS ALERT - IT IS INTENDED
        return;
      }
    }

    let address = {};
    address['line1'] = f.address1;
    address['line2'] = f.address2;
    address['postalCode'] = f.postalCode;
    address['country'] = this.utilitiesService.generateQuickIdObject(f.country);
    if (!this.utilitiesService.allPropertiesNull(address))
    {
      if ((address['line1']) && (address['country']))
        this.employee.address = address;
      else {
        alert('If the address is supplied, please make sure it is valid or remove it entirely.');//KEEP THIS ALERT - IT IS INTENDED
        return;
      }
    } // else address is null, it will be discarded.

    // Formula
    const selectedOnes = this.rForm.value.formulas.filter(i => i.selected);
    const selectedFormulasList = [];
    for (let i = 0; i < selectedOnes.length; i++) {
      const formulaObj = {};
      formulaObj['employee'] = this.utilitiesService.generateQuickIdObject(0);
      formulaObj['formula'] = this.utilitiesService.generateQuickIdObject(selectedOnes[i]);
      selectedFormulasList.push(formulaObj);
    }
    this.employee['formulas'] = selectedFormulasList;

    // Extra
    const extras: EmployeeExtra[] = [];
    for (let i = 0; i < this.extras.length; i++) {
      const employeeExtra: EmployeeExtra = {
        extra: this.utilitiesService.generateQuickIdObject(this.extras[i].id),
        value: f[this.extras[i].id + '']
      };
      extras.push(employeeExtra);
    }

    this.employee.extras = extras;

    this.apiService.saveOnly(globals.EMPLOYEE_ENDPOINT, this.employee)
      .subscribe(
        (res: boolean) => {
          if (res) {
            this.notifier.showSaved();
          } else {
            this.notifier.showGenericError();
          }
          this.rForm.reset();
          this.getFormulas();
        },
        (err) => this.notifier.showGenericError()
      );
    window.scroll(0, 0);
  }

  employeeEdit(id){
    this.apiService.getById(globals.EMPLOYEE_ENDPOINT, id).subscribe(
      (res: Employee) => {
        this.employee = res;
        //this.rForm.setValue({
        this.rForm.patchValue({
          firstName: this.employee.firstName,
          middleName: this.employee.middleName,
          lastName: this.employee.lastName,
          dob: this.employee.dob,
          idNumber: this.employee.idNumber,
          passportNumber: this.employee.passportNumber,
          emailAddress: this.employee.emailAddress,
          payGrade: (this.employee.payGrade != null) ? this.employee.payGrade : null, // very import otherwise null breaks things
          basicPay: this.employee.basicPay,
          department: (this.employee.department != null) ? this.employee.department : null, // very important to have the condition
          company: (this.employee.department.company != null) ? this.employee.department.company : null,
          position: (this.employee.position != null) ? this.employee.position : null,
          taxNumber: this.employee.taxNumber,
          hireDate: this.employee.hireDate,
          address1: (this.employee.address != null) ? this.employee.address.line1 : null,
          address2: (this.employee.address != null) ? this.employee.address.line2 : null,
          postalCode: (this.employee.address != null) ? this.employee.address.postalCode : null,
          country: (this.employee.address != null) ? this.employee.address.country : null,

          active: this.employee.active,
          title: (this.employee.title != null) ? this.employee.title.id : null,
          gender: (this.employee.gender != null) ? this.employee.gender.id : null,
          employeeType: (this.employee.employeeType != null) ? this.employee.employeeType.id : null,
          maritalStatus: this.employee.maritalStatus,
          nationality: this.employee.nationality,
          hourlyRate: this.employee.hourlyRate,
          leaveDate: this.employee.leaveDate,
          extension: this.employee.extension,
          personalEmail: this.employee.personalEmail,
          manager: this.employee.manager,
          homeNumber: (this.employee.phone != null) ? this.employee.phone.home : null,
          cellNumber: (this.employee.phone != null) ? this.employee.phone.cell : null,
          bankName: (this.employee.bankingDetails != null) ? this.employee.bankingDetails.bank_name : null,
          bankAccount: (this.employee.bankingDetails != null) ? this.employee.bankingDetails.bank_account : null,
          bankBranch: (this.employee.bankingDetails != null) ? this.employee.bankingDetails.branch_code : null,
          bankSwift: (this.employee.bankingDetails != null) ? this.employee.bankingDetails.swift_code : null,
          /*formulas: new FormArray([])*/
        });

        //
        this.apiService.getAll(globals.FORMULA_ENDPOINT + '/pretax' /*'/formula-type/1'*/).subscribe(
          (response: []) => {
            this.defaultFormulas = response;
            this.formulasFormArray.controls.splice(0); // remove all
            this.defaultFormulas.forEach(formula => {
              let isSelected = false;
              this.employee.formulas.forEach(submittedFormulaObj => {
                if (formula['id'] === submittedFormulaObj['formula']['id']) {
                  isSelected = true;
                }
              });
              const formulaObject: FormulaType = {
                id: formula['id'],
                name: formula['name'],
                selected: isSelected
              };
              const chkGrp = this.fb.group(formulaObject);
              this.formulasFormArray.push(chkGrp);
            });
          },
          (err) => {
            this.notifier.showGenericError();
          });

        // Extra
        this.employee.extras.forEach(employeeExtra => {
          const formPropertyName = employeeExtra.extra.id;
          const formPropertyValue = employeeExtra.value;
          this.rForm.patchValue({
            [formPropertyName] : formPropertyValue
          });
        });
      },
      (err) => {
        this.notifier.showGenericError();
      });
  }

  updateEmployee(f) {
    this.employee.firstName = f.firstName;
    this.employee.middleName = f.middleName;
    this.employee.lastName = f.lastName;
    this.employee.dob = f.dob;
    this.employee.idNumber = f.idNumber;
    this.employee.passportNumber = f.passportNumber;
    this.employee.emailAddress = f.emailAddress;
    this.employee.payGrade = this.utilitiesService.generateQuickIdObject(f.payGrade);//check behaviour when null
    this.employee.basicPay = 0; //f.basicPay;
    this.employee.department = this.utilitiesService.generateQuickIdObject(f.department);//check behaviour when null
    this.employee.position = this.utilitiesService.generateQuickIdObject(f.position);//check behaviour when null
    this.employee.taxNumber = f.taxNumber;
    this.employee.hireDate = f.hireDate;

    this.employee.active = f.active;
    this.employee.title = (f.title) ? this.utilitiesService.generateQuickIdObject(f.title) : null;
    this.employee.gender = (f.gender) ? this.utilitiesService.generateQuickIdObject(f.gender) : null;
    this.employee.employeeType = (f.employeeType) ? this.utilitiesService.generateQuickIdObject(f.employeeType) : null;
    this.employee.maritalStatus = (f.maritalStatus) ? f.maritalStatus : null;
    this.employee.nationality = (f.nationality) ? this.utilitiesService.generateQuickIdObject(f.nationality) : null;
    this.employee.hourlyRate = f.hourlyRate;
    this.employee.leaveDate = f.leaveDate;
    this.employee.extension = f.extension;
    this.employee.personalEmail = f.personalEmail;
    this.employee.manager = (f.manager) ? this.utilitiesService.generateQuickIdObject(f.manager) : null;

    let phone = {};
    phone['home'] = f.homeNumber;
    phone['cell'] = f.cellNumber;
    if (!this.utilitiesService.allPropertiesNull(phone)) {
      if (this.employee.phone) phone['id'] = this.employee.phone.id;//
      this.employee.phone = phone;
    } else
      this.employee.phone = null;

    let bankingDetails = {};
    bankingDetails['bank_name'] = f.bankName;
    bankingDetails['bank_account'] = f.bankAccount;
    bankingDetails['branch_code'] = f.bankBranch;
    bankingDetails['swift_code'] = f.bankSwift;
    if (!this.utilitiesService.allPropertiesNull(bankingDetails)) {
      if ((bankingDetails['bank_name']) && (bankingDetails['bank_name'])) {
        if (this.employee.bankingDetails) bankingDetails['id'] = this.employee.bankingDetails.id;//
        this.employee.bankingDetails = bankingDetails;
      } else {
        alert('If banking details are supplied, please make sure to include bank name and account number or remove it entirely.');//KEEP THIS ALERT - IT IS INTENDED
        return;
      }
    } else {
      this.employee.bankingDetails = null;
    }

    let address = {};
    address['line1'] = f.address1;
    address['line2'] = f.address2;
    address['postalCode'] = f.postalCode;
    address['country'] = this.utilitiesService.generateQuickIdObject(f.country);
    //alert('f.country\n' + f.country + '\naddress\n' + JSON.stringify(address));
    if (!this.utilitiesService.allPropertiesNull(address))
    {
      if ((address['line1']) && (address['country'])) {
        if (this.employee.address) address['id'] = this.employee.address.id;
        this.employee.address = address;
      } else {
        alert('If the address is supplied, please make sure it is valid or remove it entirely.');//KEEP THIS ALERT - IT IS INTENDED
        return;
      }
    } else {
      this.employee.address = null;// Do not discard but set it to null
    }

    const selectedOnes = this.rForm.value.formulas.filter(i => i.selected);
    const selectedFormulasList = [];
    for (let i = 0; i < selectedOnes.length; i++) {
      const formulaObj = {};
      formulaObj['employee'] = this.utilitiesService.generateQuickIdObject(this.employee.id);
      formulaObj['formula'] = this.utilitiesService.generateQuickIdObject(selectedOnes[i]);
      selectedFormulasList.push(formulaObj);
    }
    this.employee['formulas'] = selectedFormulasList;

    // Extra
    const extras: EmployeeExtra[] = [];
    for (let i = 0; i < this.extras.length; i++) {
      const employeeExtra: EmployeeExtra = {
        extra: this.utilitiesService.generateQuickIdObject(this.extras[i].id),
        value: f[this.extras[i].id + '']
      };
      extras.push(employeeExtra);
    }
    this.employee.extras = extras;

    this.apiService.updateOnly(globals.EMPLOYEE_ENDPOINT, this.employee)
      .subscribe(
        (res) => {
          this.notifier.showSaved();
        },
        (err) => this.notifier.showGenericError()
      );
  }

  deleteEmployee(id) {
    this.apiService.deleteOnly(globals.EMPLOYEE_ENDPOINT, id)
      .subscribe(
        (res: boolean) => {
          this.notifier.showDeleted();
        },
        (err) => this.notifier.showGenericError()
      );
  }

  //<Dropdown_Stuff>
  // below is for dropdown
  getPayGrades(): void {
    this.payGrades = this.apiService.getAll(globals.PAY_GRADE_ENDPOINT);
  }

  /*getDepartments(): void {
    this.departments = this.apiService.getAll(globals.DEPARTMENT_ENDPOINT);
  }*/

  /*getCompanyDepartments(event) {
    this.rForm.controls.department.reset();
    let selectCompanyId = event.option.value.id;
    this.departments = this.apiService.getAll(globals.DEPARTMENT_ENDPOINT+'/by-company/' + selectCompanyId);

    this.filteredDepartments = this.rForm.get('department').valueChanges
      .pipe(
        startWith(''),
        switchMap(value => this.filterDepartments(value))
      );
  }*/

  getCompanyDepartmentsAndEmployees(event) {
    this.rForm.controls.department.reset();
    this.rForm.controls.manager.reset();
    let selectCompanyId = event.option.value.id;

    this.departments = this.apiService.getAll(globals.DEPARTMENT_ENDPOINT+'/by-company/'+selectCompanyId);
    this.filteredDepartments = this.rForm.get('department').valueChanges
      .pipe(
        startWith(''),
        switchMap(value => this.filterDepartments(value))
      );

    this.allEmployees = this.apiService.getAll(globals.EMPLOYEE_ENDPOINT+'/related-to-company/'+selectCompanyId);
    this.filteredEmployees = this.theManager.valueChanges
      .pipe(
        startWith(''),
        switchMap(value => this.filterEmployees(value))
      );
  }

  getPositions(): void {
    this.positions = this.apiService.getAll(globals.POSITION_ENDPOINT);
  }
  getCountries(): void {
    this.countries = this.apiService.getAll(globals.COUNTRY_ENDPOINT);
  }
  getEmployees(): void {
    this.allEmployees = this.apiService.getAll(globals.EMPLOYEE_ENDPOINT);
  }
  /*getEmployees(): void {
    this.allEmployees = Observable.of(_);
    this.apiService.getAll(globals.EMPLOYEE_ENDPOINT).subscribe(_ => {
    this.allEmployees = Observable.of(_);
    })
  }*/
  getCompanies(): void {
    this.companies = this.apiService.getAll(globals.COMPANY_ENDPOINT);
  }
  getEmployeeTypes(): void {
    this.apiService.getAll(globals.EMPLOYEE_TYPE_ENDPOINT).subscribe(
      (res: []) => {
        this.employeeTypes = res;
      }
    );
  }
  getGenders(): void {
    this.apiService.getAll(globals.GENDER_ENDPOINT).subscribe(
      (res: []) => {
        this.genders = res;
      }
    );
  }
  getTitles(): void {
    this.apiService.getAll(globals.TITLES_ENDPOINT).subscribe(
      (res: []) => {
        this.titles = res;
      }
    );
  }
  ngAfterViewInit() {
    this._subscribeToClosingActions();
  }

  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  private _subscribeToClosingActions(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.triggerCollection.toArray()[0].panelClosingActions
      .subscribe(e => {
          if (!e || !e.source) {
            if (!this.utilitiesService.isObject(this.rForm.getRawValue().nationality))
              this.rForm.controls.nationality.setValue(null);
          }
        },
        err => this._subscribeToClosingActions(),
        () => this._subscribeToClosingActions());

    this.subscription = this.triggerCollection.toArray()[1].panelClosingActions
      .subscribe(e => {
          if (!e || !e.source) {
            if (!this.utilitiesService.isObject(this.rForm.getRawValue().country))
              this.rForm.controls.country.setValue(null);
          }
        },
        err => this._subscribeToClosingActions(),
        () => this._subscribeToClosingActions());

    this.subscription = this.triggerCollection.toArray()[2].panelClosingActions
      .subscribe(e => {
          if (!e || !e.source) {
            if (!this.utilitiesService.isObject(this.rForm.getRawValue().department))
              this.rForm.controls.department.setValue(null);
          }
        },
        err => this._subscribeToClosingActions(),
        () => this._subscribeToClosingActions());

    this.subscription = this.triggerCollection.toArray()[3].panelClosingActions
      .subscribe(e => {
          if (!e || !e.source) {
            if (!this.utilitiesService.isObject(this.rForm.getRawValue().position))
              this.rForm.controls.position.setValue(null);
          }
        },
        err => this._subscribeToClosingActions(),
        () => this._subscribeToClosingActions());

    this.subscription = this.triggerCollection.toArray()[4].panelClosingActions
      .subscribe(e => {
          if (!e || !e.source) {
            if (!this.utilitiesService.isObject(this.rForm.getRawValue().manager))
              this.rForm.controls.manager.setValue(null);
          }
        },
        err => this._subscribeToClosingActions(),
        () => this._subscribeToClosingActions());

    this.subscription = this.triggerCollection.toArray()[5].panelClosingActions
      .subscribe(e => {
          if (!e || !e.source) {
            if (!this.utilitiesService.isObject(this.rForm.getRawValue().payGrade))
              this.rForm.controls.payGrade.setValue(null);
          }
        },
        err => this._subscribeToClosingActions(),
        () => this._subscribeToClosingActions());

    this.subscription = this.triggerCollection.toArray()[6].panelClosingActions
      .subscribe(e => {
          if (!e || !e.source) {
            if (!this.utilitiesService.isObject(this.rForm.getRawValue().company))
              this.rForm.controls.company.setValue(null);
          }
        },
        err => this._subscribeToClosingActions(),
        () => this._subscribeToClosingActions());
  }

  private filterPayGrades(value: string | PayGrade) {
    let filterValue = '';
    if (value) {
      filterValue = typeof value === 'string' ? value.toLowerCase() : value.gradeName.toLowerCase();
      return this.payGrades.pipe(
        map(payGrades => payGrades.filter(payGrade => payGrade.gradeName.toLowerCase().includes(filterValue) || payGrade.midpointSalary.toString().toLowerCase().includes(filterValue)))
      );
    } else {
      return this.payGrades;
    }
  }
  displayPayGradeFn(payGrade?: PayGrade): string | undefined {
    return payGrade ? payGrade.gradeName + ' (Grade ' + payGrade.midpointSalary + ')' : undefined;
  }

  private filterDepartments(value: string | Department) {
    let filterValue = '';
    if (value) {
      filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
      return this.departments.pipe(
        map(departments => departments.filter(department => department.name.toLowerCase().includes(filterValue)))
      );
    } else {
      return this.departments;
    }
  }

  displayDepartmentFn(department?: Department): string | undefined {
    return department ? department.name : undefined;
  }

  displayCompanyFn(company?: Company): string | undefined {
    return company ? company.name : undefined;
  }

  private filterPositions(value: string | Position) {
    let filterValue = '';
    if (value) {
      filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
      return this.positions.pipe(
        map(positions => positions.filter(position => position.name.toLowerCase().includes(filterValue)))
      );
    } else {
      return this.positions;
    }
  }
  displayPositionFn(position?: Position): string | undefined {
    return position ? position.name : undefined;
  }

  private filterCountries(value: string | Country) {
    let filterValue = '';
    if (value) {
      filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
      return this.countries.pipe(
        map(countries => countries.filter(country => country.name.toLowerCase().includes(filterValue)))
      );
    } else {
      return this.countries;
    }
  }
  /*private filterNationalities(value: string | Country) {
    let filterValue = '';
    if (value) {
      filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
      return this.countries.pipe(
        map(countries => countries.filter(country => country.name.toLowerCase().includes(filterValue)))
      );
    } else {
      return this.countries;
    }
  }*/
  displayCountryFn(country?: Country): string | undefined {
    return country ? country.name : undefined;
  }

  private filterEmployees(value: string | Employee) {
    let filterValue = '';
    if (value) {
      filterValue = typeof value === 'string' ? value.toLowerCase() : value.firstName.toLowerCase();
      return this.allEmployees.pipe(
        map(employees => employees.filter(employee => employee.firstName.toLowerCase().includes(filterValue) || employee.lastName.toLowerCase().includes(filterValue)))
      );
    } else {
      return this.allEmployees;
    }
  }
  displayManagerFn(employee?: Employee): string | undefined {
    return employee ? employee.firstName + ' ' + employee.lastName : undefined;
  }

  private filterCompanies(value: string | Company) {
    let filterValue = '';
    if (value) {
      filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
      return this.companies.pipe(
        map(companies => companies.filter(company => company.name.toLowerCase().includes(filterValue)))
      );
    } else {
      return this.companies;
    }
  }

  get theCompany() {
    return this.rForm.get('company');
  }

  get thePayGrade() {
    return this.rForm.get('payGrade');
  }
  get thedepartment() {
    return this.rForm.get('department');
  }
  get theposition() {
    return this.rForm.get('position');
  }
  get thecountry() {
    return this.rForm.get('country');
  }
  get thenationality() {
    return this.rForm.get('nationality');
  }
  get theManager() {
    return this.rForm.get('manager');
  }
  //</Dropdown_Stuff>
}
