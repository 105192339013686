import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { LoginComponent } from '../user/login.component'; No longer needed here since it needs it's own view
import { DashboardComponent } from '../dashboard/dashboard.component';
import { AppComponent } from '../app.component';
import { PayGradeComponent } from '../admin/pay-grade/pay-grade.component';
import { CostcentreComponent } from '../admin/costcentre/costcentre.component';
import { DepartmentComponent } from '../admin/department/department.component';
import { PositionComponent } from '../admin/position/position.component';
import { ManageEmployeeComponent } from '../admin/employee/manage-employee.component';
import { EmployeeListComponent } from '../admin/employee/employee-list.component';
import { EmployeeDetailsComponent } from '../admin/employee/employee-details.component';
import { FormulaComponent } from '../admin/formula/formula.component';
import { PayrollPeriodComponent } from '../admin/payrollPeriod/payrollPeriod.component';
import { AbsenceComponent } from '../admin/Absence/absence.component';
import { OvertimeComponent } from '../admin/overtime/overtime.component';
import { TaxYearComponent } from '../admin/taxyear/tax-year.component';
import { TaxTableComponent } from '../admin/taxtable/taxtable.component';
import { TaxRebateComponent} from '../admin/taxrebate/tax-rebate.component';
import { TaxThresholdComponent} from '../admin/taxthreshold/tax-threshold.component';
import { CalendarComponent} from '../admin/calendar/calendar.component';
import { PayrollComponent } from '../admin/payroll/payroll.component';
import { PPDetailsComponent } from '../admin/payrollPeriod/ppdetails.component';
import { ViewPayslipComponent } from '../admin/payslip/view-payslip.component';
import { CountryComponent } from '../admin/countries/country.component';
import { UserListComponent } from '../user/user-list.component';
import { ManageUserComponent } from '../user/manage-user.component';
import { CompanyComponent } from '../admin/company/company.component';
import { PersonalDetailsComponent } from '../employee/details/personal-details.component';
import { ConfigComponent } from '../admin/config/config.component';
import { TestComponent } from '../admin/test/test.component';
import { EmployeePeriodsComponent} from '../employee/period/employeePeriods.component';
import { DashComponent} from '../dash/dash.component';
import { ExtraComponent} from '../restricted/extra/extra.component';
import { SalaryComponent} from '../admin/salary/salary.component';
import { BonusComponent} from '../admin/bonus/bonus.component';

//emp
import { PayslipComponent } from '../employee/payslip/payslip.component';

import { GlobalErrorComponent }  from '../services/global-error.component';
import { PageNotFoundComponent }  from '../services/page-not-found.component'; //Trying

import { AuthGuard } from '../guards';
import { Role } from '../user/role';
import { SettingsComponent } from '../user/settings.component';

const routes: Routes = [
  /*{
    path: 'login',
    component: LoginComponent,
  },*/
  {
    path: 'manage_employee', //create employee
    component: ManageEmployeeComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_EMPLOYEE_ADMIN,] }
  },
  {
    path: 'manage_employee/:id',
    component: ManageEmployeeComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_EMPLOYEE_ADMIN] }
  },
  {
    path: 'employee_list/:for',
    component: EmployeeListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_ADMIN, Role.ROLE_PAYROLL_ADMIN, Role.ROLE_EMPLOYEE_ADMIN] }
  },
  {
    path: 'salary/:id',
    component: SalaryComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_ADMIN, Role.ROLE_PAYROLL_ADMIN, Role.ROLE_EMPLOYEE_ADMIN] }
  },
  {
    path: 'bonus/:id',
    component: BonusComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_ADMIN, Role.ROLE_PAYROLL_ADMIN, Role.ROLE_EMPLOYEE_ADMIN] }
  },
  {
    path: 'user_list',
    component: UserListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_ADMIN] }
  },
  {
    path: 'manage_user',
    component: ManageUserComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_ADMIN] }
  },
  {
    path: 'create_user/:empId',
    component: ManageUserComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_ADMIN] }
  },
  {
    path: 'edit_user/:emp_userIds',
    component: ManageUserComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_ADMIN] }
  },
  {
    path: 'employee_details/:id',
    component: EmployeeDetailsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_PAYROLL_ADMIN, Role.ROLE_EMPLOYEE_ADMIN] }
  },
  {
    path: 'pay-grade',
    component: PayGradeComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_PAYROLL_ADMIN, Role.ROLE_CLERK] }
  },
  {
    path: 'costcentre',
    component: CostcentreComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_PAYROLL_ADMIN, Role.ROLE_CLERK] }
  },
  {
    path: 'department',
    component: DepartmentComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_EMPLOYEE_ADMIN, Role.ROLE_CLERK] }
  },
  {
    path: 'position',
    component: PositionComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_EMPLOYEE_ADMIN, Role.ROLE_CLERK] }
  },
  {
    path: 'countries',
    component: CountryComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_EMPLOYEE_ADMIN] }
  },
  {
    path: 'company_details',
    component: CompanyComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_EMPLOYEE_ADMIN] }
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_USER] }
  },
  {
    path: 'config',
    component: ConfigComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_ADMIN] }
  },
  {
    path: 'formula',
    component: FormulaComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_PAYROLL_ADMIN] }
  },
  {
    path: 'payrollperiod',
    component: PayrollPeriodComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_PAYROLL_ADMIN, Role.ROLE_CLERK] }
  },
  {
    path: 'absence',
    component: AbsenceComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_EMPLOYEE_ADMIN, Role.ROLE_CLERK] }
  },
  {
    path: 'overtime',
    component: OvertimeComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_EMPLOYEE_ADMIN, Role.ROLE_CLERK] }
  },
  {
    path: 'tax-year',
    component: TaxYearComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_EMPLOYEE_ADMIN, Role.ROLE_CLERK] }
  },
  {
    path: 'tax-table',
    component: TaxTableComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_PAYROLL_ADMIN, Role.ROLE_CLERK] }
  },
  {
    path: 'tax-rebate',
    component: TaxRebateComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_PAYROLL_ADMIN, Role.ROLE_CLERK] }
  },
  {
    path: 'tax-threshold',
    component: TaxThresholdComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_PAYROLL_ADMIN, Role.ROLE_CLERK] }
  },
  {
    path: 'calendar',
    component: CalendarComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_PAYROLL_ADMIN, Role.ROLE_CLERK] }
  },
  {
    path: 'payroll',
    component: PayrollComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_PAYROLL_ADMIN, Role.ROLE_CLERK] }
  },
  {
    path: 'payroll-period-view/:id',
    component: PPDetailsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_PAYROLL_ADMIN] }
  },
  {
    path: 'view-payslip/:ppId/:eId',
    component: ViewPayslipComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_PAYROLL_ADMIN] }
  },
  {
    path: 'payslip/:ppId',
    component: PayslipComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_EMPLOYEE] }
  },
  {
    path: 'employee/payslip/:id',
    component: PayslipComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_EMPLOYEE] }
  },
  {
    path: 'employee/self',
    component: PersonalDetailsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_EMPLOYEE] }
  },
  {
    path: 'restricted/extra',
    component: ExtraComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_RESTRICTED] }
  },
  {
    path: 'employee/periods',
    component: EmployeePeriodsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_EMPLOYEE] }
  },
  {
    path: 'test',
    component: TestComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ROLE_ADMIN] }
  },
  {
      path: '',
      component: DashboardComponent,
  },
  { path: 'dashboard', component: DashComponent, canActivate: [AuthGuard], data: { roles: [Role.ROLE_PAYROLL_ADMIN] } },
  {
    path: 'error',
    component: GlobalErrorComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class AppRoutingModule { }
