import { Component, OnInit, Injector } from '@angular/core';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Payroll } from './payroll';
import { PayrollService } from './payroll.service';
import { CustomValidators } from '../../services/custom_validators';
import { FormService } from '../../services/form';
import { DataService } from '../data.service';
import { NotificationService } from '../../services/notification.service';
import { PayrollPeriod } from '../payrollPeriod/payrollPeriod'; // For dropdown
import { Formula } from '../formula/formula';
import {ApiService} from '../api.service';
import * as globals from '../../globals';
import {UtilitiesService} from '../../services/utilities.service';
import {CurrencyService} from '../../services/currency.service';
import {ConfirmationDialogService} from '../../services/confirmation-dialog/confirmation-dialog.service';
import {AuthService} from '../../services/auth.service';
import {GlobalBehavioursService} from '../../services/global-behaviours.service';

@Component({
  selector: 'app-payroll',
  templateUrl: './payroll.component.html',
  styleUrls: ['payroll.component.css']
})

export class PayrollComponent implements OnInit {

  rForm: FormGroup;
  payrollPeriods: PayrollPeriod[];
  requiredAlert = 'This field is required';
  isServiceAvailable = false;

  payrollViews: any;
  summaryAvailable = false;

  constructor(public auth: AuthService,
              private injector: Injector,
              public apiService: ApiService,
              private payrollService: PayrollService,
              private fb: FormBuilder,
              public currencyService: CurrencyService,
              public utilitiesService: UtilitiesService,
              public confirmationDialogService: ConfirmationDialogService,
              public globalBehavioursService: GlobalBehavioursService,
              public formService: FormService) {

    this.rForm = fb.group({
      payrollPeriod: [null, [Validators.required]]
    });
  }

  isServiceAvailable$ = this.globalBehavioursService.isServiceAvailable$;
  notifier = this.injector.get(NotificationService);

  /*ngOnInit() {
    this.getOpenPayrollPeriods();
    this.getRecentPayrollSummaries();
  }*/

  ngOnInit() {
    this.currencyService.setDefaultCurrency().then(r => { // Wrapped inside promise to ensure currencies are set first.
      this.getOpenPayrollPeriods();
      this.getRecentPayrollSummaries();
    }).catch(function(e) {
      console.log('Error setting currencies! Please contact system administrator');
    });
  }

  getOpenPayrollPeriods(): void {
    this.apiService.getAll(globals.PAYROLL_PERIOD_ENDPOINT + '/open').subscribe(
      (res: []) => {
        this.payrollPeriods = res['content'];
      }
    );
  }
  runPayroll(f) {
    const payrollPeriod: PayrollPeriod = this.utilitiesService.generateQuickIdObject(f.payrollPeriod);

    this.apiService.saveOnly(globals.RUN_PAYROLL_ENDPOINT, payrollPeriod).subscribe(_ => {
      this.notifier.showSuccess('Payroll ran successfully');
      this.ngOnInit();
      /*this.notifier.showSaved();
      this.resetForm();
      this.ngAfterViewInit();
      this.updateMode = false;*/
    }, error => this.isServiceAvailable = false);
  }

  getRecentPayrollSummaries() {
    this.apiService.getAll(globals.PAYROLL_ENDPOINT).subscribe(
      (res: any) => {
        this.payrollViews = res;
        this.summaryAvailable = true;
        console.log(this.payrollViews);
      }
    );
  }

  public confirmationDialogClosePP(id) {
    this.confirmationDialogService.confirm('Please confirm...', 'This action cannot be undone.\n Do you wish to proceed?')
      .then((confirmed) => {
        if (confirmed) this.closePayrollPeriod(id);
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  private closePayrollPeriod(id) {
    this.apiService.updateWithIdOnly(globals.PAYROLL_PERIOD_ENDPOINT + '/close', id)
      .subscribe(
        (res: boolean) => {
          this.notifier.showSuccess('Period successfully closed');
          this.ngOnInit();
          // window
        }/*,
          (err) => this.error = err*/
      );
  }

  public rollback(id) {
    this.confirmationDialogService.confirm('Please confirm...', 'All data related to this period will be erased.\n This action cannot be undone.\n Do you wish to proceed?')
      .then((confirmed) => {
        if (confirmed) this.rollItBack(id);
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  public rollItBack(id) {
    this.apiService.saveOnly(globals.RUN_PAYROLL_ENDPOINT + '/rollback', this.utilitiesService.generateQuickIdObject(id)).subscribe(_ => {
      this.notifier.showSuccess('Rollback successful');
      this.ngOnInit();
    }, error => this.isServiceAvailable = false);
  }

  hasRollbackRight() {
    return this.auth.hasRole('ROLE_RESTRICTED');
  }

  hasPayrollAdminRole() {
    return this.auth.hasRole('ROLE_PAYROLL_ADMIN');
  }

  hasClerkRole() {
    return this.auth.hasRole('ROLE_CLERK');
  }

  /*
  title = 'payroll-system';
  payrolls: Payroll[];
  error = '';
  success = '';
  payrollPeriods: PayrollPeriod[]; // For dropdown

  formulas: Formula[];

  payroll = new Payroll(0,0,'',0);

  rForm: FormGroup;
  showList = false;
  updateMode = false;
  post: any;
  payrollPeriod = 0;
  hod = 0;

  public formErrors = {
    payrollPeriod: '',
    payday: ''
  };

  constructor(private injector: Injector,
              private payrollService: PayrollService,
              private dataService: DataService,
              private fb: FormBuilder,
              public formService: FormService) {

    this.rForm = fb.group({
      payrollPeriod: [null, [Validators.required]],
      payday: [null, [Validators.required]]
    });

    this.rForm.valueChanges.subscribe((data) => {
      this.formErrors = this.formService.validateForm(this.rForm, this.formErrors, true);
    });
  }

  notifier = this.injector.get(NotificationService);

  ngOnInit() {
    this.getPayrollPeriods();
    this.getAllFormulas();
    if (this.showList) {
      this.getPayrolls();
    }
  }

  showHideList($isChecked): void {
    if ($isChecked) {
      if (!this.payrolls) {
        // Above Condition added to make the list available on demand. can't retrieve list if not defined (or it throws an error)
        this.getPayrolls();
      }
      this.showList = true;
    } else {
      this.showList = false;
    }
  }
  monthYear(d): string {//dup
    d = new Date(d);

    const monthNames = ["January", "February", "March", "April", "May", "June",
                          "July", "August", "September", "October", "November", "December"
                        ];

    let mm =  monthNames[d.getMonth()];
    let yyyy = d.getFullYear();
    return mm + ' ' + yyyy;
  }

  // for dropdown
  getPayrollPeriods(): void {
    this.dataService.getAllPayrollPeriods().subscribe(
      (res: PayrollPeriod[]) => {
        this.payrollPeriods = res;
      }
    );
  }

  getAllFormulas(): void {
    this.dataService.getAllFormulas().subscribe(
      (res: Formula[]) => {
        this.formulas = res;
      }
    );
  }

  getPayrolls(): void {
    this.payrollService.getAllPayrolls().subscribe(
      (res: Payroll[]) => {
        this.payrolls = res;
      }
    );
  }

  getPayroll(id): void {
    this.payrollService.getPayroll(id).subscribe(
      (res: Payroll) => {
        this.payroll = res;
      }
    );
  }

  runPayroll(f) {
    this.resetErrors();

    //this.payroll.period = f.payrollPeriod;
    //this.payroll.payday = f.payday;

    //for validation
    const selectedPeriod = this.payrollPeriods.find((item) => {
        return +item.id === +f.payrollPeriod;
      });

    this.payrolls = [];//INIT
    if (this.monthYear(selectedPeriod.period) === this.monthYear(f.payday)) {
        //run
        for (let x = 0; x<this.formulas.length; x++){

            let employeeid = +this.formulas[x].employee.id;
            let monthly = +this.formulas[x].monthly;

            let tax = +this.formulas[x].tax;
            let medicalaid = +this.formulas[x].medicalaid;
            let retirement = +this.formulas[x].retirement;

            let net = monthly-tax-medicalaid-retirement;

            let payrollObj = new Payroll(0,0,'',0);
            payrollObj.payday = f.payday;
            payrollObj.period = f.payrollPeriod;
            payrollObj.employee = employeeid;
            payrollObj.net = +net;
            //alert(JSON.stringify(payrollObj));
            this.payrolls.push(payrollObj);
        }
        //alert(JSON.stringify(this.payrolls));

        //SAVE IT
        this.payrollService.storePayroll(this.payrolls)
        .subscribe(
            (res: any) => {
            // Inform the user
            this.success = 'Created successfully';
            this.notifier.showSuccess('Payroll ran successfully');//.showSaved();

            // Reset the form
            this.rForm.reset();
            }
        );

    } else {
        alert('Pay day must be in the selected period.');
    }

  }

  deletePayroll(id) {
    this.resetErrors();
    this.payrollService.deletePayroll(id)
      .subscribe(
        (res: Payroll[]) => {
          if (this.showList) {
            this.payrolls = res;
          }
          this.success = 'Deleted successfully';
          this.notifier.showDeleted();
          this.updateMode = false;
          this.rForm.reset();
        }
      );
  }

  private resetErrors() {
    this.success = '';
    this.error   = '';
  }
*/}

