import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-open-periods-mini',
  templateUrl: './open-periods-mini.component.html',
  styleUrls: ['./open-periods-mini.component.scss']
})
export class OpenPeriodsMiniComponent implements OnInit {
  @Input() periods: [];
  @Input() periodIndex = 0;
  @Input() nextDisabled = true;
  @Input() previousDisabled = true;
  @Input() numberOfPeriods = 1;
  constructor() { }

  ngOnInit(): void {
  }

  next(currentIndex: number) {
    this.periodIndex += 1;
    if (this.periodIndex === this.numberOfPeriods - 1) {
      this.nextDisabled = true;
      this.previousDisabled = false;
    }
    this.previousDisabled = (this.periodIndex > 0) ? false : true;
  }

  prev(currentIndex: number) {
    this.periodIndex -= 1;
    if (this.periodIndex === 0) {
      this.nextDisabled = false;
      this.previousDisabled = true;
    }
    this.nextDisabled = (this.periodIndex < this.numberOfPeriods-1) ? false : true;
  }

}
